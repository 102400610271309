import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_search_faq,
  get_search_note,
  get_search_preset,
  get_search_total,
  get_search_user,
  put_search_preset,
} from "../../store/search/search";
import useToken from "../storage/useToken";
import useRefresh from "./useRefresh";

const hasEmpty = result =>
  Object.values(result).some(v => v === undefined || v === null);

export const useSearchTotal = query => {
  const access_token = useToken();
  const dispatch = useDispatch();
  const search = useSelector(state => state.search);
  const { result, prev_query, loading } = search;

  const callApiGetSearchTotal = async () => {
    // 통합 검색 api 호출.
    if (query) {
      dispatch(
        get_search_total({
          access_token,
          query,
        })
      );
    }
  };

  useEffect(() => {
    if (prev_query !== query || hasEmpty(result)) {
      // 이전 검색어와 같지 않거나 result에 빈 값이 있을 경우(통합 검색을 하지 않았음.)
      callApiGetSearchTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useRefresh(() => callApiGetSearchTotal(), loading);

  return [result, loading];
};

export const useSearchPresets = (
  query,
  model_id,
  support_program_id,
  support_os_id,
  order
) => {
  const access_token = useToken();
  const dispatch = useDispatch();
  const search = useSelector(state => state.search);
  const { result, prev_query, loading, end } = search;
  const { preset_posts } = result;
  const { preset_posts: end_posts } = end;

  const callApiGetSearchPreset = async () => {
    // 프리셋 게시글 검색 api 호출.
    dispatch(
      get_search_preset({
        access_token,
        query,
        model_id,
        support_program_id,
        support_os_id,
        order,
      })
    );
  };

  const loadApiGetSearchPreset = async (offset, limit) => {
    // 프리셋 게시글 추가 로딩 검색 api 호출.
    if (!loading && !end_posts) {
      dispatch(
        put_search_preset({
          access_token,
          query,
          model_id,
          support_program_id,
          support_os_id,
          order,
          offset,
          limit,
        })
      );
    }
  };

  useEffect(() => {
    if (prev_query !== query || !preset_posts) {
      // 이전 검색어와 같지 않거나 preset_posts에 빈 값이 있을 경우(프리셋 검색을 하지 않았음.)
      callApiGetSearchPreset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    callApiGetSearchPreset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model_id, support_program_id, support_os_id, order]);

  useRefresh(callApiGetSearchPreset, loading);

  return [preset_posts, loading, loadApiGetSearchPreset];
};

export const useSearchUsers = query => {
  const access_token = useToken();
  const dispatch = useDispatch();
  const search = useSelector(state => state.search);
  const { result, prev_query, loading } = search;
  const { users } = result;

  const callApiSearchUser = async () => {
    // 업데이트 내역 검색 api 호출.
    if (query) {
      dispatch(get_search_user({ access_token, query }));
    }
  };

  useEffect(() => {
    if (prev_query !== query || !users) {
      // 이전 검색어와 같지 않거나 result에 빈 값이 있을 경우(통합 검색을 하지 않았음.)
      callApiSearchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useRefresh(() => callApiSearchUser(), loading);

  return [users, loading];
};

export const useSearchReleaseNotes = query => {
  const dispatch = useDispatch();
  const search = useSelector(state => state.search);
  const { result, prev_query, loading } = search;
  const { notes } = result;

  const callApiSearchReleaseNote = async () => {
    // 업데이트 내역 검색 api 호출.
    if (query) {
      dispatch(get_search_note(query));
    }
  };

  useEffect(() => {
    if (prev_query !== query || !notes) {
      // 이전 검색어와 같지 않거나 result에 빈 값이 있을 경우(통합 검색을 하지 않았음.)
      callApiSearchReleaseNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useRefresh(() => callApiSearchReleaseNote(), loading);

  return [notes, loading];
};
export const useSearchFaqs = query => {
  const dispatch = useDispatch();
  const search = useSelector(state => state.search);
  const { result, prev_query, loading } = search;
  const { faqs } = result;

  const callApiSearchFaq = async () => {
    // 자주 묻는 질문 검색 api 호출.
    if (query) {
      dispatch(get_search_faq(query));
    }
  };

  useEffect(() => {
    if (prev_query !== query || !faqs) {
      // 이전 검색어와 같지 않거나 result에 빈 값이 있을 경우(통합 검색을 하지 않았음.)
      callApiSearchFaq();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useRefresh(() => callApiSearchFaq(), loading);

  return [faqs, loading];
};
