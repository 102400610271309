import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const KEY = "TEMPORARY_TOKEN";

export const setTemporaryToken = token => {
  sessionStorage.setItem(KEY, token);
};

const getTemporaryToken = () => {
  const finded_email = sessionStorage.getItem(KEY);
  return finded_email;
};
const removeTemporaryToken = () => {
  sessionStorage.removeItem(KEY);
};

const useTemporaryToken = redirectPath => {
  const history = useHistory();
  const temporary_token = getTemporaryToken();

  useEffect(() => {
    if (!temporary_token) {
      if (redirectPath) {
        history.replace(redirectPath);
      }
    }
    return removeTemporaryToken;
  }, [history, redirectPath, temporary_token]);

  return temporary_token;
};

export default useTemporaryToken;
