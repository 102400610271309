import React from "react";
import classNames from "classnames/bind";
import { Button } from "@material-ui/core";

import PostIcon from "../../icons/tab/PostIcon";
import BookmarkIcon from "../../icons/tab/BookmarkIcon";

import styles from "./Tabs.module.scss";

const cn = classNames.bind(styles);

const Tabs = ({ isBookmark, onChangeType }) => {
  return (
    <div className={styles["tabs"]}>
      <Button className={styles["item"]} onClick={() => onChangeType("writer")}>
        <PostIcon on={!isBookmark} />
        <h1 className={cn("tab-name", { on: !isBookmark })}>게시물</h1>
      </Button>
      <Button
        className={styles["item"]}
        onClick={() => onChangeType("bookmark")}
      >
        <BookmarkIcon on={isBookmark} />
        <h1 className={cn("tab-name", { on: isBookmark })}>저장됨</h1>
      </Button>
    </div>
  );
};

export default Tabs;
