import React from "react";
import styles from "./Faq.module.scss";

// ! material
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import EmptyResult from "../error/EmptyResult";
import { SkeletonFaqs } from "./SkeletonFaq";

/*
    자주 묻는 질문 아이템
*/
const Faq = ({ question, answer }) => {
  return (
    <Accordion className={styles["faq"]}>
      <ButtonBase component={AccordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography className={styles["title"]}>
          <span className={styles["left"]}>Q:</span>
          <span className={styles["right"]}>{question}</span>
        </Typography>
      </ButtonBase>
      <AccordionDetails>
        <div className={styles["detail"]}>
          <p className={styles["left"]}>A:</p>
          <div className={styles["right"]}>{answer}</div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const FaqList = ({ list, loading }) => {
  return (
    <section className={styles["list"]}>
      {list === null || loading ? (
        <SkeletonFaqs />
      ) : list.length !== 0 ? (
        list.map(item => <Faq {...item} key={item.faq_id} />)
      ) : (
        <EmptyResult height={130} />
      )}
    </section>
  );
};

export const Faqs = React.memo(FaqList);

export default Faq;
