import React from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";

import EmptyResult from "../error/EmptyResult";

import { Paths } from "../../paths";

import { dateToRelative } from "../../lib/formatter";

import styles from "./Qna.module.scss";
import { SkeletonQnas } from "./SkeletonQna";

const cn = classNames.bind(styles);

export const QnaHeadBar = () => (
  <div className={styles["head-bar"]}>
    <div className={styles["title"]}>문의제목</div>
    <div className={styles["created-at"]}>등록일</div>
    <div className={styles["status"]}>상태</div>
  </div>
);

const Qna = ({ qna_id, title, created_at, answer }) => {
  return (
    <ButtonBase
      component={Link}
      to={`${Paths.contact.qna.index}/${qna_id}`}
      className={styles["item"]}
    >
      <div className={styles["title"]}>{title}</div>
      <div className={styles["created-at"]}>
        {dateToRelative(new Date(created_at), ".")}
      </div>
      <div className={cn("status", { on: answer !== null })}>
        {answer === null ? "답변대기" : "답변완료"}
      </div>
    </ButtonBase>
  );
};

const QnaList = ({ list, loading }) => {
  return (
    <section className={styles["list"]}>
      <QnaHeadBar />
      {list === null || loading ? (
        <SkeletonQnas />
      ) : list.length !== 0 ? (
        list.map(item => <Qna {...item} key={item.qna_id} />)
      ) : (
        <EmptyResult height={500} text="문의 내역이 없습니다." />
      )}
    </section>
  );
};

export const Qnas = React.memo(QnaList);

export default Qna;
