import React from "react";

import SearchError from "../../components/error/SearchError";
import { Profiles } from "../../components/profile/Profile";
import TitleHelmet from "../../components/title/TitleHelmet";

import { useSearchUsers } from "../../hooks/redux/useSearch";

/**
 * 프로필 검색 컨테이너.
 *
 * 커뮤니티 프로필을 검색하여, 유저 프로필 리스트 렌더.
 */
const SearchProfileContainer = ({ query }) => {
  const { q } = query; // url query 값

  const [users, loading] = useSearchUsers(q);

  return (
    <>
      <TitleHelmet title="프로필 검색" />
      {q ? (
        <Profiles loading={loading} list={users} />
      ) : (
        <SearchError text="검색어를 입력해 주세요." />
      )}
    </>
  );
};

export default SearchProfileContainer;
