import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames/bind";
import { useLocation } from "react-router-dom";
/* Library */

import { useDispatch } from "react-redux";
import { dialogClose } from "../../store/dialog";
/* Redux */

import { ButtonBase, Dialog as MuiDialog } from "@material-ui/core";
/* Components */

import styles from "./Dialog.module.scss";
/* StyleSheets */

const cn = classNames.bind(styles);

const Dialog = ({
  open,
  title,
  text,
  onClick = () => {},
  onClose = () => {},
  isConfirm,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const confirmButton = useRef(null);

  const close = useCallback(() => {
    dispatch(dialogClose());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);
  const handleClick = useCallback(() => {
    onClick();
    close();
  }, [onClick, close]);

  useEffect(() => {
    const keypressEvent = e => {
      if (e.key === "Enter") {
        handleClick();
      }
    };
    if (open) {
      document.addEventListener("keypress", keypressEvent, true);
      return () => {
        document.removeEventListener("keypress", keypressEvent, true);
      };
    }
  }, [handleClick, open]);

  useEffect(() => {
    if (open) {
      // 경로가 바뀌면 dialog를 닫음.
      dispatch(dialogClose());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MuiDialog open={open} onClose={handleClose}>
      <div className={cn("dialog", { confirm: isConfirm, open })}>
        <div className={styles["area"]}>
          <div className={cn("content")}>
            <h3 className={styles["title"]}>{title}</h3>
            <p className={styles["text"]}>{text}</p>
          </div>
          <div className={styles["bottom"]}>
            {isConfirm && (
              <ButtonBase className={cn("button")} onClick={handleClose}>
                아니오
              </ButtonBase>
            )}
            <ButtonBase
              ref={confirmButton}
              className={cn("button", "active")}
              onClick={handleClick}
            >
              {isConfirm ? "예" : "확인"}
            </ButtonBase>
          </div>
        </div>
      </div>
    </MuiDialog>
  );
};

export default Dialog;
