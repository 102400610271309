import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import AOS from "aos";
import "./App.scss";

import {
  MainPage,
  SignInPage,
  SignUpPage,
  SearchRoutePage,
  PresetRoutePage,
  UserProfilePage,
  ContactRoutePage,
  FindPage,
  UpdateProfileRoutePage,
  NotFoundPage,
  ErrorPage,
  PolicyPage,
  OAuthPage,
} from "./pages";

import UrlModalContainer from "./containers/assets/UrlModalContainer";
import RefreshContainer from "./containers/assets/RefreshContainer";
import DialogContainer from "./containers/assets/DialogContainer";
import SnackbarContainer from "./containers/assets/SnackbarContainer";
import Loading from "./components/assets/Loading";
import Header from "./components/header/Header";
import MobileHeader from "./components/header/MobileHeader";
import Footer from "./components/footer/Footer";
import TitleHelmet from "./components/title/TitleHelmet";

import { MobileTitleProvider } from "./contexts/MobileTitleContext";

import { Paths } from "./paths";

import { useStorageCheck } from "./hooks/storage/useToken";

import DialogPortals from "./DialogPortals";

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const { success, failure, loading } = useStorageCheck();

  const background = location.state?.background;

  useEffect(() => {
    AOS.init();
    // 같은 위치로 이동 발생하면 중복 제거.
    let currentLocation = null;
    history.block((location, action) => {
      const nextLocation = location.pathname + location.search;
      if (action === "PUSH") {
        if (currentLocation === nextLocation) {
          return false;
        }
      }
      currentLocation = nextLocation;
    });

    const jssStyles = document.getElementById("jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!background) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <MobileTitleProvider render={MobileHeader}>
      <TitleHelmet
        title="Community - 크리에이터들의 소통 공간"
        subject="INVAIZ Community, 인바이즈 커뮤니티"
        author="인바이즈, INVAIZ"
        keywords="인바이즈, 다이얼 키패드, 키패드, 다이얼, 편집 컨트롤러, 편집, 컨트롤러, INVAIZ, Dial Keypad, Controller, Edit, Edit Controller, Contents, Media, MassMedia"
        description="인바이즈, 다이얼 키패드, 키패드, 다이얼, 편집 컨트롤러, 편집, 컨트롤러, INVAIZ, Dial Keypad, Controller, Edit, Edit Controller, Contents, Media, MassMedia"
        noFront
      />
      <DialogPortals children={<DialogContainer />} tagId="dialog" />
      {success && !failure && <UrlModalContainer location={location} />}
      <RefreshContainer />
      <SnackbarContainer />

      <Header loading={loading} />
      {success && !failure && (
        <Switch location={background || location}>
          <Route path={Paths.index} component={MainPage} exact />
          <Route path={Paths.signin} component={SignInPage} exact />
          <Route path={Paths.signup} component={SignUpPage} />
          <Route path={Paths.search.index} component={SearchRoutePage} />
          <Route path={Paths.preset.index} component={PresetRoutePage} />
          <Route path={Paths.contact.index} component={ContactRoutePage} />
          <Route path={Paths.user.update} component={UpdateProfileRoutePage} />
          <Route
            path={`${Paths.user.index}/:user_id`}
            component={UserProfilePage}
          />
          <Route path={Paths.find.index} component={FindPage} />
          <Route path={`${Paths.policy.index}/:type`} component={PolicyPage} />
          <Route path={Paths.oauth} component={OAuthPage} />
          <Route component={NotFoundPage} />
        </Switch>
      )}
      {success && <Footer />}
      {loading && <Loading open={loading} />}
      {failure && <ErrorPage />}
    </MobileTitleProvider>
  );
};

export default App;
