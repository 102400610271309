import React from "react";

import { ButtonBase } from "@material-ui/core";

import styles from "./BannerButton.module.scss";

const BannerButton = ({ image, component, children, ...restProps }) => {
  return (
    <ButtonBase
      style={{
        backgroundImage: `url(${image})`,
      }}
      className={styles["button"]}
      component={component}
      {...restProps}
    >
      {children}
    </ButtonBase>
  );
};

export default BannerButton;
