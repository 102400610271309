import { createAction, handleActions } from "redux-actions";
import { call, put, takeEvery } from "redux-saga/effects";

import { requestGetUser, requestPostLogout } from "../api/auth";
import { requestGetViewBase } from "../api/combination";

import { removeToken } from "../hooks/storage/useToken";

const GET_INFO = "info/GET_INFO";
const GET_INFO_SUCCESS = "info/GET_INFO_SUCCESS";
const GET_INFO_FAILURE = "info/GET_INFO_FAILURE";

const GET_USER_INFO = "info/GET_USER_INFO";
const GET_USER_INFO_SUCCESS = "info/GET_USER_INFO_SUCCESS";
const GET_USER_INFO_FAILURE = "info/GET_USER_INFO_FAILURE";

const LOGOUT_USER = "info/LOGOUT_USER";
const LOGOUT_USER_SUCCESS = "info/LOGOUT_USER_SUCCESS";
const LOGOUT_USER_FAILURE = "info/LOGOUT_USER_FAILURE";

const UPDATE_USER = "info/UPDATE_USER";

export const get_info = createAction(GET_INFO);
export const get_user_info = createAction(GET_USER_INFO);
export const logout_user = createAction(LOGOUT_USER);
export const update_user = createAction(UPDATE_USER);

const initState = {
  loading: false,
  failure: false,
  success: false,
  error: null,

  company: null,
  models: null,
  products: null,
  support_programs: null,
  support_operating_systems: null,

  user: null,
  access_token: null,
};

function* get_info_saga(action) {
  try {
    const res = yield call(requestGetViewBase, action.payload);
    yield put({
      type: GET_INFO_SUCCESS,
      payload: res.data,
      token: action.payload,
    });
  } catch (e) {
    yield put({
      type: GET_INFO_FAILURE,
      payload: e,
    });
  }
}

function* get_user_info_saga(action) {
  try {
    const res = yield call(requestGetUser, action.payload);
    yield put({
      type: GET_USER_INFO_SUCCESS,
      payload: res.data.user,
    });
  } catch (e) {
    yield put({
      type: GET_USER_INFO_FAILURE,
      payload: e,
    });
  }
}

function* get_user_logout_saga(action) {
  try {
    const res = yield call(requestPostLogout, action.payload);
    yield put({
      type: LOGOUT_USER_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    yield put({
      type: LOGOUT_USER_FAILURE,
      payload: e,
    });
  }
}

export function* info_saga() {
  yield takeEvery(GET_INFO, get_info_saga);
  yield takeEvery(GET_USER_INFO, get_user_info_saga);
  yield takeEvery(LOGOUT_USER, get_user_logout_saga);
}

const info = handleActions(
  {
    [GET_INFO]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GET_INFO_SUCCESS]: (state, action) => {
      if (action.token && !action.payload.user) {
        // 토큰은 있는데 로그인은 안 될 경우 토큰 삭제
        removeToken();
      }
      return {
        ...state,
        loading: false,
        success: true,
        ...action.payload,
        access_token: action.token,
      };
    },
    [GET_INFO_FAILURE]: (state, action) => {
      const error = action.payload;
      const message = error?.response?.data?.message;
      if (message === "탈퇴된 유저입니다.") {
        removeToken();
      }
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
      };
    },

    [GET_USER_INFO]: (state, action) => ({
      ...state,
      loading: true,
      access_token: action.payload,
    }),
    [GET_USER_INFO_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      user: action.payload,
    }),
    [GET_USER_INFO_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      failure: true,
    }),

    [LOGOUT_USER]: (state, action) => state,
    [LOGOUT_USER_SUCCESS]: (state, action) => {
      removeToken();
      return { ...state, user: null, access_token: null };
    },
    [LOGOUT_USER_FAILURE]: (state, action) => state,
    [UPDATE_USER]: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
  },
  initState
);

export default info;
