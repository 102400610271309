import React, { useEffect, useState } from "react";
import styles from "./Agree.module.scss";

const Agree = ({ description, checked, onClick, required }) => {
  return (
    <span className={styles["label"]} onClick={onClick}>
      <input
        className={styles["checkbox"]}
        type="checkbox"
        checked={checked}
        required
        readOnly
      />
      <span className={styles["description"]}>{`${description} ${
        required !== undefined ? (required ? "(필수)" : "(선택)") : ""
      }`}</span>
    </span>
  );
};

const AgreeItem = props => {
  return (
    <li className={styles["item"]}>
      <Agree {...props} />
    </li>
  );
};

export const AgreeList = ({ agreeList, setAgreeList, on }) => {
  const [allCheck, setAllCheck] = useState(false);

  useEffect(() => {
    setAllCheck(agreeList.every(agreeItem => agreeItem.checked));
  }, [agreeList]);

  const onClickAllCheck = () => {
    setAgreeList(list => list.map(item => ({ ...item, checked: !allCheck })));
  };
  const handleChange = id => {
    setAgreeList(list =>
      list.map(item =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  return (
    <ul className={styles["list"]}>
      <AgreeItem
        description="모두 동의하기"
        checked={allCheck}
        onClick={onClickAllCheck}
      />
      {agreeList.map(({ id, description, checked, required }) => {
        return (
          <AgreeItem
            key={id}
            description={description}
            checked={checked}
            required={required}
            onClick={() => handleChange(id)}
          />
        );
      })}
    </ul>
  );
};

export default Agree;
