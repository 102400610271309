import React, { useEffect, useReducer, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Paths } from "../../paths";

//components
import { ButtonBase } from "@material-ui/core";
import PresetViewer from "../../components/preset/PresetViewer";
import WriteButton from "../../components/button/write/WriteButton";
import QuillInput from "../../components/preset/QuillInput";
import HashtagInput from "../../components/preset/HashtagInput";
import TopInfoItem from "../../components/preset/TopInfoItem";
import TitleHelmet from "../../components/title/TitleHelmet";

import _NONE from "../../images/main/none.png";

import { dateToRelative } from "../../lib/formatter";

import {
  requestPostPreset,
  requestGetPreset,
  requestPutPresetUpdate,
  requestPostDecryptPreset,
} from "../../api/preset";

import { useTokenEffect } from "../../hooks/storage/useToken";
import useFeed from "../../hooks/redux/useFeed";
import useUser from "../../hooks/redux/useUser";
import useDialog from "../../hooks/useDialog";

import { formatFeed } from "../../store/feed";

import styles from "./PresetWriteContainer.module.scss";

const SET_INIT = "SET_INIT";
const UPDATE_STATUS = "UPDATE_MANY_STATUS";
const initialState = {
  title: "",
  content: "",
  model: null,
  support_os: null,
  support_program: null,
  preset_content: null,
  hashtags: [],

  created_at: null,
  file: null,
  file_name: "",

  previous: {
    title: "",
    content: "",
    model: null,
    support_os: null,
    support_program: null,
    preset_content: null,
    hashtags: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_INIT:
      return {
        ...state,
        ...action.payload,
        previous: {
          ...action.payload,
        },
      };
    case UPDATE_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return initialState;
  }
};

const initFeedData = ({
  title,
  content,
  hashtags,
  preset_content,
  created_at,
  model,
  support_os,
  support_program,
}) => {
  const getFileName = preset_content => {
    if (preset_content) {
      const { Casting_Program, PresetName } = preset_content;
      return `[${Casting_Program}] ${PresetName}`;
    }
    return "";
  };
  return {
    title,
    content,
    hashtags: hashtags.map(hashtag => hashtag.name),
    preset_content,
    created_at,
    model,
    support_os,
    support_program,
    file_name: getFileName(preset_content),
  };
};

const searchListId = (list, value) => {
  const REMOVE_SPACE_REG = / /g;
  const t = value.toLowerCase().replace(REMOVE_SPACE_REG, "");
  return list.find(item => {
    const name = item.name.toLowerCase().replace(REMOVE_SPACE_REG, "");
    return name.indexOf(t) !== -1 || t.indexOf(name) !== -1;
  });
};

/**
 * Preset Write 컨테이너.
 *
 * id 값이 있을 경우, 해당 id 값을 수정함.
 *
 * @param {number} id 수정할 id 값
 * @returns 프리셋 작성 컨테이너
 */
const PresetWriteContainer = ({ id }) => {
  const history = useHistory();
  const openDialog = useDialog();
  const access_token = useTokenEffect();

  const { models, support_programs, support_operating_systems } = useSelector(
    state => state.info
  );

  const { user } = useUser();
  const { feed, updateFeed } = useFeed(id);
  const parseData = useMemo(
    () => (feed ? initFeedData(feed) : initialState),
    [feed]
  );
  const [post, dispatchPost] = useReducer(reducer, parseData);

  const {
    title,
    content,
    model,
    support_os,
    support_program,
    preset_content,
    file,
    hashtags,
    created_at,
    file_name,
    previous,
  } = post;

  const tags = useMemo(() => {
    return hashtags.map(hashtag => ({
      id: hashtag,
      text: hashtag,
    }));
  }, [hashtags]);

  const isUpdate = useMemo(() => !!id, [id]); // update 상태인지 유무
  const isChanged = useMemo(
    () =>
      title !== previous?.title ||
      content !== previous?.content ||
      JSON.stringify(hashtags) !== JSON.stringify(previous?.hashtags) ||
      preset_content !== previous?.preset_content,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [post]
  );
  const canWrite = useMemo(
    () =>
      isChanged && title.length !== 0 && content.length !== 0 && preset_content,
    [isChanged, title, content, preset_content]
  );

  const onChangeTitle = e =>
    dispatchPost({
      type: UPDATE_STATUS,
      payload: { title: e.target.value },
    });
  // 프리셋 게시글 제목 변경 리스너
  const onChangeContent = value =>
    dispatchPost({ type: UPDATE_STATUS, payload: { content: value } });
  // 프리셋 게시글 내용 변경 리스너

  const onAdditionTag = tag => {
    dispatchPost({
      type: UPDATE_STATUS,
      payload: {
        hashtags: hashtags.concat(tag.text.replace(/#/gi, "")),
      },
    });
  };
  const onDeleteTag = index => {
    dispatchPost({
      type: UPDATE_STATUS,
      payload: {
        hashtags: hashtags.filter((hashtag, i) => i !== index),
      },
    });
  };

  //파일 업로드
  const onUploadFile = e => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    try {
      fileReader.onload = async () => {
        const res = await requestPostDecryptPreset(fileReader.result);
        const { preset_content } = res.data;
        const {
          Casting_OS,
          Casting_Program,
          Casting_Model = "Grid10",
        } = preset_content;
        const model = searchListId(models, Casting_Model);
        const support_os = searchListId(support_operating_systems, Casting_OS);
        const support_program = searchListId(support_programs, Casting_Program);
        dispatchPost({
          type: UPDATE_STATUS,
          payload: {
            model: model,
            support_os: support_os,
            support_program: support_program,
            preset_content,
            file,
            file_name: file.name,
          },
        });
      };
      fileReader.readAsText(file);
    } catch (error) {
      openDialog("잘못된 프리셋 파일입니다.");
    }
  };

  const onClickWrite = async () => {
    // 프리셋 게시글 작성 / 수정 api 호출
    try {
      const { model_id } = searchListId(models, model.name);
      const { support_os_id } = searchListId(
        support_operating_systems,
        support_os.name
      );
      const { support_program_id } = searchListId(
        support_programs,
        support_program.name
      );
      const res = isUpdate
        ? await requestPutPresetUpdate(
            access_token,
            id,
            model_id,
            support_os_id,
            support_program_id,
            title,
            content,
            file,
            hashtags
          )
        : await requestPostPreset(
            access_token,
            model_id, // model_id
            support_program_id,
            support_os_id,
            title,
            content,
            file,
            hashtags
          );

      const { post } = res.data;
      const { board_id } = post;
      updateFeed({
        ...post,
        hashtags: post.hashtags.map(hashtag => ({ hashtag })),
      });
      history.replace(`${Paths.preset.index}/${board_id}`);
    } catch (e) {
      openDialog("업로드 중 오류가 발생했습니다.", {
        text: "잠시후 다시 시도하여주세요.",
      });
    }
  };
  // 프리셋 게시글 작성 / 수정 버튼 리스너

  // 프리셋 수정 시 상세 보기 호출
  const callApiGetPreset = async () => {
    try {
      const res = await requestGetPreset(access_token, id);
      const { post } = res.data;
      updateFeed(post);
      if (post.user?.user_id !== user?.user_id) {
        openDialog("수정 권한이 없는 게시물입니다.", {
          onClose: () => history.goBack(),
        });
      } else {
        const payload = initFeedData(formatFeed(post));
        dispatchPost({ type: SET_INIT, payload: payload });
      }
    } catch (e) {
      openDialog(e?.response?.data?.message, {
        onClose: () => history.goBack(),
      });
    }
  };

  useEffect(() => {
    // 수정 시 id 값이 바뀔 때마다 프리셋 게시글 상세 정보 가져옴.
    if (isUpdate) {
      callApiGetPreset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderPageTitle = useMemo(
    () => (isUpdate ? "수정" : "작성"),
    [isUpdate]
  );
  const renderSupportProgramImage = useMemo(
    () => (support_program ? support_program?.image : _NONE),
    [support_program]
  );
  const renderModelName = useMemo(
    () => model?.name || (!isUpdate && "없음"),
    [model, isUpdate]
  );
  const renderOsName = useMemo(
    () => support_os?.name || (!isUpdate && "없음"),
    [support_os, isUpdate]
  );
  const renderDate = useMemo(
    () => (isUpdate ? dateToRelative(new Date(created_at), ".") : "지금"),
    [created_at, isUpdate]
  );
  const renderFileName = useMemo(
    () => file_name || (!isUpdate && "선택된 파일이 없습니다."),
    [file_name, isUpdate]
  );
  // Render text data 최적화.

  return (
    <article className={styles["container"]}>
      <TitleHelmet title={`게시글 ${renderPageTitle}`} />
      <section className={styles["wrapper"]}>
        <div className={styles["contents"]}>
          <div className={styles["top-area"]}>
            <div className={styles["title-box"]}>
              <img
                className={styles["support-program"]}
                src={renderSupportProgramImage}
                alt="support_program"
              />
              <input
                type="text"
                className={styles["title-input"]}
                placeholder="제목을 입력해 주세요."
                name="title"
                value={title}
                onChange={onChangeTitle}
              />
            </div>
            <div className={styles["top-info"]}>
              <TopInfoItem name="Model" value={renderModelName} />
              <TopInfoItem name="OS" value={renderOsName} />
              <TopInfoItem name="Date" value={renderDate} />
              <TopInfoItem name="Writer" value={user?.nickname} />
            </div>
          </div>

          <div className={styles["editor"]}>
            <QuillInput value={content} onChange={onChangeContent} />
            <div className={styles["preset-view-box"]}>
              <PresetViewer preset={preset_content} />
            </div>
            <HashtagInput
              tags={tags}
              onAdditionTag={onAdditionTag}
              onDeleteTag={onDeleteTag}
            />
          </div>
          <div className={styles["action-area"]}>
            <WriteButton
              text={isUpdate ? "수정하기" : "게시하기"}
              onClick={onClickWrite}
              disabled={!canWrite}
            >
              <div className={styles["upload-area"]}>
                <input
                  type="file"
                  id="ex_file"
                  className={styles["file-upload"]}
                  onChange={onUploadFile}
                />
                <ButtonBase
                  component="label"
                  className={styles["upload"]}
                  htmlFor="ex_file"
                >
                  프리셋 업로드
                </ButtonBase>
                <p className={styles["file-name"]}>{renderFileName}</p>
              </div>
            </WriteButton>
          </div>
        </div>
      </section>
    </article>
  );
};

export default PresetWriteContainer;
