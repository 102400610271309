import React, { useState } from "react";
import classNames from "classnames/bind";

import FindTextInput from "../../../components/input/FindTextInput";
import FindSelect from "../../../components/input/FindSelect";
import SecessionButton from "../../../components/button/SecessionButton";
import Agree from "../../../components/input/Agree";
import TitleHelmet from "../../../components/title/TitleHelmet";

import useDialog from "../../../hooks/useDialog";
import { useTokenEffect } from "../../../hooks/storage/useToken";
import useUser from "../../../hooks/redux/useUser";

import styles from "./UpdateProfileContainer.module.scss";

const cn = classNames.bind(styles);

const DropContainer = () => {
  const openDialog = useDialog();

  const { onSecession } = useUser();

  const [reasonType, setReasonType] = useState(0);
  const [reasonComment, setReasonComment] = useState("");

  const [checked, setChecked] = useState(false);

  const onChangeType = e => setReasonType(parseInt(e.target.value));
  const onChangeComment = e => setReasonComment(e.target.value);

  const onClickCheckbox = () => setChecked(!checked);
  const onClickSecession = e => {
    e.preventDefault();
    openDialog("정말로 탈퇴하시겠습니까?", {
      text: "탈퇴 후 모든 정보는 복구할 수 없습니다.",
      onClick: () => onSecession(reasonType, reasonComment),
      isConfirm: true,
    });
  };

  useTokenEffect();

  return (
    <form
      className={cn("layout", "secession", { checked })}
      onSubmit={onClickSecession}
    >
      <TitleHelmet title="회원 탈퇴" />
      <div className={styles["caution"]}>
        <p className={styles["attention"]}>
          회원 탈퇴를 신청하기 전에 먼저 확인해주세요.
        </p>
        <p className={styles["warning"]}>
          신청 후 <b>2주 간의 유예 기간</b>이 있으며, 기간 내에 문의주시면 철회
          가능합니다.
        </p>
        <p className={styles["warning"]}>
          탈퇴 후 회원정보 및 이용기록은 <b>모두 삭제</b>되며{" "}
          <b>다시 복구가 불가</b>합니다.
        </p>
        <p className={styles["warning"]}>
          동일한 SNS계정과 이메일을 사용한 재 가입은 <b>2주 이내에 불가</b>
          합니다.
        </p>
      </div>
      <FindSelect
        label="탈퇴 사유"
        value={reasonType}
        onChange={onChangeType}
        options={[
          { name: "이유 없음", value: 0 },
          { name: "사용에 있어서 불편함을 느껴서", value: 1 },
          { name: "원하는 컨텐츠가 없어서", value: 2 },
        ]}
      />
      <FindTextInput
        label="자유 의견"
        type="text"
        value={reasonComment}
        onChange={onChangeComment}
      />
      <div className={styles["flex-box"]}>
        <Agree
          description="회원탈퇴 동의"
          checked={checked}
          onClick={onClickCheckbox}
          required
        />
      </div>
      <SecessionButton disabled={!checked} />
    </form>
  );
};

export default DropContainer;
