import React from "react";
import classNames from "classnames/bind";

import { ButtonBase } from "@material-ui/core";

import styles from "./SNSButton.module.scss";
import { Paths } from "../../paths";

const cn = classNames.bind(styles);

const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
  //   return string;
};

const SNSButton = ({ serviceName }) => {
  const onClickOAuth = () =>
    (window.location = `${Paths.apiV1}/auth/OAuth/${serviceName}`);

  return (
    <ButtonBase
      className={cn("sns-button", serviceName)}
      onClick={onClickOAuth}
    >
      <span className={cn("sns-name", serviceName)}>
        {capitalize(serviceName)} 계정으로 로그인
      </span>
    </ButtonBase>
  );
};

export default SNSButton;
