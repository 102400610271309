import React from "react";
import { Link } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";

import { SkeletonSearchHashtags } from "./SkeletonSearchHashtag";

import { Paths } from "../../paths";

import styles from "./SearchHashtag.module.scss";

const SearchHashtag = ({ name }) => {
  return (
    <ButtonBase
      component={Link}
      to={`${Paths.preset.index}?keyword=${name}`}
      className={styles["search-hashtag"]}
    >
      {name}
    </ButtonBase>
  );
};

const SearchHashtagList = ({ list, loading }) => {
  return (
    <section className={styles["list"]}>
      {list === null || loading ? (
        <SkeletonSearchHashtags />
      ) : (
        list.length !== 0 &&
        list.map(item => <SearchHashtag {...item} key={item.hashtag_id} />)
      )}
    </section>
  );
};

export const SearchHashtags = React.memo(SearchHashtagList);

export default SearchHashtag;
