import React from "react";

import ReleaseNoteContainer from "../../containers/contact/ReleaseNoteContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const ReleaseNotePage = ({ match }) => {
  useSetTitle("업데이트 내역");
  const { id } = match.params;
  return <ReleaseNoteContainer id={id} />;
};

export default ReleaseNotePage;
