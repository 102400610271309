import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Qnas } from "../../components/qna/Qna";
import PageTitle from "../../components/title/PageTitle";
import PostButton from "../../components/button/write/PostButton";
import TitleHelmet from "../../components/title/TitleHelmet";

//hooks
import { useTokenCallback } from "../../hooks/storage/useToken";

//api
import { requestGetQnaList } from "../../api/qna";

import { Paths } from "../../paths";

import styles from "./QnaContainer.module.scss";

/**
 * 1:1 문의 내역 컨테이너.
 */
const QnaContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [qnas, setQnas] = useState(null); // 1:1 문의 리스트

  const onClickPostButton = () => history.push(Paths.contact.qna.write);
  // 1:1 문의 작성하러 가기 버튼 리스너

  const callApiGetQnaList = useTokenCallback(
    async access_token => {
      // 1:1 문의 내역 리스트 api 호출
      setLoading(true);
      try {
        const res = await requestGetQnaList(access_token);
        if (res.status === 200) {
          const { qnas } = res.data;
          setQnas(qnas);
        }
      } catch (e) {
        console.log(e.response);
      }
      setLoading(false);
    },
    { isRequired: true, goBack: true }
  );

  useEffect(() => {
    // mount시 1:1 문의 내역 리스트 가져 옴
    callApiGetQnaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article className={styles["container"]}>
      <TitleHelmet title="1:1 문의" />
      <PageTitle>1:1 문의 내역</PageTitle>
      <div className={styles["button"]}>
        <PostButton text="문의하기" onClick={onClickPostButton} />
      </div>
      <section className={styles["content"]}>
        <Qnas loading={loading} list={qnas} />
      </section>
    </article>
  );
};

export default QnaContainer;
