import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { requestGetViewContact } from "../../api/combination";

const initialState = {
  loading: false,
  failure: false,
  success: false,

  faqs: null,
  notes: null,
};

const viewContactSlice = createSlice({
  name: "view_contact",
  initialState,
  reducers: {
    get_view: (state, action) => {
      state.loading = true;
      state.failure = false;
      state.success = false;
    },
    get_view_success: (state, action) => {
      const { faqs, release_notes: notes } = action.payload;

      state.loading = false;
      state.success = true;
      state.faqs = faqs;
      state.notes = notes;
    },
    get_view_failure: (state, action) => {
      state.loading = false;
      state.failure = true;
    },
  },
});

export const { get_view, get_view_success, get_view_failure } =
  viewContactSlice.actions;

function* get_view_contact_saga(action) {
  try {
    const res = yield call(requestGetViewContact);
    yield put(get_view_success(res.data));
  } catch (e) {
    yield put(get_view_failure(e));
  }
}

export function* view_contact_saga() {
  yield takeLatest(get_view.type, get_view_contact_saga);
}

export default viewContactSlice.reducer;
