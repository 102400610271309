import React, { useMemo, useState } from "react";
import classNames from "classnames/bind";

//component
import { ButtonBase } from "@material-ui/core";
import Tabs from "../../components/tab/Tabs";
import { Feeds } from "../../components/feed/Feed";
import FirstPostButton from "../../components/button/write/FirstPostButton";
import DetailProfile from "../../components/profile/DetailProfile";
import InfinityScroll from "../../components/search/InfinityScroll";
import TitleHelmet from "../../components/title/TitleHelmet";

import useMypage from "../../hooks/redux/useMypage";
import useUser from "../../hooks/redux/useUser";

import styles from "./UserProfileContainer.module.scss";

const cn = classNames.bind(styles);

/**
 * Mypage 페이지 컨테이너.
 *
 * 해당 id를 가진 유저의 정보와 게시글, 북마크 한 게시글 리스트 렌더.
 */
const UserProfileContainer = ({ user_id }) => {
  const { user, onLogout: onClickLogout } = useUser();

  const [type, setType] = useState("writer");

  const [mypage, loadApiGetUserProfile] = useMypage(user_id, type); //!.리덕스로 관리해야하는지 잘 모르겠음.!
  const { user_info, write_posts, bookmark_posts, loading } = mypage;

  const isBookmark = type === "bookmark";
  const isMyself = user?.user_id === parseInt(user_id); // 로그인 한 자신의 페이지인지 확인.

  const offset = useMemo(
    () => (isBookmark ? bookmark_posts?.length : write_posts?.length),
    [bookmark_posts, isBookmark, write_posts]
  );
  const limit = 10;

  const onChangeType = type => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setType(type);
  };

  return (
    <article className={styles["container"]}>
      {user_info && <TitleHelmet title={`${user_info.nickname}`} />}
      <section className={cn("wrapper", "profile")}>
        <DetailProfile user={isMyself ? user : user_info} isMyself={isMyself} />
      </section>
      {isMyself && (
        <section className={cn("wrapper", "logout")}>
          <ButtonBase className={styles["button"]} onClick={onClickLogout}>
            로그아웃
          </ButtonBase>
        </section>
      )}
      <section className={cn("wrapper", "tabs")}>
        <Tabs isBookmark={isBookmark} onChangeType={onChangeType} />
      </section>
      <section className={cn("wrapper", "feeds")}>
        <InfinityScroll
          offset={offset}
          limit={limit}
          loading={loading}
          onLoad={loadApiGetUserProfile}
        >
          {!isBookmark ? (
            <Feeds list={write_posts}>
              <div className={styles["first-feed"]}>
                <p className={styles["title"]}>
                  {isMyself
                    ? "첫 프리셋을 공유해보세요!"
                    : "게시글이 없습니다."}
                </p>
                <p className={styles["sub-title"]}>
                  {isMyself && "당신의 소중한 게시글이 많은 도움이 됩니다.!"}
                </p>
                <div className={styles["button"]}>
                  {isMyself && <FirstPostButton />}
                </div>
              </div>
            </Feeds>
          ) : (
            <Feeds list={bookmark_posts}>
              <div className={styles["first-feed"]}>
                <p className={styles["title"]}>저장된 프리셋이 없습니다.</p>
              </div>
            </Feeds>
          )}
        </InfinityScroll>
      </section>
    </article>
  );
};

export default UserProfileContainer;
