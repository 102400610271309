import { useEffect, useState } from "react";
import "./QuillSnow.scss"; // ES6
import "./Quill.scss";

const CustomToolbar = () => (
  <div id="toolbar">
    <div className="ql-wrapper">
      <select
        className="ql-header"
        defaultValue={""}
        onChange={e => e.persist()}
      >
        <option defaultValue></option>
        <option value="1"></option>
        <option value="2"></option>
        <option value="3"></option>
        <option value="4"></option>
        <option value="5"></option>
        <option value="6"></option>
      </select>
      <span className="ql-formats">
        <select className="ql-color">
          <option value="red"></option>
          <option value="green"></option>
          <option value="blue"></option>
          <option value="orange"></option>
          <option value="violet"></option>
          <option value="#d0d1d2"></option>
          <option defaultValue></option>
        </select>

        <select className="ql-background">
          <option value="red"></option>
          <option value="green"></option>
          <option value="blue"></option>
          <option value="orange"></option>
          <option value="violet"></option>
          <option value="#d0d1d2"></option>
          <option defaultValue></option>
        </select>
      </span>

      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        <button className="ql-code" />
      </span>

      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
        <select className="ql-align">
          <option defaultValue></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>

      <span className="ql-formats">
        <button className="ql-link" />
        <button className="ql-image" />
        <button className="ql-video" />
      </span>
    </div>
  </div>
);

const initialState = {
  ImageResize: null,
  ImageDrop: null,
  ReactQuill: null,
};

const QuillInput = ({ value, onChange }) => {
  const [QuillLibrary, setQuillLibrary] = useState(initialState);

  const { ReactQuill, ImageResize, ImageDrop } = QuillLibrary;

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
    imageResize: ImageResize,
    imageDrop: ImageDrop,
  };

  const formats = [
    "header",
    "font",
    "color",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "align",
    "link",
    "image",
    "video",
  ];

  const importQuill = async () => {
    const { default: ReactQuill } = await import("react-quill");
    const { default: ImageResize } = await import("quill-image-resize-module");
    const { ImageDrop } = await import("quill-image-drop-module");
    ReactQuill.Quill.register({
      "modules/imageResize": ImageResize,
      "modules/imageDrop": ImageDrop,
    });
    setQuillLibrary({ ReactQuill, ImageResize, ImageDrop });
  };

  useEffect(() => {
    importQuill();
  }, []);

  if (!ReactQuill) {
    return null;
  }

  return (
    <>
      <CustomToolbar />
      <ReactQuill
        modules={modules}
        formats={formats}
        onChange={onChange}
        value={value}
      />
    </>
  );
};

export default QuillInput;
