import React from "react";

const HtmlTagReg = /(<([^>]+)>)/gi;

const canHighligt = (text, query) =>
  query && text.toLowerCase().includes(query.toLowerCase());

const getTextParts = (text, query) =>
  text.split(new RegExp(`(${query})`, "gi"));

const equalsText = (part, query) => part.toLowerCase() === query.toLowerCase();

export const HighLightedString = (text, query) => {
  if (canHighligt(text.replace(HtmlTagReg, ""), query)) {
    const parts = getTextParts(text, query);

    return parts
      .map((part, index) =>
        equalsText(part, query) ? `<mark>${part}</mark>` : part
      )
      .join("");
  }

  return text;
};

const HighLightedText = ({ children: text, query }) => {
  if (canHighligt(text, query)) {
    const parts = getTextParts(text, query);

    return parts.map((part, index) =>
      equalsText(part, query) ? <mark key={index}>{part}</mark> : part
    );
  }
  return text;
};

export default HighLightedText;
