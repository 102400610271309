import React from "react";
import qs from "qs";

import SearchTotalContainer from "../../containers/search/SearchTotalContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const SearchTotal = ({ location }) => {
  useSetTitle("통합 검색");
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return <SearchTotalContainer query={query} />;
};

export default SearchTotal;
