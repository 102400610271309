import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestPostSharedFile = (token, name, file) => {
  const url = resolveAPIURL("shared-files");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("name", name);
  formData.append("shared_file", file);

  return axios.post(url, formData, config);
};

export const requestGetSharedFiles = token => {
  const url = resolveAPIURL("shared-files");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.get(url, config);
};

export const requestDeleteSharedFile = (token, id) => {
  const url = `${resolveAPIURL("shared-files")}/${id}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.delete(url, config);
};
