import React from "react";
import classNames from "classnames/bind";

import { Button } from "@material-ui/core";

import styles from "./SecessionButton.module.scss";

const cn = classNames.bind(styles);

const SecessionButton = ({ disabled }) => {
  return (
    <div className={styles["box"]}>
      <Button
        className={cn("button", {
          disabled: disabled,
        })}
        type="submit"
        disabled={disabled}
      >
        회원탈퇴
      </Button>
    </div>
  );
};

export default SecessionButton;
