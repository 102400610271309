import React from "react";
import ProfileButton from "./cover/ProfileButton";

import styles from "./SkeletonDetailProfile.module.scss";

const SkeletonDetailProfile = () => {
  return (
    <div className={styles["profile"]}>
      <div className={styles["image-view"]}>
        <div className={styles["profile-image"]}>
          <ProfileButton borderwidth={5} />
        </div>
      </div>
      <div className={styles["info"]}>
        <p className={styles["nickname"]} />
        <p className={styles["name"]} />
        <p className={styles["email"]} />
        <p className={styles["introduce"]} />
      </div>
    </div>
  );
};

export default SkeletonDetailProfile;
