import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { requestPutPresetBookmark, requestPutPresetLike } from "../api/preset";

const initialState = {};

export const formatFeed = feed => {
  return {
    ...feed,
    user: feed.user,
    hashtags: feed.hashtags.map(hashtag => hashtag.hashtag),
    replies: feed.replies.map(reply => reply.reply),
    preset: feed.preset,
    support_program: {
      ...feed.support_program,
      image: feed.support_program.image,
    },
  };
};

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    add_comment: {
      reducer: (state, action) => {
        const { reply, board_id, pushBack = false } = action.payload;
        if (!pushBack) {
          // 제일 앞에 삽입
          state[board_id].replies.splice(0, 0, reply);
          // 0번 인덱스에 하나도 제거하지 않고 reply 요소 추가
        } else {
          // 제일 뒤에 삽입
          state[board_id].replies.push(reply);
        }
      },
      prepare: (reply, board_id, pushBack = false) => {
        reply.new_created = true;
        return { payload: { reply, board_id, pushBack } };
      },
    },
    update_comment: {
      reducer: (state, action) => {
        const { reply, board_id, reply_id } = action.payload;
        const update_index = state[board_id].replies.findIndex(
          r => r.reply_id === reply_id
        );
        // 변경할 인덱스를 찾음
        if (update_index !== -1) {
          state[board_id].replies[update_index] = reply;
          // 변경할 인덱스의 값 변경.
        }
      },
      prepare: (reply, board_id, reply_id) => {
        return { payload: { reply, board_id, reply_id } };
      },
    },
    delete_comment: {
      reducer: (state, action) => {
        const { board_id, reply_id } = action.payload;
        const delete_index = state[board_id].replies.findIndex(
          r => r.reply_id === reply_id
        );
        // 삭제할 인덱스를 찾음.
        if (delete_index !== -1) {
          state[board_id].replies.splice(delete_index, 1);
          // 삭제할 인덱스에서 값 하나 제거.
        }
      },
      prepare: (board_id, reply_id) => {
        return { payload: { board_id, reply_id } };
      },
    },
    update_feed: (state, action) => {
      const feed = Object.assign({}, action.payload); // 값 복사
      delete feed.preset_content;
      state[feed.board_id] = formatFeed(feed);
      state[feed.board_id].operLike = 0;
      state[feed.board_id].operBookmark = 0;
    },
    update_feeds: (state, action) => {
      const newFeeds = action.payload;
      if (newFeeds) {
        newFeeds.forEach(feed => {
          state[feed.board_id] = formatFeed(feed);
          state[feed.board_id].operLike = 0;
          state[feed.board_id].operBookmark = 0;
        });
      }
    },
    do_like: (state, action) => {
      const { board_id } = action.payload;
      const currentLike = state[board_id].doLike;
      state[board_id].doLike = !currentLike;
      state[board_id].operLike = currentLike ? -1 : 1;
    },
    do_like_success: (state, action) => {
      const { board_id, like_users, doLike } = action.payload;
      state[board_id].like_users = like_users;
      state[board_id].doLike = doLike;
      state[board_id].operLike = 0;
    },
    do_like_failure: (state, action) => {},
    do_bookmark: (state, action) => {
      const { board_id } = action.payload;
      const currentBookmark = state[board_id].doBookmark;
      state[board_id].doBookmark = !currentBookmark;
      state[board_id].operBookmark = currentBookmark ? -1 : 1;
    },
    do_bookmark_success: (state, action) => {
      const { board_id, bookmark_users, doBookmark } = action.payload;
      state[board_id].bookmark_users = bookmark_users;
      state[board_id].doBookmark = doBookmark;
      state[board_id].operBookmark = 0;
    },
    do_bookmark_failure: (state, action) => {},
    delete_feed: (state, action) => {},
  },
});

export const {
  add_comment,
  update_comment,
  delete_comment,
  update_feed,
  update_feeds,
  do_like,
  do_like_success,
  do_like_failure,
  do_bookmark,
  do_bookmark_success,
  do_bookmark_failure,
} = feedSlice.actions;

function* do_like_saga(action) {
  try {
    const { access_token, board_id } = action.payload;
    const res = yield call(requestPutPresetLike, access_token, board_id);
    yield put(do_like_success(res.data.post));
  } catch (e) {
    yield put(do_like_failure(e));
  }
}

function* do_bookmark_saga(action) {
  try {
    const { access_token, board_id } = action.payload;
    const res = yield call(requestPutPresetBookmark, access_token, board_id);
    yield put(do_bookmark_success(res.data.post));
  } catch (e) {
    yield put(do_bookmark_failure(e));
  }
}

export function* feed_saga() {
  yield takeLatest(do_like.type, do_like_saga);
  yield takeLatest(do_bookmark.type, do_bookmark_saga);
}

export default feedSlice.reducer;
