import React from "react";

import SignUpContainer from "../../containers/main/SignUpContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";
import useBlockRefresh from "../../hooks/useBlockRefresh";

const SignUpPage = ({ match }) => {
  const { modal } = match.params;

  useSetTitle("회원가입");
  useBlockRefresh();

  return <SignUpContainer modal={modal} />;
};

export default SignUpPage;
