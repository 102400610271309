import React from "react";

import NotFoundContainer from "../../containers/error/NotFoundContainer";
import { useSetTitle } from "../../contexts/MobileTitleContext";

const NotFoundPage = () => {
  useSetTitle("찾을 수 없는 페이지");
  return <NotFoundContainer />;
};

export default NotFoundPage;
