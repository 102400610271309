import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
// import { IconButton } from '@material-ui/core';

import MypageIcon from "../../../icons/header/MypageIcon";

import _DefaultProfile from "../../../images/svg/default_profile.svg";

import styles from "./ProfileImage.module.scss";

const cn = classNames.bind(styles);

/**
 * 프로필 이미지 렌더 / 클릭 컴포넌트
 */
const ProfileImage = ({
  // buttonSize = { width: 40, height: 40 },
  imageSize = { width: 24, height: 24, borderWidth: 2 },
  src,
  on,
}) => {
  const [imageSrc, setImageSrc] = useState(src);

  const onError = e => setImageSrc(_DefaultProfile);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  return (
    <div className={cn("cover", { on })} style={imageSize}>
      {imageSrc ? (
        <img
          className={styles["image"]}
          src={imageSrc}
          alt="profile"
          onError={onError}
        />
      ) : (
        <MypageIcon on={on} />
      )}
    </div>
  );
};

export default ProfileImage;
