import React from "react";
import { useHistory } from "react-router-dom";

// import Radio from '../../../components/input/Radio';
import PhoneAuth from "../../../components/input/PhoneAuth";
// import EmailAuth from '../../../components/input/EmailAuth';
import SignButton from "../../../components/button/SignButton";
import TitleHelmet from "../../../components/title/TitleHelmet";

import { Paths } from "../../../paths";
import { requestPostTemporary } from "../../../api/auth";
import useDialog from "../../../hooks/useDialog";
import useInputs from "../../../hooks/useInputs";

import { setTemporaryToken } from "../../../hooks/storage/useTemporaryToken";

import styles from "../FindContainer.module.scss";

const initialState = {
  email: "",
  phoneNumber: "",
  verifyToken: null,
};

const FindIDContainer = () => {
  const history = useHistory();
  const openDialog = useDialog();

  const [findInfo, onChangeInput, findDispatch] = useInputs(initialState);

  const {
    // email,
    phoneNumber,
    verifyToken,
  } = findInfo;
  // Reducer 데이터 갖고 옴

  const onAuthComplete = verifyToken => {
    // 휴대폰 / 이메일 인증 후 콜백 함수
    findDispatch({ name: "verifyToken", value: verifyToken });
  };

  const onClickPostVerfiyToken = async () => {
    try {
      if (verifyToken) {
        const res = await requestPostTemporary(
          verifyToken,
          undefined,
          phoneNumber
        );
        if (res.status === 200) {
          setTemporaryToken(res.data.temporary_token);
          history.push(Paths.find.id.view);
        }
      }
    } catch (e) {
      openDialog(e?.response?.data?.message);
      findDispatch({ name: "verifyToken", value: null });
    }
  };

  return (
    <>
      <TitleHelmet title="아이디 찾기" />
      <div className={styles["find-method-box"]}>
        <PhoneAuth
          phoneNumber={phoneNumber}
          onChange={onChangeInput}
          complete={verifyToken}
          onComplete={onAuthComplete}
        />
      </div>
      <SignButton
        disabled={!verifyToken}
        onClick={onClickPostVerfiyToken}
        name="다음"
      />
    </>
  );
};

export default FindIDContainer;
