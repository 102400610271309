import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { requestGetUserProfileByType } from "../../api/auth";
import { update_feeds } from "../feed";

const initialState = {
  loading: false,
  failure: false,
  success: false,
  error: null,

  user_info: null,
  write_posts: null,
  bookmark_posts: null,

  write_posts_end: false,
  bookmark_posts_end: false,
};

const mypageSlice = createSlice({
  name: "mypage",
  initialState,
  reducers: {
    get_mypage: (state, action) => {
      const isSameID =
        state.user_info?.user_id === parseInt(action.payload.user_id);
      if (!isSameID) {
        state.user_info = null;
        state.write_posts = null;
        state.bookmark_posts = null;
      }
      state.loading = true;
      state.failure = false;
      state.success = false;
    },
    put_mypage: (state, action) => {
      state.loading = true;
      state.failure = false;
      state.success = false;
    },
    get_mypage_success: (state, action) => {
      const { user, bookmarkBoardPreset, writeBoardPreset } = action.payload;

      state.user_info = user;
      if (writeBoardPreset) {
        state.write_posts = writeBoardPreset.map(post => post.board_id);
        state.write_posts_end = false;
      }
      if (bookmarkBoardPreset) {
        state.bookmark_posts = bookmarkBoardPreset.map(post => post.board_id);
        state.bookmark_posts_end = false;
      }
      state.loading = false;
      state.success = true;
    },
    put_mypage_success: (state, action) => {
      const { bookmarkBoardPreset, writeBoardPreset, flag } = action.payload;

      if (writeBoardPreset) {
        state.write_posts.push(...writeBoardPreset.map(post => post.board_id));
        state.write_posts_end = flag;
      }
      if (bookmarkBoardPreset) {
        state.bookmark_posts.push(
          ...bookmarkBoardPreset.map(post => post.board_id)
        );
        state.bookmark_posts_end = flag;
      }
      state.loading = false;
      state.success = true;
    },
    get_mypage_failure: (state, action) => {
      state.loading = false;
      state.failure = true;
      state.error = action.payload;
    },
  },
});

export const {
  get_mypage,
  put_mypage,
  get_mypage_success,
  put_mypage_success,
  get_mypage_failure,
} = mypageSlice.actions;

function* get_mypage_saga(action) {
  try {
    const { access_token, user_id, type } = action.payload;
    const res = yield call(
      requestGetUserProfileByType,
      access_token,
      user_id,
      type
    );
    yield put(get_mypage_success(res.data));
    yield put(update_feeds(res.data.writeBoardPreset));
    yield put(update_feeds(res.data.bookmarkBoardPreset));
  } catch (e) {
    yield put(get_mypage_failure(e.response));
  }
}

function* put_mypage_saga(action) {
  try {
    const { access_token, user_id, type, offset, limit } = action.payload;
    const res = yield call(
      requestGetUserProfileByType,
      access_token,
      user_id,
      type,
      offset,
      limit
    );
    yield put(put_mypage_success(res.data));
    yield put(update_feeds(res.data.writeBoardPreset));
    yield put(update_feeds(res.data.bookmarkBoardPreset));
  } catch (e) {
    yield put(get_mypage_failure(e.response));
  }
}

export function* mypage_saga() {
  yield takeLatest(get_mypage.type, get_mypage_saga);
  yield takeLatest(put_mypage.type, put_mypage_saga);
}

export default mypageSlice.reducer;
