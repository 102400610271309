import React from "react";
import { Switch, Route } from "react-router-dom";

import ContactPage from "./ContactPage";
import QnaPage from "./QnaPage";
import QnaViewPage from "./QnaViewPage";
import QnaWritePage from "./QnaWritePage";
import ReleaseNotePage from "./ReleaseNotePage";

import { Paths } from "../../paths";

const ContactRoutePage = () => {
  return (
    <Switch>
      <Route path={Paths.contact.index} component={ContactPage} exact />
      <Route path={Paths.contact.qna.index} component={QnaPage} exact />
      <Route
        path={`${Paths.contact.qna.write}/:id?`}
        component={QnaWritePage}
        exact
      />
      <Route
        path={`${Paths.contact.qna.index}/:id`}
        component={QnaViewPage}
        exact
      />
      <Route
        path={`${Paths.contact.release}/:id`}
        component={ReleaseNotePage}
        exact
      />
    </Switch>
  );
};

export default ContactRoutePage;
