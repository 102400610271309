import React from "react";
import styles from "./SkeletonQna.module.scss";

const SkeletonQna = () => {
  return (
    <div className={styles["skeleton-qna"]}>
      <div className={styles["title"]} />
      <div className={styles["created-at"]} />
      <div className={styles["status"]} />
    </div>
  );
};

export const SkeletonQnas = () => {
  return (
    <>
      <SkeletonQna />
      <SkeletonQna />
      <SkeletonQna />
      <SkeletonQna />
    </>
  );
};

export default SkeletonQna;
