import { useEffect } from "react";

const useBlockRefresh = () => {
  useEffect(() => {
    const onBeforeUnload = event => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);
};

export default useBlockRefresh;
