import styled from "styled-components";
// React.js module

import { ReactComponent as CwSVG } from "../../icons/tooltip/cw.svg";
import { ReactComponent as CcwSVG } from "../../icons/tooltip/ccw.svg";
// icons

/** Tooltip을 감싸고 있는 스타일 컴포넌트 */
export const StyleFunctionTooltipTitleWrapper = styled.div`
  padding: 4px;
`;

/** Tooltip의 텍스트 line 스타일 컴포넌트 */
export const StyleFunctionTooltipTextLine = styled.div`
  display: flex;
  align-items: center;

  line-height: 1.5;
  width: 100%;
`;

/** Tooltip의 텍스트 스타일 컴포넌트  */
export const StyleFunctionTooltipText = styled.span`
  font-size: 11px;
  color: #fff;
`;

/** Tooltip의 시계 / 반시계 영역 스타일 컴포넌트 */
export const StyleFunctionDialClockArea = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
`;

/** Tooltip의 시계 / 반시계 영역 텍스트 스타일 컴포넌트 */
export const StyleFunctionDialClockVector = styled.span`
  margin-left: 4px;
`;

/** 시계 방향 아이콘 스타일 컴포넌트 */
export const CwIcon = styled(CwSVG)`
  width: 12px;
  height: 12px;
`;

/** 반시계 방향 아이콘 스타일 컴포넌트 */
export const CcwIcon = styled(CcwSVG)`
  width: 12px;
  height: 12px;
`;
