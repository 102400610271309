import React from "react";

import { IconButton } from "@material-ui/core";

import ProfileCoverIcon from "../profile/cover/ProfileCoverIcon";

import styles from "./RepresentativeIconButton.module.scss";

const RepresentativeIconButton = ({ onClick, src }) => {
  return (
    <IconButton className={styles["representative-icon"]} onClick={onClick}>
      <ProfileCoverIcon src={src} size={{ width: 60, height: 60 }} />
    </IconButton>
  );
};

export default RepresentativeIconButton;
