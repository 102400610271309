import { Paths } from "../paths";

const PATHS = {
  user: "users",
  user_logout: "users/logout",
  user_profile: "users/profile",
  user_personal_info: "users/personal_info",
  user_email: "users/email",
  user_password: "users/password",
  user_find_password: "users/find/password",

  user_signin: "auth/user/signin",

  auth_verify_phone: "auth/verify/phone",
  auth_verify_email: "auth/verify/email",
  auth_verify_confirm: "auth/verify/confirm",
  auth_issue_temporary: "auth/user/temporary",

  view_community_base: "views/community/base",
  view_community_main: "views/community/main",
  view_community_contact: "views/community/contact",
  view_community_search: "views/community/search",
  view_community_search_user: "views/community/search/user",
  view_community_search_release: "views/community/search/release",
  view_community_search_faq: "views/community/search/faq",

  board_preset: "board/presets",
  board_preset_decrypt: "board/presets/decrypt",

  reply: "replies",

  search: "searches",

  qna: "supports/qnas",

  "release-note": "supports/release-notes",
  "shared-files": "shared-files",
};

export const resolveAPIURL = name => {
  const path = PATHS[name];

  if (path === undefined) {
    throw new Error(`Invalid API name: ${name}`);
  }

  return `${Paths.apiV2}/${path}`;
};
