import React from "react";

import styles from "./SearchError.module.scss";

const SearchError = ({ text }) => {
  return (
    <div className={styles["wrapper"]}>
      <p className={styles["error-text"]}>{text}</p>
    </div>
  );
};

export default SearchError;
