import React from "react";
import classNames from "classnames/bind";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";

import { Paths } from "../../paths";

import styles from "./Header.module.scss";

const cn = classNames.bind(styles);

const DropdownMenu = ({
  unopenable,
  onClickLogout,
  onClose,
  user_page_url,
}) => {
  return (
    <div className={cn("dropdown", { unopenable })} onClick={onClose}>
      <ul className={styles["dropdown-list"]}>
        <ButtonBase component="li" className={styles["dropdown-element"]}>
          <Link className={styles["navigator"]} to={user_page_url}>
            마이 페이지
          </Link>
        </ButtonBase>
        <ButtonBase
          component="li"
          className={styles["dropdown-element"]}
          name="profile"
        >
          <Link className={styles["navigator"]} to={Paths.user.profile}>
            프로필 변경
          </Link>
        </ButtonBase>
        <ButtonBase
          component="li"
          className={styles["dropdown-element"]}
          name="info"
        >
          <Link className={styles["navigator"]} to={Paths.user.info}>
            개인정보 변경
          </Link>
        </ButtonBase>
        <ButtonBase
          component="li"
          className={styles["dropdown-element"]}
          name="password"
        >
          <Link className={styles["navigator"]} to={Paths.user.password}>
            비밀번호 변경
          </Link>
        </ButtonBase>
        <ButtonBase
          component="li"
          className={styles["dropdown-element"]}
          name="drop"
          onClick={onClickLogout}
        >
          로그아웃
        </ButtonBase>
      </ul>
    </div>
  );
};

export default DropdownMenu;
