import React from "react";

import ChangeProfileContainer from "../../../containers/mypage/update/ChangeProfileContainer";

import { useSetTitle } from "../../../contexts/MobileTitleContext";

const ChangeProfilePage = () => {
  useSetTitle("프로필 변경");
  return <ChangeProfileContainer />;
};

export default ChangeProfilePage;
