import React, { useEffect } from "react";
import qs from "qs";
import { useHistory } from "react-router-dom";

import TitleHelmet from "../components/title/TitleHelmet";

import { useSetTitle } from "../contexts/MobileTitleContext";
//api

import useDialog from "../hooks/useDialog";
import useUser from "../hooks/redux/useUser";

import { Paths } from "../paths";

const getRegisterType = register_type => {
  switch (register_type) {
    case "naver":
      return "네이버";
    case "kakao":
      return "카카오";
    case "google":
      return "구글";
    case "facebook":
      return "페이스북";
    default:
      return "일반";
  }
};

const OAuthPage = ({ location }) => {
  useSetTitle(null);
  const history = useHistory();
  const openDialog = useDialog();
  const { onLogin } = useUser();

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    const { message, account_token, name, more } = query;
    history.replace(Paths.oauth); // query 숨기기 용 replace
    if (message === "success") {
      if (account_token) {
        onLogin(account_token, true);
      }
    } else if (name === "AccountDuplicationError") {
      // 같은 정보로 가입된 계정.
      try {
        const { register_type } = JSON.parse(more);
        openDialog("같은 정보로 가입된 계정이 있습니다.", {
          text: `${getRegisterType(register_type)} 로그인 계정입니다.`,
          onClose: () => history.replace(Paths.index),
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      console.error(query);
      if (message) {
        openDialog(message, {
          onClose: () => history.replace(Paths.index),
        });
      } else {
        history.replace(Paths.index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TitleHelmet title="로그인" />;
};

export default OAuthPage;
