import React from "react";
import TitleHelmet from "../../components/title/TitleHelmet";
import styles from "./Error.module.scss";

const NotFoundContainer = () => {
  return (
    <article className={styles["container"]}>
      <TitleHelmet title="Not Found" />
      <section className={styles["content"]}>
        페이지를 찾을 수 없습니다.
      </section>
    </article>
  );
};

export default NotFoundContainer;
