import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { IconButton } from "@material-ui/core";

import ProfileButton from "./cover/ProfileButton";
import SkeletonDetailProfile from "./SkeletonDetailProfile";

import _Setting from "../../images/svg/settings.svg";

import { Paths } from "../../paths";

import styles from "./DetailProfile.module.scss";

const DetailProfile = ({ user, isMyself }) => {
  const history = useHistory();
  const location = useLocation();

  const onClickProfileImage = () => {
    history.push(`${Paths.profileimage}/${user?.user_id}`, {
      background: location,
    });
  };

  const renderUserName = useMemo(
    () => (isMyself || user?.name_is_public ? user?.name : "미공개"),
    [user, isMyself]
  );

  if (!user) {
    // 유저 데이터가 없으면 스켈레톤 노출
    return <SkeletonDetailProfile />;
  }

  return (
    <div className={styles["profile"]}>
      <div className={styles["image-view"]}>
        <div className={styles["profile-image"]}>
          <ProfileButton
            src={user?.profile_image}
            iconsrc={user?.representative_icon}
            borderwidth={5}
            onClick={onClickProfileImage}
          />
        </div>
      </div>
      <div className={styles["info"]}>
        <h3 className={styles["nickname"]}>{user?.nickname}</h3>
        <p className={styles["name"]}>{renderUserName}</p>
        <p className={styles["email"]}>{user?.email}</p>
        {user?.introduce && (
          <p className={styles["introduce"]}>{user?.introduce}</p>
        )}
        {isMyself && (
          <IconButton
            className={styles["setting"]}
            onClick={() => history.push(Paths.user.profile)}
          >
            <img className={styles["image"]} src={_Setting} alt="setting" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default DetailProfile;
