import React from "react";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paths } from "../../paths";
import _Logo from "../../images/svg/logo/invaiz_white.svg";

import EmptyResult from "../error/EmptyResult";
import { SkeletonReleaseNotes } from "./SkeletonReleaseNote";

//lib
import { dateToRelative } from "../../lib/formatter";

import styles from "./ReleaseNote.module.scss";

const ReleaseNote = ({ release_id, title, created_at, content }) => {
  return (
    <ButtonBase
      className={styles["note"]}
      component={Link}
      to={`${Paths.contact.release}/${release_id}`}
    >
      <div className={styles["thumbnail"]}>
        <img src={_Logo} alt="thumbnail" className={styles["thumbnail-logo"]} />
      </div>
      <div className={styles["content-box"]}>
        <h3 className={styles["title"]}>[ {title} ]</h3>
        <p className={styles["writer"]}>
          {`invaiz.official / ${dateToRelative(new Date(created_at))}`}
        </p>
        <div
          className={styles["content"]}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </ButtonBase>
  );
};

const ReleaseNoteList = ({ list, loading }) => {
  return (
    <section className={styles["list"]}>
      {list === null || loading ? (
        <SkeletonReleaseNotes />
      ) : list.length !== 0 ? (
        list.map(item => <ReleaseNote {...item} key={item.release_id} />)
      ) : (
        <EmptyResult height={260} />
      )}
    </section>
  );
};

export const ReleaseNotes = React.memo(ReleaseNoteList);

export default ReleaseNote;
