import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestGetSearchFaq,
  requestGetSearchProfile,
  requestGetSearchReleaseNote,
  requestGetSearchTotal,
} from "../../api/combination";
import { requestGetPresetList } from "../../api/preset";

import { update_feeds } from "../feed";

const initialState = {
  loading: false,
  failure: false,
  success: false,
  error: null,

  prev_query: null, // 이전 검색어
  result: {
    // 해당 검색어로 나온 검색 결과
    users: null,
    preset_posts: null,
    faqs: null,
    notes: null,
  },
  end: {
    // 해당 검색어의 리스트 끝부분 표시
    users: false,
    preset_posts: false,
    faqs: false,
    notes: false,
  },
};

const search_start = type => (state, action) => {
  state.failure = false;
  state.success = false;
  state.loading = true;
  if (type) {
    if (type === "all") {
      state.end = {
        users: false,
        preset_posts: false,
        faqs: false,
        notes: false,
      };
    } else {
      state.end[type] = false;
    }
  }
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    get_search_total: search_start("all"),
    get_search_preset: search_start("preset_posts"),
    get_search_user: search_start("users"),
    get_search_note: search_start("notes"),
    get_search_faq: search_start("faqs"),
    get_search_success: {
      reducer: (state, action) => {
        const { data, query } = action.payload;
        const { posts = null, notes = null, users = null, faqs = null } = data;
        state.loading = false;
        state.success = true;

        state.prev_query = query;
        state.result.users = users;
        state.result.preset_posts = posts
          ? posts.map(post => post.board_id)
          : null;
        state.result.notes = notes;
        state.result.faqs = faqs;
      },
      prepare: (data, query) => ({
        payload: { data, query },
      }),
    },
    put_search_preset: search_start(),
    put_search_success: (state, action) => {
      const {
        posts = null,
        // notes = null,
        // users = null,
        // faqs = null,
        flag,
      } = action.payload;
      state.loading = false;
      state.success = true;
      state.result.preset_posts.push(...posts.map(post => post.board_id));
      state.end.preset_posts = flag;
    },
    get_search_failure: (state, action) => {
      state.loading = false;
      state.failure = true;
      state.error = action.payload;
    },
  },
});

export const {
  get_search_total,
  get_search_preset,
  get_search_user,
  get_search_note,
  get_search_faq,
  put_search_preset,
  get_search_success,
  put_search_success,
  get_search_failure,
} = searchSlice.actions;

function* get_search_preset_saga(action) {
  try {
    const {
      access_token,
      query,
      user_id,
      model_id,
      support_program_id,
      support_os_id,
      order,
    } = action.payload;
    const res = yield call(
      requestGetPresetList,
      access_token,
      query,
      user_id,
      model_id,
      support_program_id,
      support_os_id,
      order
    );
    yield put(get_search_success(res.data, query));
    yield put(update_feeds(res.data.posts));
  } catch (e) {
    yield put(get_search_failure(e)); // 피드 데이터에 받아온 데이터 추가 및 갱신.
  }
}

function* put_search_preset_saga(action) {
  try {
    const {
      access_token,
      query,
      user_id,
      model_id,
      support_program_id,
      support_os_id,
      order,
      offset,
      limit,
    } = action.payload;
    const res = yield call(
      requestGetPresetList,
      access_token,
      query,
      user_id,
      model_id,
      support_program_id,
      support_os_id,
      order,
      offset,
      limit
    );
    yield put(put_search_success(res.data));
    yield put(update_feeds(res.data.posts));
  } catch (e) {
    yield put(get_search_failure(e)); // 피드 데이터에 받아온 데이터 추가 및 갱신.
  }
}

function* get_search_total_saga(action) {
  try {
    const { access_token, query } = action.payload;
    const res = yield call(requestGetSearchTotal, access_token, query);
    yield put(get_search_success(res.data, query));
    yield put(update_feeds(res.data.posts));
  } catch (e) {
    yield put(get_search_failure(e));
  }
}

function* get_search_user_saga(action) {
  try {
    const { access_token, query } = action.payload;
    const res = yield call(requestGetSearchProfile, access_token, query);
    yield put(get_search_success(res.data, query));
  } catch (e) {
    yield put(get_search_failure(e));
  }
}

function* get_search_note_saga(action) {
  try {
    const query = action.payload;
    const res = yield call(requestGetSearchReleaseNote, query);
    yield put(get_search_success(res.data, query));
  } catch (e) {
    yield put(get_search_failure(e));
  }
}

function* get_search_faq_saga(action) {
  try {
    const query = action.payload;
    const res = yield call(requestGetSearchFaq, query);
    yield put(get_search_success(res.data, query));
  } catch (e) {
    yield put(get_search_failure(e));
  }
}

export function* search_saga() {
  yield takeLatest(get_search_total.type, get_search_total_saga);
  yield takeLatest(get_search_preset.type, get_search_preset_saga);
  yield takeLatest(put_search_preset.type, put_search_preset_saga);
  yield takeLatest(get_search_user.type, get_search_user_saga);
  yield takeLatest(get_search_note.type, get_search_note_saga);
  yield takeLatest(get_search_faq.type, get_search_faq_saga);
}

export default searchSlice.reducer;
