export const Paths = {
  index: "/",
  signin: "/signin",
  signup: "/signup",
  user: {
    index: "/user",
    update: "/user/update",
    info: "/user/update/info",
    profile: "/user/update/profile",
    password: "/user/update/password",
    drop: "/user/update/drop",
    admin: "/user/update/admin",
  },
  find: {
    index: "/find",
    id: {
      index: "/find/id",
      view: "/find/id/view",
    },
    password: {
      index: "/find/password",
      reset: "/find/password/reset",
    },
  },
  search: {
    index: "/search",
    profile: "/search/profile",
    faq: "/search/faq",
    release: "/search/release",
  },
  preset: {
    index: "/preset",
    write: "/preset/write",
    comment: "/preset/comment",
  },
  contact: {
    index: "/contact",
    qna: {
      index: "/contact/qna",
      write: "/contact/qna/write",
    },
    release: "/contact/release",
  },
  comment: "/comment",
  policy: {
    index: "/policy",
    modal: "/policywindow",
  },
  profileimage: "/profileimage",
  oauth: "/OAuth",
  apiV1: process.env.COMMUNITY_APP_API_URL,
  apiV2: `${process.env.COMMUNITY_APP_API_URL}/v2`,
  contents: "https://invaiz.com/preset",
};
