import React, { createContext, useContext, useEffect, useState } from "react";

const MobileTitleContext = createContext({ title: null, setTitle: () => {} });
export default MobileTitleContext;

export const useSetTitle = title => {
  const { setTitle } = useContext(MobileTitleContext);
  useEffect(() => {
    setTitle(title);

    return () => setTitle("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const MobileTitleConsumer = ({ children: Children }) => {
  return (
    <MobileTitleContext.Consumer>
      {({ title }) => <Children>{title}</Children>}
    </MobileTitleContext.Consumer>
  );
};

export const MobileTitleProvider = ({ children, render }) => {
  const [title, setTitle] = useState("");

  return (
    <MobileTitleContext.Provider value={{ title, setTitle }}>
      <MobileTitleConsumer>{render}</MobileTitleConsumer>
      {children}
    </MobileTitleContext.Provider>
  );
};
