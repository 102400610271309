import React from "react";
import ViewIDContainer from "../../../../containers/find/id/ViewIDContainer";
import { useSetTitle } from "../../../../contexts/MobileTitleContext";
import useBlockRefresh from "../../../../hooks/useBlockRefresh";

const ViewIDPage = () => {
  useSetTitle("아이디 확인");
  useBlockRefresh();
  return <ViewIDContainer />;
};

export default ViewIDPage;
