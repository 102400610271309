import React from "react";
import ResetPWContainer from "../../../../containers/find/password/ResetPWContainer";
import { useSetTitle } from "../../../../contexts/MobileTitleContext";
import useBlockRefresh from "../../../../hooks/useBlockRefresh";

const ResetPWPage = () => {
  useSetTitle("비밀번호 재설정");
  useBlockRefresh();
  return <ResetPWContainer />;
};

export default ResetPWPage;
