import React from "react";

const BookmarkIcon = ({ on }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.808"
      height="24"
      viewBox="0 0 16.808 24"
    >
      <path
        d="M14.707,0H2.1A2.179,2.179,0,0,0,0,2.25V24l8.4-5.25,8.4,5.25V2.25A2.179,2.179,0,0,0,14.707,0Zm0,20.083L8.4,16.145,2.1,20.083V2.531a.272.272,0,0,1,.263-.281H14.444a.272.272,0,0,1,.263.281Z"
        fill={on ? "#0066ff" : " #9e9e9e"}
      />
    </svg>
  );
};

export default BookmarkIcon;
