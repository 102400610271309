import React from "react";

import PresetWriteContainer from "../../containers/preset/PresetWriteContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";
import useBlockRefresh from "../../hooks/useBlockRefresh";

const PresetWritePage = ({ match }) => {
  const { id } = match.params;
  const isUpdate = !!id;

  useSetTitle(`게시글 ${isUpdate ? "수정" : "작성"}`);
  useBlockRefresh();
  return <PresetWriteContainer id={id} />;
};

export default PresetWritePage;
