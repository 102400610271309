import React from "react";

const HomeIcon = ({ on }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        id="bx-home"
        d="M4.2,16.2H5.4v8.4A2.4,2.4,0,0,0,7.8,27H22.2a2.4,2.4,0,0,0,2.4-2.4V16.2h1.2a1.2,1.2,0,0,0,.848-2.049l-10.8-10.8a1.2,1.2,0,0,0-1.7,0l-10.8,10.8A1.2,1.2,0,0,0,4.2,16.2Zm8.4,8.4v-6h4.8v6ZM15,5.9l7.2,7.2v5.5l0,6H19.8v-6a2.4,2.4,0,0,0-2.4-2.4H12.6a2.4,2.4,0,0,0-2.4,2.4v6H7.8V13.1Z"
        transform="translate(-2.999 -2.999)"
        fill={on ? "#0066ff" : " #9e9e9e"}
      />
    </svg>
  );
};

export default HomeIcon;
