import React from "react";
import classNames from "classnames/bind";
import { Button } from "@material-ui/core";

import styles from "./UpdateTabs.module.scss";

const cn = classNames.bind(styles);

const UpdateTabs = ({ tabs, currentPath, onClickTab }) => {
  return (
    <div className={styles["tabs"]}>
      {tabs &&
        tabs.map(tab => (
          <Button
            className={cn("tab", {
              selected: tab.link === currentPath,
            })}
            onClick={() => onClickTab(tab.link)}
            key={tab.title}
          >
            {tab.title}
          </Button>
        ))}
    </div>
  );
};

export default UpdateTabs;
