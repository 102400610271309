import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { get_mypage, put_mypage } from "../../store/view/mypage";

import useToken from "../storage/useToken";
import useDialog from "../useDialog";
import useRefresh from "./useRefresh";

/**
 * 마이페이지 데이터 조회하기 위한 리덕스 훅.
 *
 * @param {number} user_id 마이 페이지에서 조회할 유저 id
 * @param {string} type 마이 페이지 작성 게시글, 북마크 탭 종류
 * @returns 마이 페이지 데이터
 */
const useMypage = (user_id, type) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const openDialog = useDialog();
  const access_token = useToken();

  const mypage = useSelector(state => state.mypage);
  const { error, loading, write_posts_end, bookmark_posts_end } = mypage;

  const callApiGetUserProfile = () => {
    // 마이 페이지의 기본적인 데이터를 가져옴.
    // type에 따라 게시글 데이터, 북마크 데이터 따로 요청
    if (!error) {
      dispatch(
        get_mypage({
          access_token,
          user_id,
          type,
        })
      );
    }
  };

  const loadApiGetUserProfile = (offset, limit) => {
    if (
      !loading &&
      (type === "writer" ? !write_posts_end : !bookmark_posts_end)
    ) {
      dispatch(
        put_mypage({
          access_token,
          user_id,
          type,
          offset,
          limit,
        })
      );
    }
  };

  useEffect(() => {
    const isSameID = mypage.user_info?.user_id === parseInt(user_id);
    const doRequest =
      type === "writer" ? !mypage.write_posts : !mypage.bookmark_posts;
    // API 요청을 해야하는지 확인.

    if (!isSameID || doRequest) {
      callApiGetUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mypage, type, user_id]);

  useEffect(() => {
    // Api 호출 에러가 발생했을 경우,
    if (error) {
      openDialog(error.data.message, {
        onClose: () => history.goBack(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useRefresh(callApiGetUserProfile, loading);

  return [mypage, loadApiGetUserProfile];
};

export default useMypage;
