const defaultPreset = {
  Groups: [
    {
      GroupName: "",
      button: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
      dial: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
    },
    {
      GroupName: "",
      button: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
      dial: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
    },
    {
      GroupName: "",
      button: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
      dial: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
    },
    {
      GroupName: "",
      button: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
      dial: [
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
        { fname: "", etype: "", fcode: {} },
      ],
    },
  ],
};

export default defaultPreset;
