import { useDispatch } from "react-redux";
import {
  requestPostPresetReply,
  requestDeleteReply,
  requestPutReply,
} from "../../api/comment";
import { add_comment, update_comment, delete_comment } from "../../store/feed";
import { useTokenCallback } from "../storage/useToken";
import useDialog from "../useDialog";

const useComment = (board_id, reply_id) => {
  const dispatch = useDispatch();
  const openDialog = useDialog();

  const callApiPostPresetReply = useTokenCallback(
    async (access_token, content, pushBack = false) => {
      if (content) {
        try {
          const res = await requestPostPresetReply(
            access_token,
            board_id,
            content,
            reply_id
          );
          dispatch(add_comment(res.data.reply, board_id, pushBack));
        } catch (e) {
          openDialog(e?.response?.data?.message);
        }
      }
    },
    {
      isRequired: true,
    }
  );

  const callApiPutPresetReply = useTokenCallback(
    async (access_token, content) => {
      try {
        const res = await requestPutReply(access_token, reply_id, content);
        dispatch(update_comment(res.data.reply, board_id, reply_id));
      } catch (e) {
        openDialog(e?.response?.data?.message);
      }
    },
    {
      isRequired: true,
    }
  );

  const callApiDeletePresetReply = useTokenCallback(
    async access_token => {
      try {
        await requestDeleteReply(access_token, reply_id);
        dispatch(delete_comment(board_id, reply_id));
      } catch (e) {
        openDialog(e?.response?.data?.message);
      }
    },
    {
      isRequired: true,
    }
  );

  return {
    postComment: callApiPostPresetReply,
    updateComment: callApiPutPresetReply,
    deleteComment: callApiDeletePresetReply,
  };
};

export default useComment;
