import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { snackbarClose, snackbarOpen } from "../store/snackbar";

let timer = null;
export const useSnackbar = () => {
  const snackbarDispatch = useDispatch();
  const openSnackbar = useCallback(
    ({ message, variant, up }, time = 3000) => {
      snackbarDispatch(snackbarOpen(message, variant, up));

      clearTimeout(timer);
      // 이 전에 기록된 타이머를 제거함.
      timer = setTimeout(() => {
        snackbarDispatch(snackbarClose());
      }, time);
    },
    [snackbarDispatch]
  );
  return openSnackbar;
};
