import React, { useState } from "react";

import SignButton from "../../../components/button/SignButton";
import FindTextInput from "../../../components/input/FindTextInput";
import TitleHelmet from "../../../components/title/TitleHelmet";

//hooks
import useDialog from "../../../hooks/useDialog";
import useInputs from "../../../hooks/useInputs";
import usePassword from "../../../hooks/usePassword";
import { useTokenEffect } from "../../../hooks/storage/useToken";

//api
import { requestPutPassword } from "../../../api/auth";

import styles from "./UpdateProfileContainer.module.scss";

const initialState = {
  passwordPrev: "",
  password: "",
  passwordCheck: "",
};

const PasswordContainer = () => {
  const access_token = useTokenEffect();
  const openDialog = useDialog();

  const [passwordState, onChangeInput] = useInputs(initialState);

  const [password_prev_error, setPasswordPrevError] = useState("");

  const { passwordPrev, password, passwordCheck } = passwordState;

  const [ruleCheck, sameCheck] = usePassword(password, passwordCheck);

  const canChange =
    passwordPrev !== "" &&
    password !== "" &&
    passwordCheck !== "" &&
    ruleCheck.value &&
    sameCheck.value;
  // 비밀번호 변경 여부 판단(모든 요소 작성했는지.)

  const onBlurPasswordPrev = () => setPasswordPrevError("");

  const onClickPutChangePassword = async e => {
    e.preventDefault();
    try {
      await requestPutPassword(access_token, passwordPrev, password);
      openDialog("성공적으로 변경되었습니다.");
    } catch (e) {
      setPasswordPrevError(e?.response?.data?.message);
    }
  };
  return (
    <form className={styles["layout"]} onSubmit={onClickPutChangePassword}>
      <TitleHelmet title="비밀번호 변경" />
      <FindTextInput
        label="이전 비밀번호"
        type="password"
        name="passwordPrev"
        value={passwordPrev}
        onChange={onChangeInput}
        onBlur={onBlurPasswordPrev}
        required
        error={password_prev_error}
      />
      <FindTextInput
        label="새 비밀번호"
        type="password"
        name="password"
        value={password}
        onChange={onChangeInput}
        onBlur={ruleCheck.onEvent}
        required
        error={ruleCheck.error}
      />
      <FindTextInput
        label="새 비밀번호 확인"
        type="password"
        name="passwordCheck"
        onChange={onChangeInput}
        onBlur={sameCheck.onEvent}
        required
        error={sameCheck.error}
      />
      <SignButton name="변경하기" disabled={!canChange} />
    </form>
  );
};

export default PasswordContainer;
