import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_keywords } from "../../store/search/keyword";

import styles from "./Keyword.module.scss";

const Keyword = ({ search, onClick }) => {
  const dispatch = useDispatch();

  const { keyword_dict } = useSelector(state => state.keyword);

  const keywords = keyword_dict[search];

  useEffect(() => {
    if (!keyword_dict[search]) {
      dispatch(get_keywords(search));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, keyword_dict]);

  return (
    <section className={styles["keywords"]}>
      {keywords?.map(item => {
        return (
          <div
            className={styles["item"]}
            key={item.keyword}
            onClick={() => onClick(item.keyword)}
          >
            <div className={styles["keyword"]}>{item.keyword}</div>
          </div>
        );
      })}
    </section>
  );
};

export default React.memo(Keyword);
