import React from "react";
import styles from "./SkeletonFaq.module.scss";

const SkeletonFaq = () => {
  return (
    <div className={styles["skeleton-faq"]}>
      <div className={styles["skeleton-title"]} />
    </div>
  );
};

export const SkeletonFaqs = () => {
  return (
    <>
      <SkeletonFaq />
      <SkeletonFaq />
      <SkeletonFaq />
    </>
  );
};

export default SkeletonFaq;
