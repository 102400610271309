import React from "react";
/* Library */

import { useSelector } from "react-redux";
/* Redux */

import Snackbar from "../../components/assets/Snackbar";
/* Components */

const SnackbarContainer = () => {
  const state = useSelector(state => state.snackbar);
  return <Snackbar {...state} />;
};

export default SnackbarContainer;
