import React, { useEffect, useRef, useReducer, useMemo, useState } from "react";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";

import PageTitle from "../../components/title/PageTitle";
import WriteButton from "../../components/button/write/WriteButton";
import Loading from "../../components/assets/Loading";
import TitleHelmet from "../../components/title/TitleHelmet";

//hooks
import { useTokenEffect } from "../../hooks/storage/useToken";
import useDialog from "../../hooks/useDialog";

//api
import {
  requestPostQna,
  requestGetQnaItem,
  requestPutQnaUpdate,
} from "../../api/qna";

import styles from "./QnaWriteContainer.module.scss";

const cn = classNames.bind(styles);

const SET_INIT = "SET_INIT";
const UPDATE_STATUS = "UPDATE_STATUS";
const initialState = {
  title: "", // 작성할 제목
  question: "", // 작성할 내용
  previous: {
    title: "", // 이전 제목
    question: "", // 이전 내용
  },
};
// 1:1 문의 작성 데이터 상태.
const reducer = (state, action) => {
  // 1:1 문의 작성에 사용할 리듀서 함수
  switch (action.type) {
    case SET_INIT:
      return {
        ...state,
        ...action.payload,
        previous: {
          ...action.payload,
        },
      };
    case UPDATE_STATUS:
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
      };
    default:
      return initialState;
  }
};

/**
 * 1:1 문의 작성 컨테이너.
 *
 * id 값이 있을 경우, 해당 id 값을 수정함.
 *
 * @param {number} id 수정할 id 값
 * @returns 1:1 문의 작성 컨테이너
 */
const QnaWriteContainer = ({ id }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const openDialog = useDialog();
  const [qna, dispatchQna] = useReducer(reducer, initialState);

  const access_token = useTokenEffect();

  const ref = useRef(null);

  const { title, question, previous } = qna;

  const isUpdate = id !== undefined; // update 상태인지 유무

  const isChanged = title !== previous.title || question !== previous.question;
  // 이전 상태와 비교해서 변경점이 있는지 확인.
  const canWrite = isChanged && title.length !== 0 && question.length !== 0;
  // 작성 / 수정 요청할 수 있는 상태인지 확인.

  const onChangeInput = e =>
    dispatchQna({ type: UPDATE_STATUS, payload: e.target });
  // 1:1 문의 제목, 질문 변경 리스너

  const onClickWrite = async () => {
    // 1:1 문의 작성 / 수정 api 호출
    setLoading(true);
    try {
      isUpdate
        ? await requestPutQnaUpdate(access_token, id, title, question)
        : await requestPostQna(access_token, title, question);

      const dialogTitle = `문의가 ${isUpdate ? "수정" : "작성"}되었습니다.`;
      const dialogText = isUpdate
        ? ""
        : "확인 후 최대한 빨리 답변해드리도록 하겠습니다.";
      openDialog(dialogTitle, {
        text: dialogText,
        onClose: () => history.goBack(),
      });
    } catch (e) {
      openDialog("업로드 중 오류가 발생했습니다.", {
        text: "잠시후 다시 시도하여주세요.",
      });
    }
    setLoading(false);
  };
  // 1:1 문의 작성 / 수정 버튼 리스너

  const callApiGetQna = async () => {
    // 1:1 문의 수정 시 내용 가져오기 api 호출
    try {
      const res = await requestGetQnaItem(access_token, id);
      dispatchQna({
        type: SET_INIT,
        payload: res.data.qna,
      });
    } catch (e) {
      openDialog(e?.response?.data?.message, {
        onClose: () => history.goBack(),
      });
    }
  };

  useEffect(() => {
    // 수정 시 id 값이 바뀔 때마다 1:1 문의 상세 정보 가져옴.
    if (isUpdate) {
      callApiGetQna();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // mount시 title로 포커싱.
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const renderPageTitle = useMemo(
    () => (isUpdate ? "수정" : "작성"),
    [isUpdate]
  );

  return (
    <article className={styles["container"]}>
      <TitleHelmet title={`문의 ${renderPageTitle}`} />
      <PageTitle>{`1:1 문의 ${renderPageTitle}`}</PageTitle>
      <section className={styles["content"]}>
        <div className={styles["title-area"]}>
          <input
            ref={ref}
            type="text"
            className={cn("input", "title")}
            placeholder={isUpdate ? "" : "제목을 입력해 주세요."}
            name="title"
            value={title}
            onChange={onChangeInput}
          />
        </div>
        <div className={styles["content-area"]}>
          <textarea
            className={cn("input", "content")}
            placeholder={isUpdate ? "" : "문의 사항을 입력해 주세요."}
            name="question"
            value={question}
            onChange={onChangeInput}
          />
        </div>
        <WriteButton
          text={isUpdate ? "문의수정" : "문의작성"}
          onClick={onClickWrite}
          disabled={!canWrite}
        />
      </section>
      <Loading open={loading} />
    </article>
  );
};

export default QnaWriteContainer;
