import React from "react";
import { Paths } from "../../../paths";
import { useHistory } from "react-router-dom";

import { requestPutFindPassword } from "../../../api/auth";

import TitleHelmet from "../../../components/title/TitleHelmet";
import SignButton from "../../../components/button/SignButton";
import FindTextInput from "../../../components/input/FindTextInput";

import useDialog from "../../../hooks/useDialog";
import useTemporaryToken from "../../../hooks/storage/useTemporaryToken";
import useInputs from "../../../hooks/useInputs";
import usePassword from "../../../hooks/usePassword";

import styles from "./ResetPWContainer.module.scss";

const initialState = {
  password: "",
  passwordCheck: "",
};

const ResetPWContainer = () => {
  const history = useHistory();
  const temporary_token = useTemporaryToken(Paths.find.password.index);
  const openDialog = useDialog();

  const [reset, onChangeInput] = useInputs(initialState);

  const { password, passwordCheck } = reset;

  const [ruleCheck, sameCheck] = usePassword(password, passwordCheck);

  const canChangePassword =
    password !== "" &&
    passwordCheck !== "" &&
    ruleCheck.value &&
    sameCheck.value;
  // 비밀번호 변경 여부 판단(모든 요소 작성했는지.)

  // 비밀번호 재설정
  const onClickPutPassword = async () => {
    try {
      const res = await requestPutFindPassword(temporary_token, password);
      if (res.status === 200) {
        openDialog("성공적으로 변경되었습니다", {
          onClose: () => history.replace(Paths.signin),
        });
      } else {
        openDialog(res.data.message);
      }
    } catch (e) {
      console.log(e.response);
      openDialog(e?.response?.data?.message);
    }
  };

  return (
    <>
      <TitleHelmet title="비밀번호 재설정" />
      <h3 className={styles["sub-title"]}>비밀번호를 재설정 해주세요.</h3>
      <div className={styles["reset-input-box"]}>
        <FindTextInput
          label="새 비밀번호"
          type="password"
          name="password"
          onChange={onChangeInput}
          onBlur={ruleCheck.onEvent}
          required
          error={ruleCheck.error}
        />
        <FindTextInput
          label="새 비밀번호 확인"
          type="password"
          name="passwordCheck"
          onChange={onChangeInput}
          onBlur={sameCheck.onEvent}
          required
          error={sameCheck.error}
        />
        <SignButton
          name="완료"
          onClick={onClickPutPassword}
          disabled={!canChangePassword}
        />
      </div>
    </>
  );
};

export default ResetPWContainer;
