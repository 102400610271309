import React from "react";
import FindPWContainer from "../../../../containers/find/password/FindPWContainer";
import { useSetTitle } from "../../../../contexts/MobileTitleContext";

const FindPWPage = () => {
  useSetTitle("비밀번호 찾기");
  return <FindPWContainer />;
};

export default FindPWPage;
