const MS = 1000;
const DIF_MINUTES = 60 * MS;
const DIF_HOURS = 60 * DIF_MINUTES;
const DIF_DAYS = 24 * DIF_HOURS;

const crossBrowsingDate = date => {
  if (date) {
    const setDate = typeof date === "string" ? date.replace(/-/g, "/") : date;
    return new Date(setDate);
  }
  return new Date(0);
};
const dateFormatting = date => (date < 10 ? "0" + date : date); // 달력의 수치를 두 자리로 변환해주는 함수
export const dateToYYYYMMDD = (date, join = "-") => {
  // Javascript Date 객체를 형식에 맞게 변환하여 표현함.
  const absolute = crossBrowsingDate(date); // 만약에 Date 객체가 넘어오지 않을 것을 대비
  const monthFormatting = dateFormatting(absolute.getMonth() + 1); // 월을 두 자리로 변환
  const dayFormatting = dateFormatting(absolute.getDate()); // 일을 두 자리로 변환
  return absolute.getFullYear() + join + monthFormatting + join + dayFormatting;
};

export const dateToRelative = (date, join = "-") => {
  // Javascript Date 객체를 현재 시간과 비교하여 표현함.
  const current = Date.now(),
    relative = crossBrowsingDate(date);
  const elapsed = current - relative.getTime();

  if (elapsed >= DIF_DAYS) {
    return dateToYYYYMMDD(relative, join);
  } else if (elapsed >= DIF_HOURS) {
    return `${Math.floor(elapsed / DIF_HOURS)}시간 전`;
  } else if (elapsed >= DIF_MINUTES) {
    return `${Math.floor(elapsed / DIF_MINUTES)}분 전`;
  } else if (elapsed >= 0) {
    return `${Math.floor(elapsed / MS)}초 전`;
  } else if (elapsed >= -DIF_MINUTES) {
    return `${-Math.ceil(elapsed / MS)}초 후`;
  } else if (elapsed >= -DIF_HOURS) {
    return `${-Math.ceil(elapsed / DIF_MINUTES)}분 후`;
  } else if (elapsed >= -DIF_DAYS) {
    return `${-Math.ceil(elapsed / DIF_HOURS)}시간 후`;
  } else {
    return dateToYYYYMMDD(relative, join);
  }
};
