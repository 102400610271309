import React from "react";
import SearchError from "../../components/error/SearchError";

import { Faqs } from "../../components/faq/Faq";
import TitleHelmet from "../../components/title/TitleHelmet";
import { useSearchFaqs } from "../../hooks/redux/useSearch";

/**
 * 자주 묻는 질문 검색 컨테이너.
 *
 * 커뮤니티 자주 묻는 질문을 검색하여, 자주 묻는 질문 리스트 렌더.
 */
const SearchFaqContainer = ({ query }) => {
  const { q } = query; // url query 값

  const [faqs, loading] = useSearchFaqs(q);

  return (
    <>
      <TitleHelmet title="자주 묻는 질문 검색" />
      {q ? (
        <Faqs loading={loading} list={faqs} />
      ) : (
        <SearchError text="검색어를 입력해 주세요." />
      )}
    </>
  );
};

export default SearchFaqContainer;
