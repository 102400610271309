import React from "react";
import { Tooltip } from "@material-ui/core";
// React.js module

import language from "../../language.json";
// languages

import {
  CcwIcon,
  CwIcon,
  StyleFunctionDialClockArea,
  StyleFunctionDialClockVector,
  StyleFunctionTooltipText,
  StyleFunctionTooltipTextLine,
  StyleFunctionTooltipTitleWrapper,
} from "./FunctionTooltipStyle";
// styles

const TOOLTIP = language.TOOLTIP;

/**
 * 'string의 첫번째 문자를 UpperCase로 변경하는 Function'
 *
 * @param str 변경할 string
 * @returns 변경된 string
 */
const toStringFirstUpperCase = str =>
  str.charAt(0).toUpperCase() + str.slice(1);

/**
 * '`ForceKey` 데이터를 출력할 수 있게 string으로 변경하는 Function'
 *
 * @param keys 입력 key 배열
 * @param modifiers 입력 modifier 배열
 *
 * @returns 출력 가능한 string
 */
const toStringForceKey = ({ keys = [], modifiers = [] }) => {
  const mix_str = modifiers.map(m => toStringFirstUpperCase(m)).concat(keys);
  return mix_str.join(" + ");
};
/**
 * 'Tooltip의 내용을 보여줄 컴포넌트'
 *
 * `Function`의 etype에 따라 형식에 맞게 렌더함
 *
 * @param func Tooltip으로 보여질 `Function`
 *
 * @returns Tooltip의 내용을 보여줄 컴포넌트
 */
const FunctionTooltipTitle = ({ func }) => {
  const { fname, etype, fcode } = func;

  switch (etype) {
    case "sendCepScript":
    case "sendLrScript":
      return (
        <StyleFunctionTooltipText>
          {fname} {TOOLTIP.cep}
        </StyleFunctionTooltipText>
      );
    case "sendDialKeyboards":
      return (
        <>
          <StyleFunctionTooltipTextLine>
            <StyleFunctionDialClockArea>
              <CwIcon />
              <StyleFunctionDialClockVector>
                {TOOLTIP.cw}
              </StyleFunctionDialClockVector>
            </StyleFunctionDialClockArea>
            <StyleFunctionTooltipText>
              {toStringForceKey(fcode.rotate.left)}
            </StyleFunctionTooltipText>
          </StyleFunctionTooltipTextLine>
          <StyleFunctionTooltipTextLine>
            <StyleFunctionDialClockArea>
              <CcwIcon />
              <StyleFunctionDialClockVector>
                {TOOLTIP.ccw}
              </StyleFunctionDialClockVector>
            </StyleFunctionDialClockArea>
            <StyleFunctionTooltipText>
              {toStringForceKey(fcode.rotate.right)}
            </StyleFunctionTooltipText>
          </StyleFunctionTooltipTextLine>
        </>
      );
    case "sendDialMouse":
      return (
        <>
          <StyleFunctionTooltipTextLine>
            {toStringForceKey(fcode)}
          </StyleFunctionTooltipTextLine>
          <StyleFunctionTooltipTextLine>
            {TOOLTIP.scroll[fcode.axis]}
          </StyleFunctionTooltipTextLine>
        </>
      );
    case "sendKeyboards":
      return (
        <StyleFunctionTooltipText>
          {toStringForceKey(fcode)}
        </StyleFunctionTooltipText>
      );
    case "sendMacro":
      return (
        <>
          {fcode.map((f, index) => (
            <React.Fragment key={index}>
              <FunctionTooltipTitle func={f} />
            </React.Fragment>
          ))}
        </>
      );
    case "sendTyping":
      return (
        <StyleFunctionTooltipText>
          {fcode.message} {TOOLTIP.typing}
        </StyleFunctionTooltipText>
      );

    case "sendDelay":
      return (
        <StyleFunctionTooltipText>
          {`${fcode / 1000}${TOOLTIP.delay}`}
        </StyleFunctionTooltipText>
      );
    default:
      return <></>;
  }
};

/**
 * '`Function` 상세보기 Tooltip 컴포넌트'
 *
 * `Function` 데이터를 보다 상세하게 보기 위하여
 * Mouse Hover 시 Tooltip을 통해 보여줌
 *
 * @param func 상세보기할 `Function`
 * @param children Tooltip을 포함시켜 렌더할 children
 *
 * @returns `Function` 상세보기 Tooltip 컴포넌트
 */
const FunctionTooltip = ({ func, children }) => {
  if (func.etype === "") return children;
  return (
    <Tooltip
      // followCursor
      arrow
      title={
        <StyleFunctionTooltipTitleWrapper>
          <FunctionTooltipTitle func={func} />
        </StyleFunctionTooltipTitleWrapper>
      }
    >
      {children}
    </Tooltip>
  );
};

export default FunctionTooltip;
