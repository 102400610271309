import React from "react";
import ProfileButton from "../profile/cover/ProfileButton";

import styles from "./SkeletonComment.module.scss";

const SkeletonComment = () => {
  return (
    <div className={styles["comment"]}>
      <div className={styles["image-view"]}>
        <div className={styles["profile-image"]}>
          <ProfileButton borderwidth={1} />
        </div>
      </div>
      <div className={styles["content"]}>
        <p className={styles["name"]} />
        <p className={styles["text"]} />
        <p className={styles["created-at"]} />
      </div>
      <div className={styles["more-area"]}>
        <div className={styles["more"]} />
      </div>
    </div>
  );
};

export const SkeletonComments = () => {
  return (
    <>
      <SkeletonComment />
      <SkeletonComment />
      <SkeletonComment />
      <SkeletonComment />
    </>
  );
};

export default SkeletonComment;
