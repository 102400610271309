import React from "react";
import { Link } from "react-router-dom";

import styles from "./TitleLabel.module.scss";

const TitleLabel = ({ title, more }) => {
  return (
    <div className={styles["title-box"]}>
      <h3 className={styles["title"]}>{title}</h3>
      {more && (
        <Link className={styles["more"]} to={more.link}>
          {more.text ? `더 많은 ${more.text} 보러가기 >` : "더보기 >"}
        </Link>
      )}
    </div>
  );
};

export default TitleLabel;
