import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestGetViewBase = token => {
  const url = resolveAPIURL("view_community_base");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios.get(url, config);
};

export const requestGetViewMain = token => {
  const url = resolveAPIURL("view_community_main");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios.get(url, config);
};

export const requestGetViewContact = () => {
  const url = resolveAPIURL("view_community_contact");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.get(url, config);
};

export const requestGetSearchTotal = (token, query) => {
  const url = resolveAPIURL("view_community_search");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      keyword: query,
    },
  };
  return axios.get(url, config);
};

export const requestGetSearchProfile = (token, query) => {
  const url = resolveAPIURL("view_community_search_user");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      keyword: query,
    },
  };
  return axios.get(url, config);
};

export const requestGetSearchReleaseNote = query => {
  const url = resolveAPIURL("view_community_search_release");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      keyword: query,
    },
  };
  return axios.get(url, config);
};

export const requestGetSearchFaq = query => {
  const url = resolveAPIURL("view_community_search_faq");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      keyword: query,
    },
  };
  return axios.get(url, config);
};
