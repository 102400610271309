import React from "react";

const CloseIcon = ({ black }) => {
  return (
    <svg width="21.414" height="21.414" viewBox="0 0 21.414 21.414">
      <g transform="translate(0.707 0.707)">
        <line
          x2="20"
          y2="20"
          fill="none"
          stroke={black ? "#000" : "#fff"}
          strokeWidth="2"
        />
        <line
          x1="20"
          y2="20"
          fill="none"
          stroke={black ? "#000" : "#fff"}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
