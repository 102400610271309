import React, { useEffect, useReducer } from "react";

import FindTextInput from "../../../components/input/FindTextInput";
import FindSelect from "../../../components/input/FindSelect";
import SignButton from "../../../components/button/SignButton";
import TitleHelmet from "../../../components/title/TitleHelmet";

//hooks
import useDialog from "../../../hooks/useDialog";
import { useTokenEffect } from "../../../hooks/storage/useToken";
import useUser from "../../../hooks/redux/useUser";

//api
import { requestPutPersonalInfo } from "../../../api/auth";

import styles from "./UpdateProfileContainer.module.scss";

const SET_INIT_USER = "SET_INIT_USER";
const SET_ONE = "SET_ONE";

const initialState = {
  sex: "male",
  address: "",
  job: "",
  company: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_INIT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ONE:
      const { name, value } = action;
      return {
        ...state,
        [name]: value,
      };
    default:
      return initialState;
  }
};

const ChangeInfoContainer = () => {
  const openDialog = useDialog();

  const { user, onUpdate } = useUser();
  const access_token = useTokenEffect();

  const [userProfile, dispatchUser] = useReducer(reducer, initialState);

  const { sex, address, job, company } = userProfile;

  const onChangeInput = e => {
    dispatchUser({
      type: SET_ONE,
      name: e.target.name,
      value: e.target.value,
    });
  };

  const onClickPutPersonalInfo = async () => {
    try {
      const res = await requestPutPersonalInfo(
        access_token,
        sex,
        address,
        job,
        company
      );
      onUpdate(res.data.user);
      openDialog("성공적으로 변경되었습니다.");
    } catch (e) {
      openDialog(e?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (user) {
      dispatchUser({
        type: SET_INIT_USER,
        payload: {
          sex: user.sex,
          address: user.address ? user.address : "",
          job: user.job ? user.job : "",
          company: user.company ? user.company : "",
        },
      });
    }
  }, [user]);

  return (
    <div className={styles["layout"]}>
      <TitleHelmet title="개인정보 변경" />
      <FindTextInput
        label="전화번호"
        type="text"
        defaultValue={user?.phone_number}
        disabled
      />
      <div className={styles["flex-box"]}>
        <div className={styles["flex-item"]}>
          <FindSelect
            label="성별"
            name="sex"
            value={sex}
            options={[
              { name: "남자", value: "male" },
              { name: "여자", value: "female" },
            ]}
            onChange={onChangeInput}
          />
        </div>
        <div className={styles["flex-item"]}>
          <FindTextInput
            label="위치"
            type="text"
            name="address"
            value={address}
            onChange={onChangeInput}
          />
        </div>
      </div>
      <div className={styles["flex-box"]}>
        <div className={styles["flex-item"]}>
          <FindTextInput
            label="직업"
            type="text"
            name="job"
            value={job}
            onChange={onChangeInput}
          />
        </div>
        <div className={styles["flex-item"]}>
          <FindTextInput
            label="회사"
            type="text"
            name="company"
            value={company}
            onChange={onChangeInput}
          />
        </div>
      </div>
      <SignButton name="저장하기" onClick={onClickPutPersonalInfo} />
    </div>
  );
};

export default ChangeInfoContainer;
