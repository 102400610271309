import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestGetKeywordList = keyword => {
  const url = resolveAPIURL("search");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      keyword: keyword,
    },
  };

  return axios.get(url, config);
};

export const requestPutEnterKeyword = keyword => {
  const url = resolveAPIURL("search");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      keyword: keyword,
    },
  };

  return axios.put(url, {}, config);
};
