import React from "react";
import { matchPath, useHistory } from "react-router-dom";

import CommentModal from "../../components/modal/CommentModal";
import PolicyModal from "../../components/modal/PolicyModal";
import ProfileModal from "../../components/modal/ProfileModal";

import { Paths } from "../../paths";

const UrlModalContainer = ({ location }) => {
  const history = useHistory();
  const background = location.state?.background;

  const onClose = () =>
    background ? history.goBack() : history.push(Paths.index);

  const { pathname } = location;

  const commentMatch = matchPath(pathname, {
    path: `${Paths.comment}/:id`,
  });
  const policyMatch = matchPath(pathname, {
    path: `${Paths.policy.modal}/:type`,
  });
  const profileImageMatch = matchPath(pathname, {
    path: `${Paths.profileimage}/:id`,
  });

  return (
    <>
      <CommentModal
        id={commentMatch?.params?.id}
        open={!!commentMatch}
        onClose={onClose}
      />
      <PolicyModal
        type={policyMatch?.params?.type}
        open={!!policyMatch}
        onClose={onClose}
      />
      <ProfileModal
        id={profileImageMatch?.params?.id}
        open={!!profileImageMatch}
        onClose={onClose}
      />
    </>
  );
};

export default UrlModalContainer;
