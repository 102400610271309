import React from "react";
import { Switch, Route } from "react-router-dom";

import FindSelectPage from "./FindSelectPage";
import FindIDPage from "./id/FindIDPage";
import ViewIDPage from "./id/ViewIDPage";
import FindPWPage from "./password/FindPWPage";
import ResetPWPage from "./password/ResetPWPage";

import SignTitle from "../../../components/title/SignTitle";

import { MobileTitleConsumer } from "../../../contexts/MobileTitleContext";

import { Paths } from "../../../paths";

import styles from "./FindPage.module.scss";

const FindPage = () => {
  return (
    <div className={styles["container"]}>
      <div className={styles["wrapper"]}>
        <div className={styles["note"]}>
          <MobileTitleConsumer>{SignTitle}</MobileTitleConsumer>
          <Switch>
            <Route path={Paths.find.index} component={FindSelectPage} exact />
            <Route path={Paths.find.id.index} component={FindIDPage} exact />
            <Route path={Paths.find.id.view} component={ViewIDPage} exact />
            <Route
              path={Paths.find.password.index}
              component={FindPWPage}
              exact
            />
            <Route
              path={Paths.find.password.reset}
              component={ResetPWPage}
              exact
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};
export default FindPage;
