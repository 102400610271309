import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

//api
import { Paths } from "../../paths";
import { requestPOSTSignUp } from "../../api/auth";

//hooks
import useDialog from "../../hooks/useDialog";

import SignTitle from "../../components/title/SignTitle";
import SignDescription from "../../components/title/SignDescription";
import SignTextInput from "../../components/input/SignTextInput";
import SignSelect from "../../components/input/SignSelect";
import { AgreeList } from "../../components/input/Agree";
import PhoneAuth from "../../components/input/PhoneAuth";
import SignButton from "../../components/button/SignButton";
import TitleHelmet from "../../components/title/TitleHelmet";

import useInputs from "../../hooks/useInputs";
import usePassword from "../../hooks/usePassword";

import styles from "./SignUpContainer.module.scss";

const initialState = {
  email: "",
  password: "",
  passwordCheck: "",
  name: "",
  nickname: "",
  birth: "",
  sex: "male",
  phoneNumber: "",
  verifyToken: null,
};

const SignUpContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const openDialog = useDialog();

  const [signInfo, onChangeInput, signDispatch] = useInputs(initialState);
  const [errorText, setErrorText] = useState("");
  const [agreeList, setAgreeList] = useState([
    {
      id: 1,
      description:
        "서비스 이용약관, 개인정보 처리방침을 확인하였고, 이에 동의합니다.",
      required: true,
      checked: false,
    },
    {
      id: 2,
      description: "이메일 수신에 동의합니다.",
      required: false,
      checked: false,
    },
  ]);

  const {
    email,
    password,
    passwordCheck,
    name,
    nickname,
    birth,
    sex,
    phoneNumber,
    verifyToken,
  } = signInfo;
  // Reducer 데이터 갖고 옴

  const [ruleCheck, sameCheck] = usePassword(password, passwordCheck);

  const canSignUp =
    email !== "" &&
    password !== "" &&
    passwordCheck !== "" &&
    name !== "" &&
    nickname !== "" &&
    birth !== "" &&
    sex !== "" &&
    ruleCheck.value &&
    sameCheck.value &&
    agreeList[0].checked &&
    verifyToken;
  // 회원가입 가능 여부 판단(모든 요소 작성했는지.)

  const onAuthComplete = verifyToken => {
    // 휴대폰 인증 후 콜백 함수
    signDispatch({ name: "verifyToken", value: verifyToken });
  };

  const onClickSignup = async e => {
    e.preventDefault();
    try {
      const response = await requestPOSTSignUp(
        email,
        name,
        nickname,
        password,
        birth,
        sex,
        phoneNumber,
        agreeList[1].checked,
        verifyToken
      );
      openDialog("회원가입이 완료되었습니다!", {
        text: "로그인하여 프리셋을 저장할 수 있습니다.",
        onClick: () => history.push(Paths.signin),
      });
    } catch (e) {
      let message = e?.response?.data?.message;
      if (e?.response?.data?.message === "Validation error") {
        const { message: error_message } = e.response.data.errors[0];
        if (error_message.indexOf("nickname") !== -1) {
          message = "중복된 닉네임입니다.";
        } else if (error_message.indexOf("phone_number") !== -1) {
          message = "이미 가입한 휴대폰 번호입니다.";
          signDispatch({ name: "verifyToken", value: null });
          // 휴대폰 번호가 중복이면 초기화.
        }
      }
      openDialog(message);
    }
  };

  return (
    <div className={styles["container"]}>
      <TitleHelmet title="회원가입" />
      <div className={styles["wrapper"]}>
        <div className={styles["note"]}>
          <SignTitle>회원가입</SignTitle>
          <SignDescription to={Paths.signin} linkname="로그인" linkbold>
            이미 계정이 있으십니까?
          </SignDescription>
          <form className={styles["form-box"]} onSubmit={onClickSignup}>
            <SignTextInput
              label="이메일 주소"
              type="email"
              name="email"
              onChange={onChangeInput}
              required
              error={errorText && "이미 가입한 이메일입니다."}
            />
            <SignTextInput
              label="비밀번호"
              type="password"
              name="password"
              onChange={onChangeInput}
              onBlur={ruleCheck.onEvent}
              required
              error={ruleCheck.error}
            />
            <SignTextInput
              label="비밀번호 확인"
              type="password"
              name="passwordCheck"
              onChange={onChangeInput}
              onBlur={sameCheck.onEvent}
              required
              error={sameCheck.error}
            />
            <SignTextInput
              label="이름"
              type="text"
              name="name"
              onChange={onChangeInput}
              required
            />
            <SignTextInput
              label="닉네임"
              type="text"
              name="nickname"
              onChange={onChangeInput}
              required
            />
            <SignTextInput
              label="출생년도"
              type="date"
              name="birth"
              max="2010-12-31"
              onChange={onChangeInput}
              required
            />
            <SignSelect
              label="성별"
              name="sex"
              onChange={onChangeInput}
              value={signInfo.sex}
              options={[
                { value: "male", name: "남자" },
                { value: "female", name: "여자" },
              ]}
              required
            />
            <div className={styles["auth"]}>
              <PhoneAuth
                phoneNumber={phoneNumber}
                onChange={onChangeInput}
                complete={verifyToken}
                onComplete={onAuthComplete}
              />
            </div>
            <p className={styles["signup-alert"]}>
              INVAIZ 및 INVAIZ Community 서비스 내&nbsp;
              <Link
                className={styles["link"]}
                to={{
                  pathname: `${Paths.policy.modal}/service`,
                  state: { background: location },
                }}
              >
                서비스 이용약관
              </Link>
              ,&nbsp;
              <Link
                className={styles["link"]}
                to={{
                  pathname: `${Paths.policy.modal}/privacy`,
                  state: { background: location },
                }}
              >
                개인정보 처리방침
              </Link>
              을 확인하십시오. INVAIZ는 귀하에게 이메일을 전송하여 제품과
              서비스에 대해 알리고자 합니다. 정보가 필요하거나 수신을 거부하려면
              개인정보취급방침을 참조하십시오.
            </p>
            <AgreeList agreeList={agreeList} setAgreeList={setAgreeList} />
            <SignButton disabled={!canSignUp} name="계정 만들기" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpContainer;
