import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Banner from "../../components/title/Banner";
import TitleLabel from "../../components/title/TitleLabel";
import BannerButton from "../../components/button/BannerButton";
import { Faqs } from "../../components/faq/Faq";
import { ReleaseNotes } from "../../components/releasenote/ReleaseNote";
import TitleHelmet from "../../components/title/TitleHelmet";

import _Banner from "../../images/main/shortcuts-banner.jpeg";
import _QnaBanner from "../../images/main/qna-banner.png";

import { useDispatch, useSelector } from "react-redux";
import { get_view } from "../../store/view/contact";

import useRefresh from "../../hooks/redux/useRefresh";

import { Paths } from "../../paths";

import styles from "./ContactContainer.module.scss";

/**
 * Contact 페이지 컨테이너.
 *
 * 문의 페이지 이동과 릴리즈 노트, 자주 묻는 질문 리스트 렌더.
 */
const ContactContainer = () => {
  const dispatch = useDispatch();

  const view_contact = useSelector(state => state.view_contact);

  const { faqs, notes, success, loading } = view_contact; // 통합 데이터.

  useEffect(() => {
    // mount시 Contact 페이지 데이터 가져옴.
    if (!success) {
      dispatch(get_view());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useRefresh(() => dispatch(get_view()), loading);

  return (
    <article className={styles["container"]}>
      <TitleHelmet
        title="고객센터"
        subject="Community, Contact, 커뮤니티, 고객센터"
      />
      <Banner image={_Banner}>
        각 프로그램의 <b>공식 단축키</b>가 궁금하시다면?
        <br />
        <b>
          <a href={Paths.contents} target="_blank" rel="noopener noreferrer">
            『여기』
          </a>
        </b>
        에서 확인하세요.
      </Banner>
      <section className={styles["wrapper"]}>
        <TitleLabel title="문의하기" />
        <BannerButton
          image={_QnaBanner}
          component={Link}
          to={Paths.contact.qna.index}
        >
          커뮤니티 이용문의
        </BannerButton>
      </section>

      <section className={styles["wrapper"]}>
        <TitleLabel title="업데이트 소식" />
        <ReleaseNotes loading={loading} list={notes} />
      </section>

      <section className={styles["wrapper"]}>
        <TitleLabel title="자주 묻는 질문" />
        <Faqs loading={loading} list={faqs} />
      </section>
    </article>
  );
};

export default ContactContainer;
