import React from "react";

import ChangeInfoContainer from "../../../containers/mypage/update/ChangeInfoContainer";

import { useSetTitle } from "../../../contexts/MobileTitleContext";

const ChangeInfoPage = () => {
  useSetTitle("개인정보 변경");
  return <ChangeInfoContainer />;
};

export default ChangeInfoPage;
