import React from "react";

import styled from "styled-components";

const Input = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
`;

const CopyInput = React.forwardRef(({ copyPath }, ref) => {
  return <Input ref={ref} defaultValue={copyPath} inputMode="none" />;
});

export default CopyInput;
