import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames/bind";
import { CircularProgress, IconButton } from "@material-ui/core";

import _Cancel from "../../images/svg/cancel.svg";
import _Search from "../../images/svg/search.svg";

import Keyword from "./Keyword";

import { useDispatch, useSelector } from "react-redux";
import { put_keywords } from "../../store/search/keyword";

import styles from "./Search.module.scss";
import useOutsideClick from "../../hooks/useOutsideClick";

const cn = classNames.bind(styles);

/**
 * 검색 input 컴포넌트
 *
 * Enter 입력 시 검색, Escape 입력 시 초기화 시킴
 */
const Search = ({
  value,
  onChange = value => {},
  onEnter = () => {},
  onInit = () => {},
  onClick = keyword => {},
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.keyword);

  const ref = useRef(null);
  const [keywordView, setKeywordView] = useState(false);

  const search = useMemo(() => (value ? value : ""), [value]);

  const onOpenKeywordView = () => setKeywordView(true);
  const onCloseKeywordView = () => setKeywordView(false);
  const onKeyDown = e => {
    switch (e.key) {
      case "Escape":
        onInit();
        break;
      case "Enter":
        dispatch(put_keywords(search)); // 엔터 수 업데이트
        onCloseKeywordView();
        onEnter();
        break;
      default:
        onOpenKeywordView();
        break;
    }
  };
  // Search input 태그에서 keyDown 리스너.

  useEffect(() => {
    if (!value) {
      onCloseKeywordView();
    }
  }, [value]);

  useOutsideClick(ref, onCloseKeywordView);

  return (
    <div className={styles["search"]} ref={ref}>
      <div className={cn("wrapper", { view: keywordView })}>
        <IconButton className={cn("button", "search")} onClick={onEnter}>
          <img className={styles["image"]} src={_Search} alt="search" />
        </IconButton>
        {!loading && search && (
          <IconButton className={cn("button", "clear")} onClick={onInit}>
            <img className={styles["image"]} src={_Cancel} alt="cancel" />
          </IconButton>
        )}
        {loading && (
          <div className={cn("button", "loading")}>
            <CircularProgress size="100%" />
          </div>
        )}
        <input
          type="search"
          inputMode="search"
          className={styles["search-input"]}
          value={search}
          onChange={onChange}
          onFocus={onOpenKeywordView}
          onKeyDown={onKeyDown}
        />
        {keywordView && <Keyword search={search} onClick={onClick} />}
      </div>
    </div>
  );
};

export default Search;
