import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { end_refresh, start_refresh } from "../../store/refresh";

export const useRefreshCallback = () => {
  const distpach = useDispatch();
  const onStartRefresh = useCallback(() => {
    distpach(start_refresh());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEndRefresh = useCallback(() => {
    distpach(end_refresh());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [onStartRefresh, onEndRefresh];
};

const useRefresh = (callback = () => {}, loading) => {
  const { pathname } = useLocation();
  const refresh = useSelector(state => state.refresh);
  const { action } = refresh;
  const [onStartRefresh, onEndRefresh] = useRefreshCallback();

  useEffect(() => {
    if (action && callback) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (action && !loading) {
      onEndRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    onEndRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return [refresh, onStartRefresh, onEndRefresh];
};

export default useRefresh;
