import React from "react";
import { useHistory } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";

import _Pencil from "../../../images/svg/pencil.svg";

import { Paths } from "../../../paths";

import styles from "./FirstPostButton.module.scss";

const FirstPostButton = () => {
  const history = useHistory();
  return (
    <ButtonBase
      className={styles["post-button"]}
      onClick={() => history.push(Paths.preset.write)}
    >
      게시글 작성
      <img className={styles["pencil"]} src={_Pencil} alt="pencil" />
    </ButtonBase>
  );
};

export default FirstPostButton;
