import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestGetQnaList = token => {
  const url = resolveAPIURL("qna");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.get(url, config);
};

export const requestGetQnaItem = (token, id) => {
  const url = `${resolveAPIURL("qna")}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.get(url, config);
};

export const requestDeleteQnaItem = (token, id) => {
  const url = `${resolveAPIURL("qna")}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.delete(url, config);
};

export const requestPostQna = (token, title, question, type = 1) => {
  const url = resolveAPIURL("qna");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    title,
    question,
    type,
  };

  return axios.post(url, formData, config);
};

export const requestPutQnaUpdate = (token, id, title, question, type = 1) => {
  const url = `${resolveAPIURL("qna")}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    title,
    question,
    type,
  };

  return axios.put(url, formData, config);
};
