import React from "react";
import HighlightedText from "../text/HighLightedText";
import { Link } from "react-router-dom";
import { Paths } from "../../paths";
import styles from "./Hashtag.module.scss";

const Hashtag = ({ name, query }) => {
  return (
    <Link
      to={`${Paths.preset.index}?keyword=${name}`}
      className={styles["hashtag"]}
    >
      #<HighlightedText query={query}>{name}</HighlightedText>
    </Link>
  );
};

const HashtagList = ({ list, query }) => {
  return (
    <>
      {list &&
        list.map(item => (
          <Hashtag {...item} query={query} key={item.hashtag_id} />
        ))}
    </>
  );
};

export const Hashtags = React.memo(HashtagList);

export default Hashtag;
