import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const isCurrentUrl = (pathname, current, force) => {
  if (force) {
    return pathname === current;
  }
  return current.indexOf(pathname) !== -1;
};

const useCurrentUrl = (checkPath, force) => {
  const location = useLocation();
  const background = location.state?.background;
  const { pathname } = background || location;
  return useMemo(() => {
    return isCurrentUrl(checkPath, pathname, force);
  }, [checkPath, force, pathname]);
};

export default useCurrentUrl;
