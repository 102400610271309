import React, { useEffect, useState } from "react";

import { Dialog, IconButton, Slide } from "@material-ui/core";
import CloseIcon from "../../icons/CloseIcon";

//hooks
import { useSelector } from "react-redux";
import useTouchMouse from "../../hooks/useTouchMouse";

import styles from "./PolicyModal.module.scss";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PolicyModal = ({ type, open, onClose }) => {
  const { company } = useSelector(state => state.info); // 회사 데이터.

  const [touchState, isPress, events] = useTouchMouse({
    use_touch: true,
  });
  const { diff } = touchState;

  const isService = type === "service"; // 어떤 모달인지 확인.

  const [data, setData] = useState({
    title: "",
    content: "",
  }); // 렌더할 데이터.
  const { title, content } = data;

  useEffect(() => {
    if (open) {
      // 오픈 시 어떠한 데이터를 렌더할지 결정.
      const use_terms = {
        title: "서비스 이용약관",
        content: company?.use_terms,
      };
      const private_policy = {
        title: "개인정보 처리방침",
        content: company?.private,
      };
      setData(isService ? use_terms : private_policy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, company]);

  useEffect(() => {
    if (!isPress && diff.y < -100) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPress]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className={styles["dialog"]}
    >
      <header className={styles["app-bar"]} {...events}>
        <div className={styles["header"]}>
          <IconButton onClick={onClose} className={styles["close"]}>
            <CloseIcon black />
          </IconButton>
          <h1 className={styles["title"]}>{title}</h1>
        </div>
      </header>
      <div
        className={styles["wrapper"]}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Dialog>
  );
};

export default PolicyModal;
