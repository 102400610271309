import React from "react";

import { ButtonBase } from "@material-ui/core";

import _Pencil from "../../../images/svg/pencil.svg";

import styles from "./PostButton.module.scss";

const PostButton = ({ text, onClick }) => {
  return (
    <ButtonBase className={styles["post-button"]} onClick={onClick}>
      <span className={styles["text"]}>{text}</span>
      <img className={styles["image"]} src={_Pencil} alt="pencil" />
    </ButtonBase>
  );
};

export default PostButton;
