import React, { useState, useEffect, useRef, useMemo } from "react";
import classNames from "classnames/bind";
import { useHistory, Link } from "react-router-dom";
import { Paths } from "../../paths";

import { Button, IconButton } from "@material-ui/core";
import MoreInfoIcon from "../../icons/MoreInfoIcon";

//components
import PresetViewer from "../../components/preset/PresetViewer";
import { Comments } from "../../components/comment/Comment";
import { Hashtags } from "../../components/hashtag/Hashtag";
import FeedCheckBox from "../../components/feed/FeedCheckBox";
import TopInfoItem from "../../components/preset/TopInfoItem";
import CopyInput from "../../components/feed/CopyInput";
import CommentInput from "../../components/comment/CommentInput";
import ViewActionModal from "../../components/modal/ViewActionModal";
import TitleHelmet from "../../components/title/TitleHelmet";

//hooks
import { useSelector } from "react-redux";
import { useTokenCallback } from "../../hooks/storage/useToken";
import useDialog from "../../hooks/useDialog";
import useFeed from "../../hooks/redux/useFeed";
import useComment from "../../hooks/redux/useComment";

//api
import {
  requestGetPreset,
  requestDeletePreset,
  requestPutPresetHide,
  requestPutPresetView,
} from "../../api/preset";

//lib
import { dateToRelative } from "../../lib/formatter";

import styles from "./PresetViewContainer.module.scss";

const cn = classNames.bind(styles);

/**
 * 프리셋 게시글 상세 보기 타이틀 영역 스켈레톤 컴포넌트
 */
const SkeletonTitleBar = () => {
  return (
    <div className={styles["skeleton-title-bar"]}>
      <div className={styles["skeleton-program-image"]}></div>
      <p className={styles["skeleton-title"]}></p>
    </div>
  );
};

/**
 * 프리셋 게시글 상세 보기 게시글 내용 영역 스켈레톤 컴포넌트
 */
const SkeletonContent = () => {
  return <div className={styles["skeleton-content"]} />;
};

/**
 * Preset View 페이지 컨테이너.
 *
 * 프리셋 게시글 상세 정보 렌더와
 * 좋아요, 북마크, 댓글 삭제 및 관리 기능 수행.
 *
 * @param {number} id 상세 보기 할 board id.
 * @returns 프리셋 상세 보기 컨테이너
 */
const PresetViewContainer = ({ id }) => {
  const history = useHistory();
  const openDialog = useDialog();

  const commentInputRef = useRef(null);
  const copyUrlRef = useRef(null);

  const { user } = useSelector(state => state.info);
  const {
    feed = null,
    updateFeed,
    doApiLike,
    doApiBookmark,
    doDownload,
    doCopy,
  } = useFeed(id, copyUrlRef);
  const { postComment } = useComment(id);

  const [presetContent, setPresetContent] = useState(null);
  const [moreModal, setMoreModal] = useState(false);

  const callApiGetPreset = useTokenCallback(
    async access_token => {
      // 프리셋 게시글 상세 정보 요청 api 호출.
      try {
        const res = await requestGetPreset(access_token, id);
        const { post } = res.data;
        const { preset_content } = post;
        updateFeed(post);
        setPresetContent(preset_content);
      } catch (e) {
        openDialog("삭제되거나 비공개 게시글입니다.", {
          onClose: () => history.goBack(),
        });
      }
    },
    {
      isRequired: false,
    }
  );
  const callApiDeletePreset = useTokenCallback(
    async access_token => {
      // 프리셋 게시글 삭제 요청 api 호출.
      try {
        await requestDeletePreset(access_token, id);
        openDialog("성공적으로 처리되었습니다.", {
          onClose: () => history.goBack(),
        });
      } catch (e) {
        openDialog(e?.response?.message);
      }
    },
    {
      isRequired: true,
    }
  );
  const callApiPutViewAndHide = useTokenCallback(
    async access_token => {
      // 프리셋 게시글 숨김 / 보임 처리 요청 api 호출.
      try {
        const { status } = feed;
        const res =
          status === 1
            ? await requestPutPresetHide(access_token, id)
            : await requestPutPresetView(access_token, id);
        updateFeed(res.data.post);
        openDialog("성공적으로 처리되었습니다.");
      } catch (e) {
        openDialog(e?.response?.message);
      }
    },
    {
      isRequired: true,
    }
  );

  const onOpenMoreModal = () => setMoreModal(true);
  const onCloseMoreModal = () => setMoreModal(false);
  // More Actions Button Modal on / off 하는 Click Event Listener
  const onClickComment = () => {
    // 프리셋 댓글 작성 input focus 하는 Click Event Listener
    commentInputRef.current.focus();
  };

  const onClickUpdatePreset = () => history.push(`${Paths.preset.write}/${id}`);
  // 프리셋 게시글 수정 Click Event Listener
  const onClickDeletePreset = () => {
    // 프리셋 게시글 삭제 Click Event Listener
    openDialog("해당 게시글을 삭제하시겠습니까?", {
      text: "삭제를 원하시면 '예'를 누르세요.",
      onClick: callApiDeletePreset,
      isConfirm: true,
    });
    onCloseMoreModal();
  };
  const onClickHidePreset = async () => {
    // 프리셋 게시글 숨김 / 보임 Click Event Listener
    const { status } = feed;
    const typeName = status === 1 ? "숨김" : "보임";
    openDialog(`해당 게시글을 ${typeName}처리 하시겠습니까?`, {
      text: `${typeName}처리를 원하시면 '예'를 누르세요.`,
      onClick: callApiPutViewAndHide,
      isConfirm: true,
    });
    onCloseMoreModal();
  };

  useEffect(() => {
    // mount시 상세 페이지 데이터 가져옴.
    callApiGetPreset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moreActions = useMemo(() => {
    return [
      {
        name: "게시글 수정",
        onClick: onClickUpdatePreset,
      },
      {
        name: "게시글 삭제",
        onClick: onClickDeletePreset,
      },
      {
        name: `게시글 ${feed?.status === 1 ? "숨김" : "숨김해제"}`,
        onClick: onClickHidePreset,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed]);

  return (
    <article className={styles["container"]}>
      {feed && (
        <TitleHelmet
          title={feed.title}
          description={feed.content}
          author={feed.user?.nickname}
          subject={feed.title}
        />
      )}
      <section className={styles["wrapper"]}>
        <div className={cn("contents", feed?.support_program?.name)}>
          <div className={styles["top-area"]}>
            {feed ? (
              <div className={styles["title-box"]}>
                <Link
                  className={cn("link", "program-image-area")}
                  to={`${Paths.preset.index}?p_name=${feed?.support_program?.name}`}
                >
                  <img
                    className={cn("support-program")}
                    src={feed?.support_program?.image}
                    alt="support_program"
                  />
                </Link>
                <h2 className={styles["title"]}>{feed?.title}</h2>
              </div>
            ) : (
              <SkeletonTitleBar />
            )}
            <div className={styles["top-info"]}>
              <TopInfoItem
                name="Model"
                value={feed?.model?.name}
                to={`${Paths.preset.index}?m_name=${feed?.model?.name}`}
              />
              <TopInfoItem
                name="OS"
                value={feed?.support_os?.name}
                to={`${Paths.preset.index}?o_name=${feed?.support_os?.name}`}
              />
              <TopInfoItem
                name="Date"
                value={feed && dateToRelative(new Date(feed?.created_at), ".")}
              />
              <TopInfoItem
                name="Writer"
                value={feed?.user?.nickname}
                to={`${Paths.user.index}/${feed?.user.user_id}`}
              />
            </div>
          </div>
          <div className={styles["feed-content-box"]}>
            {feed ? (
              <div
                className={styles["text-contents"]}
                dangerouslySetInnerHTML={{
                  __html: feed?.content,
                }}
              />
            ) : (
              <SkeletonContent />
            )}
            <div className={styles["preset-view-box"]}>
              <PresetViewer preset={presetContent} />
            </div>
          </div>

          <div className={styles["hashtag-box"]}>
            <Hashtags list={feed?.hashtags} />
          </div>
          <div className={styles["contents-data-box"]}>
            <p className={styles["contents-info"]}>
              좋아요 {feed ? feed?.like_users?.length + feed?.operLike : 0}개 ·
              댓글 {feed ? feed?.replies?.length : 0}개 · 저장{" "}
              {feed ? feed?.bookmark_users?.length + feed?.operBookmark : 0}회
            </p>
          </div>
          <div className={cn("feed-interaction", feed?.support_program?.name)}>
            <div className={styles["feed-check"]}>
              <FeedCheckBox
                name="좋아요"
                checked={user && feed?.doLike}
                onClick={doApiLike}
              />
              <FeedCheckBox
                name="북마크"
                checked={user && feed?.doBookmark}
                onClick={doApiBookmark}
              />
            </div>
            <div className={styles["feed-button-area"]}>
              <Button
                className={styles["feed-button"]}
                onClick={onClickComment}
              >
                댓글
              </Button>
              <Button className={styles["feed-button"]} onClick={doCopy}>
                공유
              </Button>
              <Button
                className={cn("feed-button", "download")}
                onClick={doDownload}
              >
                다운로드
              </Button>
              {feed && user?.user_id === feed?.user.user_id && (
                <IconButton
                  className={styles["more-info"]}
                  onClick={onOpenMoreModal}
                >
                  <MoreInfoIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div className={styles["comment-area"]}>
            <CommentInput
              ref={commentInputRef}
              user={user}
              onPostComment={postComment}
            />
            <Comments list={feed?.replies} board_id={id} />
            {user && (
              <p className={styles["comment-focus"]}>
                <span
                  className={styles["comment-positioner"]}
                  onClick={onClickComment}
                >
                  댓글을 입력하세요...
                </span>
              </p>
            )}
          </div>
        </div>
      </section>
      <ViewActionModal
        open={moreModal}
        onClose={onCloseMoreModal}
        actions={moreActions}
      />
      <CopyInput ref={copyUrlRef} copyPath={window.location.href} />
    </article>
  );
};

export default PresetViewContainer;
