import React from "react";
import ProfileButton from "./cover/ProfileButton";

import styles from "./SkeletonProfile.module.scss";

const SkeletonProfile = () => {
  return (
    <div className={styles["profile"]}>
      <div className={styles["image-view"]}>
        <div className={styles["profile-image"]}>
          <ProfileButton borderwidth={5} />
        </div>
      </div>
      <div className={styles["info"]}>
        <p className={styles["nickname"]} />
        <p className={styles["name"]} />
        <p className={styles["email"]} />
        <p className={styles["introduce"]} />
      </div>
    </div>
  );
};

export const SkeletonProfiles = () => {
  return (
    <>
      <SkeletonProfile />
      <SkeletonProfile />
      <SkeletonProfile />
      <SkeletonProfile />
    </>
  );
};

export default SkeletonProfile;
