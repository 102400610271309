import React, { useEffect } from "react";
import qs from "qs";

import SignInContainer from "../../containers/main/SignInContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";
import { setStudioLogin } from "../../hooks/storage/useStudioLogin";

const SignInPage = ({ location }) => {
  const { studio_login } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (studio_login) {
      setStudioLogin();
    }
  }, [studio_login]);

  useSetTitle("로그인");
  return <SignInContainer />;
};

export default SignInPage;
