import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//components
import BannerButton from "../../components/button/BannerButton";
import Banner from "../../components/title/Banner";
import Search from "../../components/search/Search";
import TitleLabel from "../../components/title/TitleLabel";
import { SearchHashtags } from "../../components/hashtag/SearchHashtag";
import { Feeds } from "../../components/feed/Feed";
import { ReleaseNotes } from "../../components/releasenote/ReleaseNote";

import _Banner from "../../images/main/community-banner.jpeg";
import _ShortcutsBanner from "../../images/main/shortcuts-banner.jpeg";

import { useDispatch, useSelector } from "react-redux";
import { get_view } from "../../store/view/main";

//hooks
import useToken from "../../hooks/storage/useToken";
import useRefresh from "../../hooks/redux/useRefresh";

import { Paths } from "../../paths";

//styles
import styles from "./MainContainer.module.scss";

/**
 * Main 페이지 컨테이너.
 *
 * 통합 검색 및, 자주 사용하는 해시태그, 이번 주 최신 프리셋, 릴리즈 노트 리스트 렌더.
 */
const MainContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const access_token = useToken();

  const view_main = useSelector(state => state.view_main);
  const [search, setSearch] = useState(""); // 통합 검색 상태.

  const { hashtags, recently_posts, notes, success, loading } = view_main;

  const onInitSearch = () => setSearch("");
  // 검색창 초기화
  const onChangeSearch = e => setSearch(e.target.value);
  // 검색창 내용 변경
  const onClickSearch = keyword =>
    history.push(`${Paths.search.index}?q=${keyword}`);
  // 추천 검색어 클릭
  const onEnterSearch = e => history.push(`${Paths.search.index}?q=${search}`);
  // 검색

  useEffect(() => {
    // mount시 Main 페이지 데이터 가져 옴
    if (!success) {
      dispatch(get_view(access_token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRefresh(() => dispatch(get_view(access_token)), loading);

  return (
    <article className={styles["container"]}>
      <Banner image={_Banner}>
        효과적인 작업 환경 공유는 <br />
        <b>인바이즈 커뮤니티</b>에서 확인하세요.
      </Banner>
      <section className={styles["wrapper"]}>
        <TitleLabel title="무엇을 찾고 있나요?" />
        <Search
          value={search}
          onChange={onChangeSearch}
          onEnter={onEnterSearch}
          onInit={onInitSearch}
          onClick={onClickSearch}
        />
        <SearchHashtags list={hashtags} loading={loading} />
      </section>
      <section className={styles["wrapper"]}>
        <BannerButton
          image={_ShortcutsBanner}
          component="a"
          href={Paths.contents}
          target="_blank"
        >
          공식 단축키 목록 보러가기
        </BannerButton>
      </section>
      <section className={styles["wrapper"]}>
        <TitleLabel
          title="이번 주 최신 프리셋"
          more={{ text: "프리셋", link: Paths.preset.index }}
        />
        <Feeds list={recently_posts} loading={loading} />
      </section>
      <section className={styles["wrapper"]}>
        <TitleLabel
          title="업데이트 소식"
          more={{
            text: "업데이트 소식",
            link: Paths.contact.index,
          }}
        />
        <ReleaseNotes list={notes} loading={loading} />
      </section>
    </article>
  );
};
export default MainContainer;
