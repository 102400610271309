import React from "react";
import classNames from "classnames/bind";

import { ButtonBase } from "@material-ui/core";

import styles from "./WriteButton.module.scss";

const cn = classNames.bind(styles);

const WriteButton = ({ text, onClick, disabled, children }) => {
  return (
    <div className={styles["write"]}>
      {children}
      <ButtonBase
        className={cn("button", {
          disabled,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </ButtonBase>
    </div>
  );
};

export default WriteButton;
