import { useEffect } from "react";

const KEY = "FINDED_EMAIL";

export const setFindedEmail = email => {
  sessionStorage.setItem(KEY, email);
};

const getFindedEmail = () => {
  const finded_email = sessionStorage.getItem(KEY);
  return finded_email;
};
const removeFindedEmail = () => {
  sessionStorage.removeItem(KEY);
};

const useFindedEmail = (onEmailListener, remove) => {
  useEffect(() => {
    const finded_email = getFindedEmail();
    if (finded_email) {
      onEmailListener(finded_email);
    }
    if (remove) {
      return removeFindedEmail;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFindedEmail;
