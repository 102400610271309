import React from "react";

const MypageIcon = ({ on }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-6953.183 3638.721)">
        <path
          d="M9.213,18.315A11.034,11.034,0,0,1,6.39,17.95a10.866,10.866,0,0,1-2.543-1.032A10.7,10.7,0,0,1,0,13.243L.154,13.1a14.564,14.564,0,0,1,4.295-2.233l.931-.345.518.424A5.858,5.858,0,0,0,7.783,12.04a3.46,3.46,0,0,0,1.464.187,3.46,3.46,0,0,0,1.464-.187A5.853,5.853,0,0,0,12.6,10.948l.518-.424.931.345A14.568,14.568,0,0,1,18.331,13.1l.12.109a10.691,10.691,0,0,1-3.851,3.7,10.865,10.865,0,0,1-2.552,1.04A11.032,11.032,0,0,1,9.213,18.315Zm.061-7.156a3.917,3.917,0,0,1-2.31-.814A6.41,6.41,0,0,1,5.1,8.055a6.444,6.444,0,0,1-.728-3.738A4.982,4.982,0,0,1,6.016,1.242,5.084,5.084,0,0,1,8.212.107,4.992,4.992,0,0,1,9.237,0a4.814,4.814,0,0,1,2.985,1.03,5.156,5.156,0,0,1,1.8,2.739,6.277,6.277,0,0,1-.03,2.692A7.484,7.484,0,0,1,12.7,9.16a5.441,5.441,0,0,1-2.073,1.709A3.321,3.321,0,0,1,9.274,11.159Z"
          transform="translate(6955.958 -3634.374)"
          fill={on ? "#0066ff" : "#9e9e9e"}
        />
      </g>
    </svg>
  );
};

export default MypageIcon;
