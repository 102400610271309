import React, { useEffect, useState } from "react";
import qs from "qs";
import { Switch, Route, useHistory } from "react-router-dom";

import SearchTotalPage from "./SearchTotalPage";
import SearchProfilePage from "./SearchProfilePage";
import SearchReleaseNotePage from "./SearchReleaseNotePage";
import SearchFaqPage from "./SearchFaqPage";

import PageTitle from "../../components/title/PageTitle";
import Search from "../../components/search/Search";

import { MobileTitleConsumer } from "../../contexts/MobileTitleContext";

import { Paths } from "../../paths";

import styles from "./SearchRoutePage.module.scss";

const SearchRoutePage = ({ location }) => {
  const history = useHistory();

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { q } = query;

  const [search, setSearch] = useState(q); // 통합 검색 상태.

  const replaceChangeUrl = queryObject => {
    const newObject = {
      ...query,
      ...queryObject,
    };
    Object.keys(newObject).forEach(key => {
      if (!newObject[key]) {
        // 내용이 없으면 삭제
        delete newObject[key];
      }
    });
    const newQuery = qs.stringify(newObject);
    history.replace(`${location.pathname}?${newQuery}`);
  };
  // url query를 변경할 때 이전에 있던 query들은 유지하며 변경하는 함수

  const onInitSearch = () => {
    if (!query.keyword) {
      // 키워드가 없으면 직접 변경
      setSearch("");
    }
    replaceChangeUrl({ q: "" });
  };
  // 검색창 초기화
  const onChangeSearch = e => setSearch(e.target.value);
  // 검색창 내용 변경
  const onClickSearch = keyword => replaceChangeUrl({ q: keyword });
  // 추천 검색어 클릭
  const onEnterSearch = () => replaceChangeUrl({ q: search });
  // 검색

  useEffect(() => {
    setSearch(q);
  }, [q]);

  return (
    <article className={styles["container"]}>
      <MobileTitleConsumer>{PageTitle}</MobileTitleConsumer>
      <section className={styles["wrapper"]}>
        <Search
          value={search}
          onChange={onChangeSearch}
          onInit={onInitSearch}
          onEnter={onEnterSearch}
          onClick={onClickSearch}
        />
      </section>
      <section className={styles["wrapper"]}>
        <Switch>
          <Route path={Paths.search.index} component={SearchTotalPage} exact />
          <Route
            path={Paths.search.profile}
            component={SearchProfilePage}
            exact
          />
          <Route
            path={Paths.search.release}
            component={SearchReleaseNotePage}
            exact
          />
          <Route path={Paths.search.faq} component={SearchFaqPage} exact />
        </Switch>
      </section>
    </article>
  );
};

export default SearchRoutePage;
