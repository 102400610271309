import React from "react";
import { useSelector } from "react-redux";

import PageTitle from "../../components/title/PageTitle";
import TitleHelmet from "../../components/title/TitleHelmet";

import { MobileTitleConsumer } from "../../contexts/MobileTitleContext";

import styles from "./PolicyContainer.module.scss";

const PolicyContainer = ({ type }) => {
  const { company } = useSelector(state => state.info);
  return (
    <div className={styles["container"]}>
      <TitleHelmet
        title={`${type === "privacy" ? "개인정보 처리방침" : "이용약관"}`}
      />
      <MobileTitleConsumer>{PageTitle}</MobileTitleConsumer>
      <div
        className={styles["content"]}
        dangerouslySetInnerHTML={{
          __html: type === "privacy" ? company?.private : company?.use_terms,
        }}
      />
    </div>
  );
};

export default PolicyContainer;
