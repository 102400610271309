import React from "react";
import classNames from "classnames/bind";

import styles from "./Toggle.module.scss";

const cn = classNames.bind(styles);

const Switch = ({ checked }) => {
  return (
    <div className={cn("wrapper", { checked })}>
      <div className={styles["switch"]}>
        <div className={styles["button"]} />
      </div>
    </div>
  );
};

const Toggle = ({ id, label, checked, ...restProps }) => {
  return (
    <div className={styles["box"]}>
      {label && <span className={styles["label"]}>{label}</span>}
      <input
        id={id}
        type="checkbox"
        checked={checked}
        className={styles["input"]}
        {...restProps}
      />
      <label htmlFor={id}>
        <div className={styles["toggle"]}>
          <Switch checked={checked} />
        </div>
      </label>
    </div>
  );
};

export default Toggle;
