import React from "react";
import styles from "./Footer.module.scss";

import _Logo from "../../images/svg/logo/invaiz_black.svg";
import _Youtube from "../../images/svg/social/youtube.svg";
import _Instagram from "../../images/svg/social/instagram.svg";
import _Facebook from "../../images/svg/social/facebook.svg";
import { Paths } from "../../paths";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles["footer"]}>
      <div className={styles["wrapper"]}>
        <img src={_Logo} alt="logo" className={styles["logo"]} />
        <div className={styles["sns_box"]}>
          <a
            href="https://www.youtube.com/channel/UCQg8KqzFq0QzxdHhhZZRbCg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={_Youtube} alt="youtube" />
          </a>

          <a
            href="https://www.instagram.com/invaiz_official/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={_Instagram} alt="instagram" />
          </a>

          <a
            href="https://www.facebook.com/INVAIZ.Marketer/?view_public_for=111255330752865"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={_Facebook} alt="facebook" />
          </a>
        </div>
        <div className={styles["contact_box"]}>
          <p>
            고객센터 <span>051-207-1512</span>
          </p>
          <p>
            메일문의 <span>support@invaiz.com</span>
          </p>
        </div>
        <div className={styles["policy"]}>
          <Link className={styles["link"]} to={`${Paths.policy.index}/privacy`}>
            개인정보 처리방침
          </Link>
          <Link className={styles["link"]} to={`${Paths.policy.index}/service`}>
            서비스 이용약관
          </Link>
        </div>
        <div className={styles["companyInfo"]}>
          부산광역시 사하구 하단동 낙동대로 550번길 37 S14-411 <br />
          사업자 등록 번호 : 695-86-02474 대표 : 이준석 <br /> Copyright 2020.
          INVAIZ All Right Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
