import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  action: false,
  pulldown: false,
  delta: 0,
};

const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    start_refresh: (state, action) => {
      state.action = true;
    },
    end_refresh: (state, action) => {
      state.action = false;
    },
    start_pulldown: (state, action) => {
      state.pulldown = true;
      state.delta = 0;
    },
    move_pulldown: (state, action) => {
      state.delta = action.payload;
    },
    end_pulldown: (state, action) => {
      state.pulldown = false;
      state.delta = 0;
    },
  },
});

export const {
  start_refresh,
  end_refresh,
  start_pulldown,
  move_pulldown,
  end_pulldown,
} = refreshSlice.actions;

export default refreshSlice.reducer;
