import React from "react";

import QnaWriteContainer from "../../containers/contact/QnaWriteContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";
import useBlockRefresh from "../../hooks/useBlockRefresh";

const QnaWritePage = ({ match }) => {
  const { id } = match.params;
  const isUpdate = !!id;

  useSetTitle(`1:1 문의 ${isUpdate ? "수정" : "작성"}`);
  useBlockRefresh();
  return <QnaWriteContainer id={id} />;
};

export default QnaWritePage;
