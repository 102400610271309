import React from "react";

const SearchIcon = ({ on }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M21.385,23.551l-5.107-5.107-.008-.008a10.21,10.21,0,1,1,2.166-2.166l.008.008,5.107,5.107a1.532,1.532,0,0,1-2.166,2.166ZM2.553,10.213a7.659,7.659,0,1,0,7.659-7.659A7.668,7.668,0,0,0,2.553,10.213Z"
        fill={on ? "#0066ff" : "#9e9e9e"}
      />
    </svg>
  );
};

export const SearchIconTest = ({ on }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M21.385,23.551l-5.107-5.107-.008-.008a10.21,10.21,0,1,1,2.166-2.166l.008.008,5.107,5.107a1.532,1.532,0,0,1-2.166,2.166ZM2.042,10.213a8.17,8.17,0,1,0,8.17-8.17A8.18,8.18,0,0,0,2.042,10.213Z"
        fill={on ? "#0066ff" : "#9e9e9e"}
      />
    </svg>
  );
};

export default SearchIcon;
