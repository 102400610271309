import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { ButtonBase } from "@material-ui/core";

import {
  requestPostVerifyConfirm,
  requestPostVerifyPhone,
} from "../../api/auth";
import { useSnackbar } from "../../hooks/useSnackbar";

import styles from "./Auth.module.scss";

const cn = classNames.bind(styles);

const ONE_MINUTE = 60;
const ONE_SECOND = 1000;
const TIME_LIMIT = ONE_MINUTE * 3; // 3분 제한 시간.

let timer = null;

const PhoneAuth = ({ phoneNumber, onChange, complete, onComplete }) => {
  const openSnackbar = useSnackbar();

  const phoneRef = useRef(null);
  const authRef = useRef(null);

  const [isSend, setIsSend] = useState(false);
  const [authNumber, setAuthNumber] = useState("");
  const [time, setTime] = useState(-1);

  const minute = parseInt(time / ONE_MINUTE);
  const second = time % ONE_MINUTE;
  const useTimer = isSend && !complete;

  const onKeyDown =
    (onEnter = () => {}) =>
    e => {
      switch (e.key) {
        case "Enter":
          onEnter();
          break;
        default:
          break;
      }
    };

  const onChangeAuth = e => setAuthNumber(e.target.value);

  const alreadyComplete = () => {
    openSnackbar({
      message: "이미 인증 완료되었습니다.",
      variant: "default",
      up: true,
    });
  };

  const onClickSend = async () => {
    if (complete) return alreadyComplete();
    try {
      const res = await requestPostVerifyPhone(phoneNumber);

      if (res.status === 200) {
        setIsSend(true);
        setTime(TIME_LIMIT); // TIME_LIMIT 만큼 시간 설정.
        if (res.data.auth_number) {
          // alert(`인증번호는 ${res.data.auth_number}입니다.`);
          setAuthNumber(res.data.auth_number);
        }

        openSnackbar({
          message: "인증번호를 발송했습니다.",
          variant: "default",
          up: true,
        });
        authRef.current.focus();
      }
    } catch (e) {
      let message = "다시 시도해 주세요.";
      if (e.response?.data?.name === "OmissionError") {
        message = "휴대폰 번호를 입력해 주세요.";
      } else if (e.response?.data?.name === "ParameterTypeError") {
        message = "휴대폰 형식을 맞춰 주세요.";
      }
      openSnackbar({
        message,
        variant: "error",
        up: true,
      });
      phoneRef.current.focus();
    }
  };

  const onClickConfirm = async () => {
    if (complete) return alreadyComplete();
    try {
      const res = await requestPostVerifyConfirm(phoneNumber, authNumber);
      if (res.status === 200) {
        openSnackbar({
          message: "인증이 완료되었습니다.",
          variant: "info",
          up: true,
        });
        setIsSend(false);
        onComplete(res.data.verify_token);
      }
    } catch (e) {
      authRef.current.focus();
      openSnackbar({
        message: "인증번호를 다시 한 번 확인해 주세요.",
        variant: "error",
        up: true,
      });
    }
  };

  useEffect(() => {
    // 타이머를 체크하는 상태 관리자.
    if (useTimer) {
      if (time > 0) {
        clearTimeout(timer);
        // 이 전에 기록된 타이머를 제거함.
        timer = setTimeout(() => {
          setTime(time - 1);
        }, ONE_SECOND);
      } else {
        setIsSend(false);
        openSnackbar({
          message: "인증 시간이 만료되었습니다.",
          variant: "error",
          up: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <div className={styles["box"]}>
      <p className={styles["label"]}>휴대폰 번호</p>
      <div className={cn("input-box")}>
        <input
          ref={phoneRef}
          className={styles["input"]}
          type="tel"
          name="phoneNumber"
          value={phoneNumber}
          onChange={onChange}
          onKeyDown={onKeyDown(onClickSend)}
          disabled={isSend || complete}
          required
        />
        <ButtonBase className={styles["send-button"]} onClick={onClickSend}>
          {isSend ? "인증번호 재발송" : "인증번호 발송"}
        </ButtonBase>
      </div>
      <div className={cn("input-box")}>
        <div className={styles["auth"]}>
          <input
            ref={authRef}
            className={styles["input"]}
            type="text"
            inputMode="numeric"
            name="authNumber"
            value={authNumber}
            onChange={onChangeAuth}
            onKeyDown={onKeyDown(onClickConfirm)}
            disabled={!useTimer}
            required
          />
          {useTimer && (
            <p className={styles["time"]}>
              {`${minute}:${
                second.toString().length === 1 ? "0" : ""
              }${second}`}
            </p>
          )}
        </div>
        <ButtonBase
          className={cn("send-button", "check", {
            disable: !useTimer,
          })}
          disabled={!useTimer}
          onClick={onClickConfirm}
        >
          인증번호 확인
        </ButtonBase>
      </div>
    </div>
  );
};

export default PhoneAuth;
