import React, { useState, useRef, useCallback, useMemo } from "react";
import classNames from "classnames/bind";
import { Link, useHistory } from "react-router-dom";
import { Paths } from "../../paths";

import { IconButton } from "@material-ui/core";
import MoreInfoIcon from "../../icons/MoreInfoIcon";
import ProfileButton from "../profile/cover/ProfileButton";
import { SkeletonComments } from "./SkeletonComment";
import InfinityScroll from "../search/InfinityScroll";
import CommentInput from "./CommentInput";

//hooks
import { useSelector } from "react-redux";
import useComment from "../../hooks/redux/useComment";
import useDialog from "../../hooks/useDialog";

//lib
import { dateToRelative } from "../../lib/formatter";

import styles from "./Comment.module.scss";
import ViewActionModal from "../modal/ViewActionModal";

const cn = classNames.bind(styles);

const CommentProfile = ({ user }) => {
  const history = useHistory();
  const onClickProfile = () =>
    history.push(`${Paths.user.index}/${user?.user_id}`);
  // 유저 상세 페이지로 이동.
  return (
    <div className={styles["profile"]}>
      <div className={styles["profile-image"]}>
        <ProfileButton
          src={user?.profile_image}
          borderwidth={1}
          onClick={onClickProfile}
        />
      </div>
    </div>
  );
};

const Comment = ({
  board_id,
  reply_id,
  content,
  child,
  user,
  created_at,
  new_created,
  isChild = false,
}) => {
  const openDialog = useDialog();

  const replyRef = useRef(null); //답글 ref
  const updateRef = useRef(null); //수정 ref

  const [update_mode, setUpdateMode] = useState(false);
  const [reply_mode, setReplyMode] = useState(false); //답글 on
  const [modal, setModal] = useState(false); //모달

  const { user: auth } = useSelector(state => state.info);

  const { postComment, updateComment, deleteComment } = useComment(
    board_id,
    reply_id
  );

  const onOpenActionModal = useCallback(() => setModal(true), []);
  const onCloseActionModal = useCallback(() => setModal(false), []);

  const onToggleReplyMode = useCallback(
    () => setReplyMode(!reply_mode),
    [reply_mode]
  );
  const onCloseReplyMode = useCallback(() => setReplyMode(false), []);

  const onOpenUpdateMode = useCallback(() => {
    setUpdateMode(true);
    onCloseActionModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onCloseUpdateMode = useCallback(() => setUpdateMode(false), []);

  const onClickDeleteComment = () => {
    // 프리셋 댓글 삭제 Click Event Listener
    openDialog("해당 댓글을 삭제하시겠습니까?", {
      text: "삭제를 원하시면 '예'를 누르세요.",
      onClick: async () => {
        await deleteComment();
        openDialog("댓글을 삭제하였습니다.");
      },
      isConfirm: true,
    });
  };

  const actions = useMemo(() => {
    return [
      {
        name: "댓글 수정",
        onClick: onOpenUpdateMode,
      },
      {
        name: "댓글 삭제",
        onClick: onClickDeleteComment,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles["item"]}>
      {update_mode ? (
        <div className={styles["update-area"]}>
          <CommentInput
            user={auth}
            ref={updateRef}
            initializeValue={content}
            onPostComment={updateComment}
            onClose={onCloseUpdateMode}
            autoFocus
            cancelText
          />
        </div>
      ) : (
        <div className={cn("parent", { new: new_created })}>
          <CommentProfile user={user} />
          <div className={styles["comment"]}>
            <div className={styles["main-content"]}>
              {user ? (
                <p className={styles["nickname"]}>
                  <Link
                    className={styles["link"]}
                    to={`${Paths.user.index}/${user.user_id}`}
                  >
                    {user?.nickname}
                  </Link>
                </p>
              ) : (
                "탈퇴한 회원입니다."
              )}
              <p className={styles["content"]}>{content}</p>
            </div>
            <div className={styles["info"]}>
              <span className={styles["created-at"]}>
                {dateToRelative(new Date(created_at))}
              </span>
              {!isChild && auth && (
                <span className={styles["reply"]} onClick={onToggleReplyMode}>
                  답글달기
                </span>
              )}
            </div>
            {auth?.user_id === user?.user_id && (
              <IconButton
                className={styles["more-info"]}
                onClick={onOpenActionModal}
              >
                <MoreInfoIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}
      <section className={styles["child-area"]}>
        {!isChild && reply_mode && (
          <div className={styles["reply-area"]}>
            <CommentInput
              user={auth}
              ref={replyRef}
              onPostComment={postComment}
              onClose={onCloseReplyMode}
              autoFocus
              cancelText
            />
          </div>
        )}
        <ChildCommentList list={child} board_id={board_id} />
      </section>
      <ViewActionModal
        open={modal}
        onClose={onCloseActionModal}
        actions={actions}
      />
    </div>
  );
};

const ChildCommentList = ({ list = [], board_id }) => {
  if (list.length === 0) return null;
  return (
    <section className={styles["child-list"]}>
      {list.map(item => (
        <Comment key={item.reply_id} {...item} board_id={board_id} isChild />
      ))}
    </section>
  );
};

const LIMIT = 5;

const CommentList = ({ list, board_id, scrollRef }) => {
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  const commentList = useMemo(() => {
    if (list) {
      const isParent = item => item.parent_id === 0 || item.parent_id === null;
      const parentList = list.filter(isParent).slice(0, offset + LIMIT);
      const childList = list.filter(item => !isParent(item));

      const newCommentList = [];
      parentList.forEach(parent => {
        const newParent = { ...parent, child: [] };
        childList.forEach(child => {
          if (parent.reply_id === child.parent_id) {
            newParent.child.push(child);
          }
        });
        newCommentList.push(newParent);
      });
      return newCommentList;
    }
    return [];
  }, [list, offset]);

  const onLoadComment = (offset, limit) => {
    if (list?.length > offset) {
      setLoading(true);
      setTimeout(() => {
        setOffset(offset + limit);
        setLoading(false);
      }, 500);
    }
  };

  return (
    <InfinityScroll
      offset={offset + LIMIT}
      limit={LIMIT}
      loading={loading}
      onLoad={onLoadComment}
      scrollRef={scrollRef}
    >
      <section className={styles["list"]}>
        {list === null || list === undefined ? (
          <SkeletonComments />
        ) : commentList.length !== 0 ? (
          commentList.map(item => (
            <Comment key={item.reply_id} {...item} board_id={board_id} />
          ))
        ) : (
          <div className={styles["empty"]}>작성된 댓글이 없습니다.</div>
        )}
      </section>
    </InfinityScroll>
  );
};

export const Comments = React.memo(CommentList);

export default Comment;
