import React from "react";

const PostIcon = ({ on }) => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24">
      <path
        d="M13.5,11.625v1.313a.564.564,0,0,1-.562.563H5.063a.564.564,0,0,1-.562-.562V11.625a.564.564,0,0,1,.563-.562h7.875A.564.564,0,0,1,13.5,11.625ZM12.938,15H5.063a.564.564,0,0,0-.562.563v1.313a.564.564,0,0,0,.563.563h7.875a.564.564,0,0,0,.563-.562V15.563A.564.564,0,0,0,12.938,15ZM18,6.183V21.75A2.251,2.251,0,0,1,15.75,24H2.25A2.251,2.251,0,0,1,0,21.75V2.25A2.251,2.251,0,0,1,2.25,0h9.567a2.25,2.25,0,0,1,1.589.661l3.933,3.933A2.242,2.242,0,0,1,18,6.183Zm-6-3.75V6h3.567L12,2.433ZM15.75,21.75V8.25H10.875A1.122,1.122,0,0,1,9.75,7.125V2.25H2.25v19.5Z"
        fill={on ? "#0066FF" : "#9e9e9e"}
      />
    </svg>
  );
};

export default PostIcon;
