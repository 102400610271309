import React from "react";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

import styles from "./SignDescription.module.scss";

const cn = classNames.bind(styles);

const SignDescription = ({ children, to, linkname, linkbold, center }) => {
  return (
    <p className={cn("description", { center })}>
      <span className={styles["jump"]}>
        <span className={styles["question"]}>{children}</span>
        <Link className={cn("link", { linkbold })} to={to}>
          {linkname}
        </Link>
      </span>
    </p>
  );
};

export default SignDescription;
