import React from "react";
import { WithContext } from "react-tag-input";
import "./HashtagInput.scss";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const HashtagInput = ({ tags, onAdditionTag, onDeleteTag }) => {
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  //   const handleDrag = (tag, currPos, newPos) => {
  //     const newTags = tags.slice();
  //     newTags.splice(currPos, 1);
  //     newTags.splice(newPos, 0, tag);
  //     setTags(newTags);
  //   };

  return (
    <WithContext
      inline
      tags={tags}
      handleAddition={onAdditionTag}
      handleDelete={onDeleteTag}
      handleDrag={() => {}}
      placeholder="# 해시태그를 입력해주세요."
      delimiters={delimiters}
    />
  );
};

export default HashtagInput;
