import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

//component
import { IconButton } from "@material-ui/core";

import _Arrow from "../../images/svg/arrow/arrow.svg";

import { Feeds } from "../../components/feed/Feed";
import Search from "../../components/search/Search";
import PageTitle from "../../components/title/PageTitle";
import SwipeNav from "../../components/swipe/SwipeNav";
import PostButton from "../../components/button/write/PostButton";
import InfinityScroll from "../../components/search/InfinityScroll";
import TitleHelmet from "../../components/title/TitleHelmet";

//hooks
import { useTokenCallback } from "../../hooks/storage/useToken";
import { useSearchPresets } from "../../hooks/redux/useSearch";
import useReplaceUrl from "../../hooks/useReplaceUrl";

import { Paths } from "../../paths";

//styles
import styles from "./PresetContainer.module.scss";

const cn = classNames.bind(styles);

const ORDER_TYPES = [
  { name: "최신순", value: "recently" },
  { name: "조회수", value: "hit" },
  { name: "다운로드수", value: "download_hit" },
];

const PresetContainer = ({ board_id, query }) => {
  const { m_name, p_name, o_name, keyword = "", order } = query; // url query 값

  const history = useHistory();

  const replaceChangeUrl = useReplaceUrl(Paths.preset.index, query);

  const { models, support_programs, support_operating_systems } = useSelector(
    state => state.info
  );

  const [search, setSearch] = useState(keyword);

  const [viewMobileFilter, setViewMobileFilter] = useState(false);

  const mID = models.find(model => model.name === m_name);
  const pID = support_programs.find(program => program.name === p_name);
  const oID = support_operating_systems.find(os => os.name === o_name);

  const model_id = mID ? mID.model_id : 0;
  const support_program_id = pID ? pID.support_program_id : 0;
  const support_os_id = oID ? oID.support_os_id : 0;

  const [preset_posts, loading, loadApiGetSearchPreset] = useSearchPresets(
    keyword,
    model_id,
    support_program_id,
    support_os_id,
    order
  );

  const offset = useMemo(() => preset_posts?.length, [preset_posts]);
  // const limit = useMemo(() => preset_posts?.length + 10, [preset_posts]);
  const limit = 10;

  const onInitSearch = () => {
    if (!query.keyword) {
      // 키워드가 없으면 직접 변경
      setSearch("");
    }
    replaceChangeUrl({ keyword: "" });
  };
  // 검색창 초기화
  const onChangeSearch = e => setSearch(e.target.value);
  // 검색창 내용 변경
  const onClickSearch = keyword => replaceChangeUrl({ keyword: keyword });
  // 추천 검색어 클릭
  const onEnterSearch = () => replaceChangeUrl({ keyword: search });
  // 검색

  const onChangeModelId = e => {
    const mName = models.find(
      model => model.model_id.toString() === e.target.value
    );
    replaceChangeUrl({ m_name: mName ? mName.name : "All" });
  };
  const onChangeProgramId = e => {
    const pName = support_programs.find(
      program => program.support_program_id.toString() === e.target.value
    );
    replaceChangeUrl({ p_name: pName ? pName.name : "All" });
  };
  const onChangeOsId = e => {
    const oName = support_operating_systems.find(
      os => os.support_os_id.toString() === e.target.value
    );
    replaceChangeUrl({ o_name: oName ? oName.name : "All" });
  };
  const onChangeOrder = e => {
    replaceChangeUrl({ order: e.target.value });
  };

  const onClickPostButton = useTokenCallback(
    () => {
      history.push(Paths.preset.write);
    },
    {
      isRequired: true,
    }
  );

  useEffect(() => {
    setSearch(keyword);
  }, [keyword]);

  return (
    <article className={styles["container"]}>
      <TitleHelmet title="프리셋 게시판" />
      <PageTitle>프리셋</PageTitle>
      <section className={cn("wrapper", "mobile-none")}>
        <PostButton text="게시글 작성" onClick={onClickPostButton} />
      </section>
      <section className={cn("wrapper", "search")}>
        <Search
          value={search}
          onChange={onChangeSearch}
          onEnter={onEnterSearch}
          onInit={onInitSearch}
          onClick={onClickSearch}
        />
      </section>
      <SwipeNav
        className={cn("wrapper", "filter", {
          "mobile-view": viewMobileFilter,
        })}
        state={viewMobileFilter}
        onChangeState={setViewMobileFilter}
        touchSize={100}
        delta={50}
      >
        <div className={styles["box"]}>
          <div className={styles["filter-area"]}>
            {models && (
              <select
                className={cn(["model", m_name])}
                onChange={onChangeModelId}
                value={model_id}
              >
                <option value={0}>All</option>
                {models.map(item => (
                  <option value={item.model_id} key={item.model_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            )}
            {support_operating_systems && (
              <select
                className={cn(["os", o_name])}
                onChange={onChangeOsId}
                value={support_os_id}
              >
                <option value={0}>All</option>
                {support_operating_systems.map(item => (
                  <option value={item.support_os_id} key={item.support_os_id}>
                    {item.name}
                  </option>
                ))}
              </select>
            )}
            {support_programs && (
              <select
                className={cn(["program", p_name])}
                onChange={onChangeProgramId}
                value={support_program_id}
              >
                <option value={0}>All</option>
                {support_programs.map(item => (
                  <option
                    value={item.support_program_id}
                    key={item.support_program_id}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className={styles["filter-area"]}>
            <select
              className={cn(["sort", order])}
              onChange={onChangeOrder}
              value={order}
            >
              {ORDER_TYPES.map(item => (
                <option value={item.value} key={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <IconButton
            className={styles["filter-open"]}
            onClick={() => setViewMobileFilter(!viewMobileFilter)}
          >
            <img className={styles["button-image"]} src={_Arrow} alt="arrow" />
          </IconButton>
        </div>
      </SwipeNav>
      <section className={cn("wrapper", "contents")}>
        <InfinityScroll
          offset={offset}
          limit={limit}
          loading={loading}
          onLoad={loadApiGetSearchPreset}
        >
          <Feeds
            list={preset_posts}
            // loading={loading}
            query={search}
            isPresetListView
            replaceChangeUrl={replaceChangeUrl}
          />
        </InfinityScroll>
      </section>
    </article>
  );
};

export default PresetContainer;
