import React, { useMemo } from "react";
import { Helmet } from "react-helmet";

const TitleHelmet = ({
  keywords,
  description,
  author,
  subject,
  title,
  noFront = false,
}) => {
  const renderBase = useMemo(() => `${title} | INVAIZ`, [title]);
  const renderTitle = useMemo(
    () => (noFront ? renderBase : `Community :: ${renderBase}`),
    [renderBase, noFront]
  );
  return (
    <Helmet>
      <meta name="author" property="author" content={author} />
      <meta name="subject" property="subject" content={subject} />
      <meta name="keywords" property="keywords" content={keywords} />
      <meta name="description" property="description" content={description} />
      <meta
        name="og:description"
        property="og:description"
        content={description}
      />
      <meta
        name="twitter:description"
        property="twitter:description"
        content={description}
      />
      <meta name="twitter:card" property="twitter:card" content={description} />
      <meta name="og:title" property="og:title" content={renderTitle} />
      <meta
        name="twitter:title"
        property="twitter:title"
        content={renderTitle}
      />
      <title>{renderTitle}</title>
    </Helmet>
  );
};

export default TitleHelmet;
