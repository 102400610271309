import { useDispatch, useSelector } from "react-redux";
import { Paths } from "../../paths";
import { do_bookmark, do_like, update_feed } from "../../store/feed";
import { useTokenCallback } from "../storage/useToken";
import { useSnackbar } from "../useSnackbar";

const useFeed = (board_id, copyUrlRef) => {
  const dispatch = useDispatch();
  const openSnackbar = useSnackbar();

  const feed = useSelector(state => state.feed);
  const viewFeed = feed[board_id];

  const updateFeedData = feedData => {
    dispatch(update_feed(feedData));
  };

  const callApiPutPresetLike = useTokenCallback(
    access_token => {
      dispatch(
        do_like({
          access_token,
          board_id,
        })
      );
    },
    {
      isRequired: true,
    }
  );
  const callApiPutPresetBookmark = useTokenCallback(
    access_token => {
      if (!viewFeed.doBookmark) {
        openSnackbar({
          message: "저장되었습니다.",
          variant: "success",
          up: true,
        });
      }
      dispatch(
        do_bookmark({
          access_token,
          board_id,
        })
      );
    },
    {
      isRequired: true,
    }
  );
  const onClickPresetDownload = () => {
    window.open(`${Paths.apiV2}/board/presets/${board_id}/download`, "_blank");
    openSnackbar({
      message: "프리셋을 다운로드합니다.",
      variant: "info",
      up: true,
    });
  };
  const onClickCopyUrl = () => {
    if (!document.queryCommandSupported("copy")) {
      return alert("복사 기능이 지원되지 않는 브라우저입니다.");
    }
    copyUrlRef.current.select();
    document.execCommand("copy");
    openSnackbar({
      message: "URL이 복사되었습니다.",
      variant: "info",
      up: true,
    });
  };

  return {
    feed: viewFeed,
    updateFeed: updateFeedData,
    doApiLike: callApiPutPresetLike,
    doApiBookmark: callApiPutPresetBookmark,
    doDownload: onClickPresetDownload,
    doCopy: onClickCopyUrl,
  };
};

export default useFeed;
