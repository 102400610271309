import React from "react";

import FindContainer from "../../../containers/find/FindContainer";

import { useSetTitle } from "../../../contexts/MobileTitleContext";

const FindSelectPage = () => {
  useSetTitle("아이디 / 비밀번호 찾기");
  return <FindContainer />;
};

export default FindSelectPage;
