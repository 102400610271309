import React from "react";
import FindIDContainer from "../../../../containers/find/id/FindIDContainer";
import { useSetTitle } from "../../../../contexts/MobileTitleContext";

const FindIDPage = () => {
  useSetTitle("아이디 찾기");
  return <FindIDContainer />;
};

export default FindIDPage;
