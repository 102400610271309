import React from "react";

import QnaContainer from "../../containers/contact/QnaContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const QnaPage = () => {
  useSetTitle("1:1 문의 내역");
  return <QnaContainer />;
};

export default QnaPage;
