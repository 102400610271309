import React from "react";

const default_color = "#fff";
const dark_color = "#666";
export default function ({ dark }) {
  return (
    <svg width="24" height="5" viewBox="0 0 24 5">
      <rect
        width="24"
        height="5"
        rx="2"
        fill={dark ? default_color : dark_color}
      />
    </svg>
  );
}
