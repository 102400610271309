import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestPostPresetReply = (token, board_id, content, parent_id) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}/replies`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const formData = {
    content,
    parent_id,
  };

  return axios.post(url, formData, config);
};

export const requestPutReply = (token, id, content) => {
  const url = `${resolveAPIURL("reply")}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    content,
  };

  return axios.put(url, formData, config);
};

export const requestDeleteReply = (token, id) => {
  const url = `${resolveAPIURL("reply")}/${id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.delete(url, config);
};
