import { useState } from "react";

const passwordRegex =
  /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;

const usePassword = (password, passwordCheck) => {
  const [sameCheck, setSameCheck] = useState(true);
  const [ruleCheck, setRuleCheck] = useState(true);

  const onRuleCheck = () => {
    // 패스워드 규칙에 맞는지 체크
    if (password) {
      setRuleCheck(passwordRegex.test(password));
    }
  };

  const onSameCheck = () => {
    // 비밀번호 / 비밀번호 확인 일치 여부.
    setSameCheck(password === passwordCheck);
  };

  return [
    {
      value: ruleCheck,
      onEvent: onRuleCheck,
      error: ruleCheck
        ? ""
        : "특수문자 / 문자 / 숫자 포함 형태의 8~16자리 이내의 비밀번호를 입력해 주세요.",
    },
    {
      value: sameCheck,
      onEvent: onSameCheck,
      error: sameCheck ? "" : "비밀번호가 일치하지 않습니다.",
    },
  ];
};

export default usePassword;
