import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestPostPreset = (
  token,
  model_id = 1,
  support_program_id = 1,
  support_os_id = 1,
  title,
  content,
  preset,
  hashtags
) => {
  const url = resolveAPIURL("board_preset");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const formData = new FormData();
  formData.append("model_id", model_id);
  formData.append("support_program_id", support_program_id);
  formData.append("support_os_id", support_os_id);
  formData.append("title", title);
  formData.append("content", content);
  formData.append("preset", preset);
  hashtags.forEach(hashtag => {
    formData.append("hashtags", hashtag);
  });

  return axios.post(url, formData, config);
};

export const requestGetPresetList = (
  token,
  keyword,
  user_id,
  model_id,
  support_program_id,
  support_os_id,
  order,
  offset = 0,
  limit = 10
) => {
  const url = resolveAPIURL("board_preset");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      keyword,
      user_id,
      model_id: model_id ? model_id : null,
      support_program_id: support_program_id ? support_program_id : null,
      support_os_id: support_os_id ? support_os_id : null,
      order,
      offset,
      limit,
    },
  };

  return axios.get(url, config);
};

export const requestGetPreset = (token, board_id) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.get(url, config);
};

export const requestPostDecryptPreset = crypt_content => {
  const url = resolveAPIURL("board_preset_decrypt");

  const data = {
    crypt_content,
  };

  return axios.post(url, data);
};

export const requestPutPresetUpdate = (
  token,
  board_id,
  model_id,
  support_os_id,
  support_program_id,
  title,
  content,
  preset = "",
  hashtags
) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = new FormData();
  formData.append("model_id", model_id);
  formData.append("support_program_id", support_program_id);
  formData.append("support_os_id", support_os_id);
  formData.append("title", title);
  formData.append("content", content);
  formData.append("preset", preset);
  hashtags.forEach(hashtag => {
    formData.append("hashtags", hashtag);
  });

  return axios.put(url, formData, config);
};

export const requestPutPresetView = (token, board_id) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}/view`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios.put(url, {}, config);
};

export const requestPutPresetHide = (token, board_id) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}/hide`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.put(url, {}, config);
};

export const requestDeletePreset = (token, board_id) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.delete(url, config);
};

export const requestPutPresetLike = (token, board_id) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}/like`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.put(url, {}, config);
};

export const requestPutPresetBookmark = (token, board_id) => {
  const url = `${resolveAPIURL("board_preset")}/${board_id}/bookmark`;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios.put(url, {}, config);
};
