import React from "react";

import DropProfileContainer from "../../../containers/mypage/update/DropProfileContainer";

import { useSetTitle } from "../../../contexts/MobileTitleContext";

const DropProfilePage = () => {
  useSetTitle("회원 탈퇴");
  return <DropProfileContainer />;
};

export default DropProfilePage;
