import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { get_user_info, logout_user, update_user } from "../../store/info";

import { setToken, useTokenCallback } from "../storage/useToken";
import useDialog from "../useDialog";

import { requestDeleteUser } from "../../api/auth";

import { getLoginPath } from "../storage/useLoginPath";

import { Paths } from "../../paths";
import { getStudioLogin, removeStudioLogin } from "../storage/useStudioLogin";

/**
 * 로그인 한 유저 데이터를 조회하기 위한 리덕스 훅.
 *
 * @returns 로그인 한 유저 데이터.
 */
const useUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const openDialog = useDialog();

  const { user } = useSelector(state => state.info);

  const onLogin = async (account_token, auto) => {
    setToken(account_token, auto);
    dispatch(get_user_info(account_token));
    const redirect_path = getLoginPath(); // 로그인 이전 위치로 리다이렉트
    const studio_login = getStudioLogin();
    if (studio_login) {
      removeStudioLogin();
      window.open(`${process.env.COMMUNITY_APP_STUDIO_URL}://${account_token}`);
      window.close();
    } else {
      history.replace(redirect_path || Paths.index);
    }
  };

  const onUpdateUser = userData => {
    dispatch(update_user(userData));
  };

  const callApiUserLogout = useTokenCallback(
    async access_token => {
      // 로그아웃 함수.
      // api 서버에 로그아웃 요청을 함.
      // 로그아웃이 완료되면 리덕스에 있는 로그인 데이터를 제거.
      try {
        dispatch(logout_user(access_token));
        openDialog("로그아웃 되었습니다.", {
          onClose: () => history.replace(Paths.index),
        });
      } catch (e) {
        console.log(e.response);
      }
    },
    {
      isRequired: true,
    }
  );

  const callApiUserSecession = useTokenCallback(
    async (access_token, reasonType, reasonComment) => {
      try {
        await requestDeleteUser(access_token, reasonType, reasonComment);
        dispatch(logout_user(access_token));
        openDialog("회원 탈퇴 신청하였습니다.", {
          text: "2주 내에 문의를 통해 유예할 수 있습니다.",
          onClose: () => history.replace(Paths.index),
        });
      } catch (e) {
        console.log(e);
      }
    }
  );

  return {
    user,
    onLogin: onLogin,
    onLogout: callApiUserLogout,
    onUpdate: onUpdateUser,
    onSecession: callApiUserSecession,
  };
};

export default useUser;
