import React from "react";
import styled from "styled-components";

import { Dialog, ButtonBase } from "@material-ui/core";

const ModalArticle = styled.article`
  width: 300px;
`;
const ActionItem = styled(ButtonBase)`
  width: 100%;
  height: 60px;
  font-size: 16px;
  color: #555;
  & + & {
    border-top: 1px solid #ebebeb;
  }
`;

const Empty = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: 20px;
  color: #777;
`;

const ViewActionModal = ({ open, onClose, actions = [] }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <ModalArticle>
        {actions.length !== 0 ? (
          actions.map(action => (
            <ActionItem key={action.name} onClick={action.onClick}>
              {action.name}
            </ActionItem>
          ))
        ) : (
          <Empty>선택 요소가 없습니다.</Empty>
        )}
      </ModalArticle>
    </Dialog>
  );
};

export default ViewActionModal;
