import React from "react";
import styles from "./SkeletonFeed.module.scss";

const SkeletonFeed = () => {
  return (
    <div className={styles["skeleton-item"]}>
      <div className={styles["skeleton-title-area"]}>
        <div className={styles["skeleton-image"]} />
        <div className={styles["skeleton-title"]} />
      </div>
      <div className={styles["skeleton-content"]} />
      <div className={styles["skeleton-bottom"]} />
    </div>
  );
};

export const SkeletonFeeds = () => {
  return (
    <>
      <SkeletonFeed />
      <SkeletonFeed />
      <SkeletonFeed />
      <SkeletonFeed />
    </>
  );
};

export default SkeletonFeed;
