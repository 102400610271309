import React from "react";
import { useHistory } from "react-router-dom";

import PhoneAuth from "../../../components/input/PhoneAuth";
import SignButton from "../../../components/button/SignButton";
import FindTextInput from "../../../components/input/FindTextInput";
import TitleHelmet from "../../../components/title/TitleHelmet";

import { Paths } from "../../../paths/index";
import { requestPostTemporary } from "../../../api/auth";

import useDialog from "../../../hooks/useDialog";
import useInputs from "../../../hooks/useInputs";
import useFindedEmail, {
  setFindedEmail,
} from "../../../hooks/storage/useFindedEmail";

import { setTemporaryToken } from "../../../hooks/storage/useTemporaryToken";

import styles from "../FindContainer.module.scss";

const initialState = {
  email: "",
  phoneNumber: "",
  verifyToken: null,
};

const FindPWContainer = () => {
  const history = useHistory();
  const openDialog = useDialog();

  const [findInfo, onChangeInput, findDispatch] = useInputs(initialState);

  const { email, phoneNumber, verifyToken } = findInfo;
  // Reducer 데이터 갖고 옴

  const onAuthComplete = verifyToken => {
    // 휴대폰 / 이메일 인증 후 콜백 함수
    findDispatch({ name: "verifyToken", value: verifyToken });
  };

  const onClickPostVerfiyToken = async () => {
    try {
      if (verifyToken) {
        const res = await requestPostTemporary(verifyToken, email, phoneNumber);
        if (res.status === 200) {
          setTemporaryToken(res.data.temporary_token);
          // 추후 이메일 사용을 위해 세션에 등록.
          setFindedEmail(email);
          history.push(Paths.find.password.reset);
        }
      }
    } catch (e) {
      openDialog(e?.response?.data?.message);
      findDispatch({ name: "verifyToken", value: null });
    }
  };

  useFindedEmail(finded_email => {
    // 이메일 찾아서 로그인할 시 바로 표시.
    findDispatch({ name: "email", value: finded_email });
  }, false);

  return (
    <>
      <TitleHelmet title="비밀번호 찾기" />
      <div className={styles["find-method-box"]}>
        <FindTextInput
          className={styles["input"]}
          type="email"
          name="email"
          value={email}
          placeholder="찾을 이메일"
          onChange={onChangeInput}
        />
        <div className={styles["phone-auth"]}>
          <PhoneAuth
            phoneNumber={phoneNumber}
            onChange={onChangeInput}
            complete={verifyToken}
            onComplete={onAuthComplete}
          />
        </div>
      </div>
      <SignButton
        disabled={!verifyToken}
        onClick={onClickPostVerfiyToken}
        name="다음"
      />
    </>
  );
};

export default FindPWContainer;
