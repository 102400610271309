import { useCallback, useEffect, useReducer, useState } from "react";

const TOUCH_START = "TOUCH_START";
const TOUCH_MOVE = "TOUCH_MOVE";
const TOUCH_END = "TOUCH_END";

const TOUCHED = "touched";
const CLICKED = "clicked";

const initialState = {
  [CLICKED]: false,
  [TOUCHED]: false,
  position: {
    x: -1,
    y: -1,
  },
  diff: {
    x: 0,
    y: 0,
  },
};
const touchReducer = (state, action) => {
  switch (action.type) {
    case TOUCH_START:
      return {
        diff: { x: 0, y: 0 },
        ...action.payload,
      };
    case TOUCH_MOVE:
      return {
        ...state,
        diff: {
          x: state.position.x - action.position.x,
          y: state.position.y - action.position.y,
        },
      };
    case TOUCH_END:
      return {
        ...state,
        [CLICKED]: false,
        [TOUCHED]: false,
        position: {
          x: -1,
          y: -1,
        },
      };
    default:
      return initialState;
  }
};

const useTouchMouse = (
  options = {
    use_touch: false,
    use_mouse: false,
  },
  ref
) => {
  const { use_touch, use_mouse } = options;

  const [events, setEvents] = useState({});
  const [touchState, dispatch] = useReducer(touchReducer, initialState);
  const { touched, clicked } = touchState;

  const isPress = touched || clicked;
  const dispatchStart = (push_type, clientPos) =>
    dispatch({
      type: TOUCH_START,
      payload: {
        [push_type]: true,
        position: clientPos,
      },
    });
  const dispatchMove = useCallback(clientPos => {
    dispatch({
      type: TOUCH_MOVE,
      position: clientPos,
    });
  }, []);

  /* ---------- Touch Event ---------- */
  const onTouchStart = useCallback(e => {
    const { clientX, clientY } = e.touches[0];
    const clientPos = {
      x: clientX,
      y: clientY,
    };
    dispatchStart(TOUCHED, clientPos);
  }, []);
  const onTouchMove = useCallback(
    e => {
      if (touched) {
        const { clientX, clientY } = e.touches[0];
        const clientPos = {
          x: clientX,
          y: clientY,
        };
        dispatchMove(clientPos);
      }
    },
    [dispatchMove, touched]
  );
  /* ---------- Touch Event ---------- */
  /* ---------- Mouse Event ---------- */
  const onMouseDown = useCallback(e => {
    const { clientX, clientY } = e;
    const clientPos = {
      x: clientX,
      y: clientY,
    };
    dispatchStart(CLICKED, clientPos);
  }, []);
  const onMouseMove = useCallback(
    e => {
      if (clicked) {
        const { clientX, clientY } = e;
        const clientPos = {
          x: clientX,
          y: clientY,
        };
        dispatchMove(clientPos);
      }
    },
    [dispatchMove, clicked]
  );
  const onEnd = () => {
    dispatch({ type: TOUCH_END });
  };
  /* ---------- Mouse Event ---------- */

  useEffect(() => {
    // const register = ref.current || window;
    // if (use_mouse) {
    //   register.addEventListener('mousedown', onMouseDown);
    //   register.addEventListener('mousemove', onMouseMove);
    //   register.addEventListener('mouseup', onEnd);
    //   register.addEventListener('mouseleave', onEnd);
    // }
    // if (use_touch) {
    //   register.addEventListener('touchstart', onTouchStart);
    //   register.addEventListener('touchmove', onTouchMove);
    //   register.addEventListener('touchend', onEnd);
    //   register.addEventListener('touchleave', onEnd);
    // }
    // return () => {
    //   if (use_mouse) {
    //     register.removeEventListener('mousedown', onMouseDown);
    //     register.removeEventListener('mousemove', onMouseMove);
    //     register.removeEventListener('mouseup', onEnd);
    //     register.removeEventListener('mouseleave', onEnd);
    //   }
    //   if (use_touch) {
    //     register.removeEventListener('touchstart', onTouchStart);
    //     register.removeEventListener('touchmove', onTouchMove);
    //     register.removeEventListener('touchend', onEnd);
    //     register.removeEventListener('touchleave', onEnd);
    //   }
    // };
  }, [
    onMouseDown,
    onMouseMove,
    onTouchMove,
    onTouchStart,
    ref,
    use_mouse,
    use_touch,
  ]);

  useEffect(() => {
    const eventObject = {};
    if (use_touch) {
      eventObject.onTouchStart = onTouchStart;
      eventObject.onTouchMove = onTouchMove;
      eventObject.onTouchEnd = onEnd;
      eventObject.onTouchCancel = onEnd;
    }
    if (use_mouse) {
      eventObject.onMouseDown = onMouseDown;
      eventObject.onMouseMove = onMouseMove;
      eventObject.onMouseUp = onEnd;
      eventObject.onMouseLeave = onEnd;
    }
    setEvents(eventObject);
  }, [
    onMouseDown,
    onMouseMove,
    onTouchMove,
    onTouchStart,
    use_mouse,
    use_touch,
  ]);

  return [touchState, isPress, events];
};

export default useTouchMouse;
