import React from "react";

import ChangePasswordContainer from "../../../containers/mypage/update/ChangePasswordContainer";

import { useSetTitle } from "../../../contexts/MobileTitleContext";

const ChangePasswordPage = () => {
  useSetTitle("비밀번호 변경");
  return <ChangePasswordContainer />;
};

export default ChangePasswordPage;
