import React from "react";

import ContactContainer from "../../containers/contact/ContactContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const ContactPage = () => {
  useSetTitle("고객센터");
  return <ContactContainer />;
};

export default ContactPage;
