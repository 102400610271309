import React from "react";
import classNames from "classnames/bind";

import Label from "./Label";
import ErrorText from "../error/ErrorText";

import styles from "./FindInput.module.scss";

const cn = classNames.bind(styles);

const FindTextInput = ({ label, error, ...restProps }) => {
  return (
    <div className={styles["box"]}>
      {label && <Label>{label}</Label>}
      <input {...restProps} className={cn("input", { error })} />
      {error && <ErrorText contents={error} />}
    </div>
  );
};

export default FindTextInput;
