import React from "react";

const Empty = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.564"
      height="26.684"
      viewBox="0 0 31.564 26.684"
    >
      <g
        transform="translate(-196.718 -56.658)"
        fill="none"
        stroke="#8b8b8b"
        strokeLinecap="square"
        strokeWidth="3"
      >
        <line x2="24" transform="translate(200 64)" />
        <line x2="24" transform="translate(200 72)" />
        <line x2="17" transform="translate(200 80)" fill="none" />
        <g
          transform="translate(199.5 59.5)"
          fill="#8b8b8b"
          strokeLinecap="round"
        >
          <path
            d="M 25.38612747192383 22.8039493560791 L 24.82383728027344 22.3484992980957 L -1.10204291343689 1.34850001335144 L -1.706092834472656 0.8592100143432617 L -1.19549286365509 0.2730700075626373 L 0.1385271102190018 -1.25832998752594 L 0.613827109336853 -1.803949952125549 L 1.176117062568665 -1.34850001335144 L 27.10199737548828 19.6515007019043 L 27.70604705810547 20.14079093933105 L 27.19544792175293 20.72693061828613 L 25.86142730712891 22.25832939147949 L 25.38612747192383 22.8039493560791 Z"
            stroke="none"
          />
          <path
            d="M 25.29590797424316 21.76569938659668 L 26.62992668151855 20.23430061340332 L 0.7040470838546753 -0.7656999826431274 L -0.6299728751182556 0.7656999826431274 L 25.29590797424316 21.76569938659668 M 25.47635650634766 23.84221076965332 L -2.782212972640991 0.9527300000190735 L 0.5235971212387085 -2.842210054397583 L 28.78216743469238 20.04726982116699 L 25.47635650634766 23.84221076965332 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Empty;
