import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  requestGetKeywordList,
  requestPutEnterKeyword,
} from "../../api/keyword";

const initialState = {
  loading: false,
  failure: false,
  success: false,

  keyword_dict: {},
};

const keywordSlice = createSlice({
  name: "keyword",
  initialState,
  reducers: {
    get_keywords: (state, action) => {
      state.failure = false;
      state.success = false;
      state.loading = true;
    },
    get_keywords_success: {
      reducer: (state, action) => {
        const { keywords, query } = action.payload;
        state.loading = false;
        state.success = true;
        state.keyword_dict[query] = keywords;
      },
      prepare: (keywords, query) => ({
        payload: { keywords, query },
      }),
    },
    get_keywords_failure: (state, action) => {
      state.loading = false;
      state.failure = true;
    },
    put_keywords: (state, action) => {},
    put_keywords_success: (state, action) => {},
    put_keywords_failure: (state, action) => {
      state.failure = true;
    },
  },
});

export const {
  get_keywords,
  get_keywords_success,
  get_keywords_failure,
  put_keywords,
  put_keywords_success,
  put_keywords_failure,
} = keywordSlice.actions;

function* get_keywords_saga(action) {
  try {
    const { payload: query } = action;
    const res = yield call(requestGetKeywordList, query);
    const { keywords } = res.data;
    yield put(get_keywords_success(keywords, query));
  } catch (e) {
    yield put(get_keywords_failure(e));
  }
}

function* put_keywords_enter_saga(action) {
  try {
    yield call(requestPutEnterKeyword, action.payload);
    yield put(put_keywords_success());
  } catch (e) {
    yield put(put_keywords_failure(e));
  }
}

export function* keyword_saga() {
  yield takeLatest(get_keywords.type, get_keywords_saga);
  yield takeEvery(put_keywords.type, put_keywords_enter_saga);
}

export default keywordSlice.reducer;
