import React, { useCallback, useEffect, useRef } from "react";
import { CircularProgress } from "@material-ui/core";

import styles from "./InfinityScroll.module.scss";

const InfinityScroll = ({
  offset,
  limit,
  loading,
  onLoad = (offset, limit) => {},
  scrollRef,
  children,
}) => {
  const loadRef = useRef(null);

  const onLoadScroll = useCallback(() => {
    const { documentElement, body } = document;
    const scrollTop = Math.max(documentElement.scrollTop, body.scrollTop);
    const usingScrollTop = scrollRef?.current?.scrollTop || scrollTop;

    const { clientHeight } = documentElement;

    const offsetTop = loadRef.current?.offsetTop;

    if (usingScrollTop + clientHeight > offsetTop) {
      onLoad(offset, limit);
    }
  }, [offset, limit, loadRef, onLoad, scrollRef]);

  useEffect(() => {
    const registDom = scrollRef?.current || window;
    registDom.addEventListener("scroll", onLoadScroll);
    return () => {
      registDom.removeEventListener("scroll", onLoadScroll);
    };
  }, [offset, limit, scrollRef, onLoadScroll]);

  return (
    <>
      {children}
      <section ref={loadRef}>
        {loading && (
          <div className={styles["loading"]}>
            <CircularProgress />
          </div>
        )}
      </section>
    </>
  );
};

export default InfinityScroll;
