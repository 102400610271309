import React from "react";

import styles from "./Banner.module.scss";

const Banner = ({ image, children }) => {
  return (
    <div
      style={{ backgroundImage: `url(${image})` }}
      className={styles["banner-container"]}
    >
      <div className={styles["banner-wrapper"]}>
        <p className={styles["banner-text"]}>{children}</p>
      </div>
    </div>
  );
};

export default Banner;
