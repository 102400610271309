import React from "react";
import styles from "./SkeletonSearchHashtag.module.scss";

const SkeletonSearchHashtag = () => {
  return <div className={styles["skeleton-search-hashtag"]} />;
};

export const SkeletonSearchHashtags = () => {
  return (
    <>
      <SkeletonSearchHashtag />
      <SkeletonSearchHashtag />
      <SkeletonSearchHashtag />
      <SkeletonSearchHashtag />
      <SkeletonSearchHashtag />
      <SkeletonSearchHashtag />
      <SkeletonSearchHashtag />
      <SkeletonSearchHashtag />
    </>
  );
};

export default SkeletonSearchHashtag;
