import { createAction, handleActions } from "redux-actions";

const OPEN = "snackbar/OPEN";
const CLOSE = "snackbar/CLOSE";

export const snackbarOpen = createAction(OPEN, (message, variant, up) => ({
  message,
  variant,
  up,
}));

export const snackbarClose = createAction(CLOSE, form => form);

const initialState = {
  message: "",
  variant: "default",
  up: false,
  open: false,
};

const snackbar = handleActions(
  {
    [OPEN]: (state, action) => {
      const { message, variant, up } = action.payload;
      return {
        ...state,
        message,
        variant,
        up,
        open: true,
      };
    },
    [CLOSE]: (state, action) => ({
      ...state,
      open: false,
    }),
  },
  initialState
);

export default snackbar;
