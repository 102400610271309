import React from "react";
import { useSelector } from "react-redux";

import { Dialog, IconButton } from "@material-ui/core";
import RepresentativeIconButton from "../button/RepresentativeIconButton";
import CloseIcon from "../../icons/CloseIcon";

import styles from "./IconModal.module.scss";

const IconModal = ({ open, onClick, onClose }) => {
  const { support_programs } = useSelector(state => state.info);
  return (
    <Dialog open={open} onClose={onClose}>
      <article className={styles["modal"]}>
        <div className={styles["table"]}>
          {support_programs &&
            support_programs.map(item => (
              <RepresentativeIconButton
                key={item.image}
                src={item.image}
                onClick={() => onClick(item.image)}
              />
            ))}
        </div>
        <IconButton className={styles["close"]} onClick={onClose}>
          <CloseIcon black={true} />
        </IconButton>
      </article>
    </Dialog>
  );
};

export default IconModal;
