import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestPOSTSignUp = (
  email,
  name,
  nickname,
  password,
  birth,
  sex,
  phone_number,
  agree,
  verify_token
) => {
  const url = resolveAPIURL("user");

  const config = {
    headers: {
      Authorization: `Bearer ${verify_token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    email,
    name,
    nickname,
    password,
    birth,
    sex,
    phone_number,
    agree,
  };
  return axios.post(url, formData, config);
};

export const requestPOSTSignIn = (email, password) => {
  const url = resolveAPIURL("user_signin");
  const data = {
    email,
    password,
  };
  return axios.post(url, data);
};

export const requestPostLogout = token => {
  const url = resolveAPIURL("user_logout");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios.post(url, {}, config);
};

export const requestGetUser = token => {
  const url = resolveAPIURL("user");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios.get(url, config);
};

export const requestPutProfile = (
  token,
  profile_image,
  representative_icon,
  nickname,
  introduce = "",
  name_is_public,
  init_profile
) => {
  const url = resolveAPIURL("user_profile");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  formData.append("profile_image", profile_image);
  formData.append("representative_icon", representative_icon);
  formData.append("nickname", nickname);
  formData.append("introduce", introduce);
  formData.append("name_is_public", name_is_public);
  formData.append("init_profile", init_profile);
  return axios.put(url, formData, config);
};

export const requestPutPersonalInfo = (token, sex, address, job, company) => {
  const url = resolveAPIURL("user_personal_info");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    sex,
    address,
    job,
    company,
  };

  return axios.put(url, formData, config);
};

export const requestPutPassword = (token, prev_password, password) => {
  const url = resolveAPIURL("user_password");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    prev_password,
    password,
  };
  return axios.put(url, formData, config);
};

export const requestDeleteUser = (token, reason_type, reason_comment) => {
  const url = resolveAPIURL("user");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: {
      reason_type,
      reason_comment,
    },
  };
  return axios.delete(url, config);
};

export const requestGetUserProfileByType = (
  token,
  id,
  type = "writer",
  offset = 0,
  limit = 10
) => {
  const url = `${resolveAPIURL("user")}/${id}/${type}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      offset,
      limit,
    },
  };
  return axios.get(url, config);
};

export const requestPostVerifyPhone = phone_number => {
  const url = resolveAPIURL("auth_verify_phone");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const formData = {
    phone_number,
  };
  return axios.post(url, formData, config);
};

export const requestPostVerifyEmail = email => {
  const url = resolveAPIURL("auth_verify_email");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const formData = {
    email,
  };
  return axios.post(url, formData, config);
};

export const requestPostVerifyConfirm = (identifier, auth_number) => {
  const url = resolveAPIURL("auth_verify_confirm");
  const config = {
    header: {
      "Content-Type": "application/json",
    },
  };
  const formData = {
    identifier,
    auth_number,
  };
  return axios.post(url, formData, config);
};

export const requestPostTemporary = (verify_token, email, phone_number) => {
  const url = resolveAPIURL("auth_issue_temporary");
  const config = {
    headers: {
      Authorization: `Bearer ${verify_token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    email,
    phone_number,
  };
  return axios.post(url, formData, config);
};

export const requestGetFindEmail = token => {
  const url = resolveAPIURL("user_email");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return axios.get(url, config);
};

export const requestPutFindPassword = (token, password) => {
  const url = resolveAPIURL("user_find_password");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const formData = {
    password,
  };
  return axios.put(url, formData, config);
};
