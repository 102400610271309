import React, { useState } from "react";
import classNames from "classnames/bind";
import { Button } from "@material-ui/core";

import SignTitle from "../../components/title/SignTitle";
import SignTextInput from "../../components/input/SignTextInput";
import SignDescription from "../../components/title/SignDescription";
import ErrorText from "../../components/error/ErrorText";
import Agree from "../../components/input/Agree";
import SNSButton from "../../components/button/SNSButton";
import TitleHelmet from "../../components/title/TitleHelmet";
import Loading from "../../components/assets/Loading";

import useInputs from "../../hooks/useInputs";
import useFindedEmail from "../../hooks/storage/useFindedEmail";
import useUser from "../../hooks/redux/useUser";

//api
import { requestPOSTSignIn } from "../../api/auth";

import { Paths } from "../../paths";

import styles from "./SignInContainer.module.scss";

const cn = classNames.bind(styles);

const initialState = {
  email: "",
  password: "",
};
const SignInContainer = () => {
  const { onLogin } = useUser();

  const [signInfo, onChangeInput, signDispatch] = useInputs(initialState);
  const [autoLogin, setAutoLogin] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  const canLogin =
    signInfo.email.length !== 0 && signInfo.password.length !== 0;
  // 로그인 버튼 활성화 유무

  const onClickAutoLogin = () => setAutoLogin(!autoLogin);
  // 자동 로그인 checkbox 변화 이벤트

  const onSubmitLogin = async e => {
    // 로그인 요청
    e.preventDefault();
    setLoading(true);
    try {
      const res = await requestPOSTSignIn(signInfo.email, signInfo.password);

      const account_token = res.data.account_token;
      onLogin(account_token, autoLogin);
    } catch (e) {
      setLoginError(e?.response?.data?.message);
    }
    setLoading(false);
  };

  useFindedEmail(finded_email => {
    // 이메일 찾아서 로그인할 시 바로 표시.
    signDispatch({ name: "email", value: finded_email });
  }, true);

  return (
    <div className={styles["container"]}>
      <TitleHelmet title="로그인" />
      <div className={styles["wrapper"]}>
        <div className={styles["note"]}>
          <SignTitle>로그인</SignTitle>
          <SignDescription to={Paths.signup} linkname="회원가입" linkbold>
            계정이 없으십니까?
          </SignDescription>
          <form className={styles["form-box"]} onSubmit={onSubmitLogin}>
            <SignTextInput
              label="이메일 주소"
              type="email"
              name="email"
              value={signInfo.email}
              onChange={onChangeInput}
              required
            />
            <SignTextInput
              label="비밀번호"
              type="password"
              name="password"
              value={signInfo.password}
              onChange={onChangeInput}
              required
            />
            {loginError && <ErrorText contents={loginError} />}
            <p className={styles["auto-check"]}>
              <Agree
                description="자동 로그인"
                checked={autoLogin}
                onClick={onClickAutoLogin}
              />
            </p>
            <Button
              type="submit"
              className={cn("signin-button", {
                on: canLogin,
              })}
            >
              로그인
            </Button>
          </form>
          <SignDescription
            to={Paths.find.index}
            linkname="아이디 찾기 / 비밀번호 찾기"
            center
          >
            계정을 잊어버리셨나요?
          </SignDescription>

          <div className={styles["social"]}>
            {["google", "facebook", "naver", "kakao"].map(serviceName => (
              <SNSButton key={serviceName} serviceName={serviceName} />
            ))}
          </div>
        </div>
      </div>
      <Loading open={loading} />
    </div>
  );
};

export default SignInContainer;
