import React from "react";
import { Link } from "react-router-dom";

import styles from "./TopInfoItem.module.scss";

/**
 * 프리셋 게시글 프리셋 정보 영역 스켈레톤 컴포넌트
 */
const SkeletonTopItem = () => {
  return <div className={styles["skeleton-top-item"]} />;
};

const TopInfoItem = ({ name, value, to }) => {
  return (
    <div className={styles["info-item"]}>
      <p className={styles["info-name"]}>{name} :</p>
      {value ? (
        <h4 className={styles["info-value"]}>
          {to ? (
            <Link className={styles["link"]} to={to}>
              {value}
            </Link>
          ) : (
            value
          )}
        </h4>
      ) : (
        <SkeletonTopItem />
      )}
    </div>
  );
};

export default TopInfoItem;
