import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Paths } from "../../paths";
import { Button } from "@material-ui/core";

import { Hashtags } from "../hashtag/Hashtag";
import FeedCheckBox from "./FeedCheckBox";
import EmptyResult from "../error/EmptyResult";
import { SkeletonFeeds } from "./SkeletonFeed";
import HighLightedText, { HighLightedString } from "../text/HighLightedText";
import CopyInput from "./CopyInput";

//hooks
import useFeed from "../../hooks/redux/useFeed";

//lib
import { dateToRelative } from "../../lib/formatter";

import styles from "./Feed.module.scss";

const cn = classNames.bind(styles);

const LinkOrDiv = ({ className, children, isDiv, to, onClick }) => {
  return (
    <>
      {isDiv ? (
        <div className={className} onClick={onClick}>
          {children}
        </div>
      ) : (
        <Link className={className} to={to}>
          {children}
        </Link>
      )}
    </>
  );
};

const TopInfoItem = ({ name, value, to, isDiv, onClick }) => {
  return (
    <div className={styles["info-item"]}>
      <p className={styles["info-name"]}>{name} :</p>
      <h4 className={styles["info-value"]}>
        {to ? (
          <LinkOrDiv
            className={styles["link"]}
            isDiv={isDiv}
            to={to}
            onClick={onClick}
          >
            {value}
          </LinkOrDiv>
        ) : (
          value
        )}
      </h4>
    </div>
  );
};

const Feed = ({ board_id, query, isPresetListView, replaceChangeUrl }) => {
  const copyUrlRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const { user } = useSelector(state => state.info);
  const { feed, doApiLike, doApiBookmark, doDownload, doCopy } = useFeed(
    board_id,
    copyUrlRef
  );

  const [onload, setOnload] = useState(false);

  // 댓글 모달
  const onClickComment = e => {
    history.push(`${Paths.comment}/${board_id}`, {
      background: location,
    });
  };

  useEffect(() => {
    setOnload(true);
  }, []);

  if (!feed) return null;
  const {
    content,
    hashtags,
    model,
    status,
    support_program,
    support_os,
    title,
    user: writer,
    like_users,
    doLike,
    operLike,
    bookmark_users,
    doBookmark,
    operBookmark,
    created_at,
  } = feed;

  return (
    <article className={cn("feed", { onload }, support_program?.name)}>
      <div className={styles["feed-wrapper"]}>
        <div className={styles["feed-content"]}>
          <div className={styles["top-area"]}>
            <div className={styles["title-area"]}>
              <LinkOrDiv
                className={cn("link", "program-image-area")}
                isDiv={isPresetListView}
                to={`${Paths.preset.index}?p_name=${support_program?.name}`}
                onClick={() =>
                  replaceChangeUrl({
                    p_name: support_program?.name,
                  })
                }
              >
                <img
                  className={styles["support-program"]}
                  src={support_program?.image}
                  alt="support_program"
                />
              </LinkOrDiv>
              <h2 className={styles["title"]}>
                <Link
                  className={styles["link"]}
                  to={`${Paths.preset.index}/${board_id}`}
                >
                  <HighLightedText query={query}>{title}</HighLightedText>
                </Link>
              </h2>
            </div>
            <div className={styles["top-info"]}>
              <TopInfoItem
                name="Model"
                value={model?.name}
                isDiv={isPresetListView}
                to={`${Paths.preset.index}?m_name=${model?.name}`}
                onClick={() =>
                  replaceChangeUrl({
                    m_name: model?.name,
                  })
                }
              />
              <TopInfoItem
                name="OS"
                value={support_os?.name}
                isDiv={isPresetListView}
                to={`${Paths.preset.index}?o_name=${support_os?.name}`}
                onClick={() =>
                  replaceChangeUrl({
                    o_name: support_os?.name,
                  })
                }
              />
              <TopInfoItem
                name="Date"
                value={dateToRelative(new Date(created_at), ".")}
              />
              <TopInfoItem
                name="Writer"
                value={writer?.nickname}
                to={`${Paths.user.index}/${writer?.user_id}`}
              />
            </div>
          </div>
          <div className={styles["feed-content-box"]}>
            <Link
              to={`${Paths.preset.index}/${board_id}`}
              className={cn("link", "feed-text")}
              dangerouslySetInnerHTML={{
                __html: HighLightedString(content, query),
              }}
            />
          </div>
          <div className={styles["bottom-info"]}>
            <div className={styles["hashtag-box"]}>
              <Hashtags list={hashtags} query={query} />
            </div>
            <div className={styles["feed-interaction"]}>
              <div className={styles["feed-check"]}>
                <FeedCheckBox
                  name={`좋아요 ${like_users?.length + operLike}개`}
                  checked={writer && doLike}
                  onClick={doApiLike}
                />
                <FeedCheckBox
                  name={`북마크 ${bookmark_users?.length + operBookmark}개`}
                  checked={writer && doBookmark}
                  onClick={doApiBookmark}
                />
              </div>
              <div className={styles["feed-button-area"]}>
                <Button
                  className={styles["feed-button"]}
                  onClick={onClickComment}
                >
                  댓글
                </Button>
                <Button className={styles["feed-button"]} onClick={doCopy}>
                  공유
                </Button>
                <Button
                  className={cn("feed-button", "download")}
                  onClick={doDownload}
                >
                  다운로드
                </Button>
              </div>
            </div>
          </div>
        </div>
        {status === 0 && (
          <div
            className={styles["screen"]}
            onClick={() => history.push(`${Paths.preset.index}/${board_id}`)}
          >
            <p className={styles["hide-text"]}>비공개된 게시글입니다.</p>
          </div>
        )}
      </div>
      <CopyInput
        ref={copyUrlRef}
        copyPath={`${window.location.host}${Paths.preset.index}/${board_id}`}
      />
    </article>
  );
};

const FeedList = ({
  list,
  loading,
  query,
  isPresetListView,
  replaceChangeUrl,
  children,
}) => {
  return (
    <section className={styles["list"]}>
      {list === null || list === undefined || loading ? (
        <SkeletonFeeds />
      ) : list.length !== 0 ? (
        list.map(board_id => (
          <Feed
            key={board_id}
            board_id={board_id}
            query={query}
            isPresetListView={isPresetListView}
            replaceChangeUrl={replaceChangeUrl}
          />
        ))
      ) : children ? (
        <>{children}</>
      ) : (
        <EmptyResult />
      )}
    </section>
  );
};

export const Feeds = React.memo(FeedList);

export default Feed;
