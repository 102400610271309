import React from "react";
import EmptyIcon from "../../icons/EmptyIcon";
import styles from "./EmptyResult.module.scss";

const EmptyResult = ({ height = 500, text }) => {
  return (
    <section className={styles["empty"]} style={{ height }}>
      <EmptyIcon />
      <p className={styles["text"]}>{text ? text : "결과가 없습니다."}</p>
    </section>
  );
};

export default EmptyResult;
