import React from "react";

import ErrorContainer from "../../containers/error/ErrorContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const ErrorPage = () => {
  useSetTitle("에러 페이지");
  return <ErrorContainer />;
};

export default ErrorPage;
