import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTokenCallback } from "../../hooks/storage/useToken";

import ProfileButton from "../profile/cover/ProfileButton";

const CommentInputArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const CommentProfileArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CommentProfileImage = styled.div`
  width: 30px;
  height: 30px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #f0f2f5;
  padding: 0 16px;
  margin-left: 12px;
`;

const CancelText = styled.div`
  padding: 0 40px;
  padding-top: 4px;
  @media (min-width: 0px) and (max-width: 767px) {
    font-size: 12px;
    text-align: right;
  }
`;

const CancelPoint = styled.span`
  color: #0066ff;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const CancelDescription = styled.span`
  @media (min-width: 0px) and (max-width: 767px) {
    display: none;
  }
`;

const CommentInput = React.forwardRef(
  (
    {
      user,
      initializeValue = "",
      autoFocus = false,
      cancelText = false,
      onPostComment = value => {},
      onClose = () => {},
    },
    ref
  ) => {
    const [writeComment, setWriteComment] = useState(initializeValue);

    const onChangeComment = e => setWriteComment(e.target.value);

    const onEnter = () => {
      onPostComment(writeComment);
      setWriteComment("");
      onClose();
    };

    const onKeyPress = e => {
      switch (e.key) {
        case "Enter":
          onEnter();
          break;
        default:
          break;
      }
    };

    const onKeyDown = e => {
      switch (e.key) {
        case "Escape":
          e.stopPropagation(); // 모달 꺼지는거 방지.
          setWriteComment("");
          onClose();
          break;
        default:
          break;
      }
    };

    const onClickGoLogin = useTokenCallback(() => {}, {
      isRequired: true,
    });

    useEffect(() => {
      if (autoFocus && ref.current) {
        setTimeout(() => {
          // 확실하게 포커스 시키기 위해 이벤트 큐
          ref.current.focus();
        }, 0);
      }
    }, [autoFocus, ref]);

    return (
      <>
        <CommentInputArea>
          <CommentProfileArea>
            <CommentProfileImage>
              <ProfileButton src={user?.profile_image} borderwidth={1} />
            </CommentProfileImage>
          </CommentProfileArea>
          <Input
            ref={ref}
            type="text"
            value={writeComment}
            onChange={onChangeComment}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            placeholder={
              user ? "댓글을 입력해 주세요." : "로그인이 필요합니다."
            }
            onClick={user ? () => {} : onClickGoLogin}
            readOnly={!user}
          />
        </CommentInputArea>
        {cancelText && (
          <CancelText>
            <CancelPoint onClick={onClose}>취소</CancelPoint>
            <CancelDescription>하려면 ESC를 누르세요.</CancelDescription>
          </CancelText>
        )}
      </>
    );
  }
);

export default CommentInput;
