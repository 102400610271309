import React from "react";
import classnames from "classnames/bind";

import _CheckImage from "../../images/main/check.png";

import styles from "./FeedCheckBox.module.scss";

const cn = classnames.bind(styles);

const FeedCheckBox = ({ name, checked, size = 10, onClick }) => {
  return (
    <div className={styles["checkbox"]} onClick={onClick}>
      <div
        className={styles["box"]}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <div
          className={cn("check", { checked: checked })}
          style={{
            backgroundImage: `url(${_CheckImage})`,
            width: `${checked ? size * 2.4 : 0}px`,
            height: `${size * 2.4}px`,
          }}
        />
      </div>
      <p className={styles["name"]}>{name}</p>
    </div>
  );
};

export default FeedCheckBox;
