import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom/client";
import ReactDOMServer from "react-dom/server";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";

import * as serviceWorker from "./serviceWorker";
import rootReducer, { rootSaga } from "./store";

import App from "./App";

const sagaMiddleware = createSagaMiddleware();

const devMode = process.env.NODE_ENV === "development";

const store = configureStore({
  reducer: rootReducer,
  preloadedState: window.__PRELOAD_STATE__,
  middleware: [sagaMiddleware],
  devTools: devMode,
});

const element = document.getElementById("invaiz");

sagaMiddleware.run(rootSaga);

const root =
  process.env.NODE_ENV === "production"
    ? ReactDOM.hydrateRoot(element)
    : ReactDOM.createRoot(element);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

serviceWorker.unregister();
