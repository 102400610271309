import React from "react";

import QnaViewContainer from "../../containers/contact/QnaViewContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const QnaViewPage = ({ match }) => {
  useSetTitle("1:1 문의 상세 보기");
  const { id } = match.params;
  return <QnaViewContainer id={id} />;
};

export default QnaViewPage;
