import React from "react";

const default_color = "#fff";
const dark_color = "#666";
export default function ({ dark }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="6"
      viewBox="0 0 13 6"
    >
      <g transform="translate(-875 -730)">
        <path
          d="M192.647,607.764a3,3,0,1,1,3-3A3,3,0,0,1,192.647,607.764Zm0-5.238a2.238,2.238,0,1,0,2.237,2.237A2.241,2.241,0,0,0,192.647,602.526Z"
          transform="translate(692.354 128.236)"
          fill={dark ? default_color : dark_color}
        />
        <path
          d="M192.647,607.764a3,3,0,1,1,3-3A3,3,0,0,1,192.647,607.764Zm0-5.238a2.238,2.238,0,1,0,2.237,2.237A2.241,2.241,0,0,0,192.647,602.526Z"
          transform="translate(685.354 128.236)"
          fill={dark ? default_color : dark_color}
        />
      </g>
    </svg>
  );
}
