import React, { useMemo, useRef, useState } from "react";
import classNames from "classnames/bind";
import { ButtonBase } from "@material-ui/core";

import FunctionTooltip from "./FunctionTooltip";

import Button1 from "../../icons/product/Button1";
import Button2 from "../../icons/product/Button2";
import Button3 from "../../icons/product/Button3";
import Button4 from "../../icons/product/Button4";
import ButtonGroup from "../../icons/product/ButtonGroup";

import defaultPreset from "./defaultPreset";

import styles from "./PresetViewer.module.scss";

const cn = classNames.bind(styles);

const button_array = [<Button1 />, <Button2 />, <Button3 />, <Button4 />];

const GroupViewer = ({ group, index }) => {
  return (
    <div className={cn("group")}>
      <p className={cn("group-title")}>
        <span className={cn("group-number")}>그룹 {index + 1}번</span>
        <span className={cn("group-name")}>{group.GroupName}</span>
      </p>
      <div className={styles["grid-10"]}>
        <div className={cn("wrapper")}>
          <div className={styles["encoder-box"]}>
            {group.dial.map((item, index) => {
              return (
                <FunctionTooltip func={item} key={index}>
                  <div className={cn("out-line")}>
                    <div className={cn("in-line")}>
                      <p className={cn("shortcut-name")}>{item.fname}</p>
                    </div>
                  </div>
                </FunctionTooltip>
              );
            })}
          </div>
          <div className={cn("button-box")}>
            {group.button.map((item, index) => {
              return (
                <FunctionTooltip func={item} key={index}>
                  <div className={cn("shortcut-button")}>
                    <div className={styles["button-cursor"]}>
                      {button_array[index]}
                    </div>
                    <p className={cn("item-name")}>{item.fname}</p>
                  </div>
                </FunctionTooltip>
              );
            })}
            <div className={cn("group-button")}>
              <div className={styles["button-cursor"]}>
                <ButtonGroup />
              </div>
              <p className={cn("item-name")}>그룹 {index + 1}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PresetViewer = ({ preset }) => {
  const viewerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const renderPreset = useMemo(() => {
    const data = preset ? preset : defaultPreset;
    if (open) {
      return data?.Groups;
    }
    return data?.Groups.slice(0, 1);
  }, [preset, open]);

  const onClickMore = () => {
    window.scrollTo({ top: viewerRef.current.offsetTop - 100 });
    setOpen(!open);
  };

  return (
    <section className={styles["viewer"]} ref={viewerRef}>
      {renderPreset &&
        renderPreset?.map((group, index) => (
          <GroupViewer key={index} group={group} index={index} />
        ))}
      <ButtonBase className={cn("more", { open })} onClick={onClickMore}>
        <p className={styles["more-text"]}>{open ? "접기" : "전체보기"}</p>
      </ButtonBase>
    </section>
  );
};

export default PresetViewer;
