import React from "react";
import qs from "qs";

import SearchReleaseNoteContainer from "../../containers/search/SearchReleaseNoteContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const SearchReleaseNotePage = ({ location }) => {
  useSetTitle("업데이트 소식 검색");
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return <SearchReleaseNoteContainer query={query} />;
};

export default SearchReleaseNotePage;
