import React from "react";
import styles from "./SkeletonReleaseNote.module.scss";

const SkeletonReleaseNote = () => {
  return (
    <div className={styles["skeleton-note"]}>
      <div className={styles["thumbnail"]} />
      <div className={styles["content-box"]}>
        <div className={styles["title"]} />
        <p className={styles["writer"]} />
        <div className={styles["content"]} />
      </div>
    </div>
  );
};

export const SkeletonReleaseNotes = () => {
  return (
    <>
      <SkeletonReleaseNote />
      <SkeletonReleaseNote />
    </>
  );
};

export default SkeletonReleaseNote;
