const STUDIO_LOGIN = "studio_login";

export const setStudioLogin = () => {
  sessionStorage.setItem(STUDIO_LOGIN, true);
};

export const getStudioLogin = () => sessionStorage.getItem(STUDIO_LOGIN);

export const removeStudioLogin = () => {
  sessionStorage.removeItem(STUDIO_LOGIN);
};
