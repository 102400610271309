import React, { useState, useEffect, useCallback, useReducer } from "react";
import classNames from "classnames/bind";
import { ButtonBase } from "@material-ui/core";

import IconModal from "../../../components/modal/IconModal";
import ProfileButton from "../../../components/profile/cover/ProfileButton";
import RepresentativeIconButton from "../../../components/button/RepresentativeIconButton";
import FindTextInput from "../../../components/input/FindTextInput";
import Label from "../../../components/input/Label";
import SignButton from "../../../components/button/SignButton";
import TitleHelmet from "../../../components/title/TitleHelmet";

//hooks
import useDialog from "../../../hooks/useDialog";
import { useTokenEffect } from "../../../hooks/storage/useToken";
import useUser from "../../../hooks/redux/useUser";

//api
import { requestPutProfile } from "../../../api/auth";

import styles from "./UpdateProfileContainer.module.scss";
import Toggle from "../../../components/input/Toggle";

const cn = classNames.bind(styles);

const SET_INIT_USER = "SET_INIT_USER";
const SET_ONE = "SET_ONE";
const SET_PROFILE = "SET_PROFILE";
const INIT_PROFILE = "INIT_PROFILE";

const initialState = {
  profile_image: null,
  representative_icon: null,
  nickname: "",
  introduce: "",
  name_is_public: false,
  init_profile: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_INIT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ONE:
      const { name, value } = action;
      return {
        ...state,
        [name]: value,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile_image: action.payload,
        init_profile: false,
      };
    case INIT_PROFILE:
      return {
        ...state,
        profile_image: null,
        init_profile: true,
      };
    default:
      return initialState;
  }
};

const ChangeProfileContainer = () => {
  const openDialog = useDialog();
  const { user, onUpdate } = useUser();

  const access_token = useTokenEffect();

  const [userProfile, dispatchUser] = useReducer(reducer, initialState);
  const [image_file, setImageFile] = useState(null);
  const [open, setModalOpen] = useState(false);
  const [nickname_error, setNicknameError] = useState("");

  const {
    representative_icon,
    nickname,
    introduce,
    profile_image,
    init_profile,
    name_is_public,
  } = userProfile;

  const onClickModalIcon = url => {
    dispatchUser({
      type: SET_ONE,
      name: "representative_icon",
      value: url,
    });
    setModalOpen(false);
  };
  const onOpenModal = () => setModalOpen(true);
  const onCloseModal = () => setModalOpen(false);

  const onBlurNickname = () => setNicknameError("");

  const onChangeInput = e => {
    dispatchUser({
      type: SET_ONE,
      name: e.target.name,
      value: e.target.value,
    });
  };
  const onChangeToggle = e => {
    dispatchUser({
      type: SET_ONE,
      name: e.target.name,
      value: e.target.checked,
    });
  };

  const onClickInitProfile = () => {
    setImageFile(null);
    dispatchUser({
      type: INIT_PROFILE,
    });
  };

  const onChangeProfile = useCallback(async e => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    try {
      if (file) {
        setImageFile(file);
        fileReader.onload = e => {
          dispatchUser({
            type: SET_PROFILE,
            payload: e.target.result,
          });
        };
      }
      fileReader.readAsDataURL(file);
    } catch (e) {
      openDialog("이미지 파일 업로드에 실패하였습니다.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickPutProfile = async () => {
    try {
      const res = await requestPutProfile(
        access_token,
        image_file,
        representative_icon,
        nickname,
        introduce,
        name_is_public,
        init_profile
      );
      onUpdate(res.data.user);
      openDialog("성공적으로 변경되었습니다.");
    } catch (e) {
      // let message = e?.response?.data?.message;
      if (e?.response?.data?.message === "Validation error") {
        const { message: error_message } = e.response.data.errors[0];
        if (error_message.indexOf("nickname") !== -1) {
          setNicknameError("중복된 닉네임입니다.");
        }
      }
    }
  };

  useEffect(() => {
    if (user) {
      dispatchUser({
        type: SET_INIT_USER,
        payload: {
          nickname: user.nickname,
          introduce: user.introduce,
          name_is_public: user.name_is_public,
          representative_icon: user.representative_icon,
          profile_image: user.profile_image,
        },
      });
    }
  }, [user]);

  return (
    <>
      <TitleHelmet title="프로필 변경" />
      <div className={styles["layout"]}>
        <div className={cn("division", "row")}>
          <div className={styles["area"]}>
            <Label>프로필 사진 바꾸기</Label>
            <div className={styles["profile-image"]}>
              <ProfileButton
                src={profile_image}
                borderwidth={3}
                component="label"
                htmlFor="change_profile"
              />
              <input
                type="file"
                id="change_profile"
                className={styles["change-profile"]}
                onChange={onChangeProfile}
                accept="image/gif, image/jpeg, image/png, image/svg"
              />
            </div>
            <ButtonBase
              className={styles["button"]}
              onClick={onClickInitProfile}
            >
              기본 이미지 사용
            </ButtonBase>
          </div>
          <div className={styles["area"]}>
            <Label>대표 아이콘 바꾸기</Label>
            <RepresentativeIconButton
              src={representative_icon}
              onClick={onOpenModal}
            />
          </div>
        </div>
        <div className={cn("division", "row")}>
          <FindTextInput
            label="이름"
            type="text"
            defaultValue={user?.name}
            disabled
          />
          <Toggle
            id="nameispublic_toggle"
            name="name_is_public"
            label="공개 유무"
            checked={name_is_public}
            onChange={onChangeToggle}
          />
        </div>
        <div className={styles["division"]}>
          <FindTextInput
            label="닉네임"
            type="text"
            name="nickname"
            value={nickname}
            onChange={onChangeInput}
            onBlur={onBlurNickname}
            error={nickname_error}
          />
          <FindTextInput
            label="자기소개 글"
            type="text"
            name="introduce"
            value={introduce}
            onChange={onChangeInput}
          />
        </div>
      </div>
      <SignButton name="저장하기" onClick={onClickPutProfile} />
      <IconModal
        open={open}
        onClick={onClickModalIcon}
        onClose={onCloseModal}
      />
    </>
  );
};

export default ChangeProfileContainer;
