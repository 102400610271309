import React, { useEffect, useState, useCallback } from "react";
import { Paths } from "../../../paths";
import { useHistory } from "react-router-dom";

import { requestGetFindEmail } from "../../../api/auth";

import SignButton from "../../../components/button/SignButton";
import TitleHelmet from "../../../components/title/TitleHelmet";

import useTemporaryToken from "../../../hooks/storage/useTemporaryToken";
import { setFindedEmail } from "../../../hooks/storage/useFindedEmail";

import styles from "./ViewIDContainer.module.scss";

const ViewIDContainer = () => {
  const history = useHistory();
  const temporary_token = useTemporaryToken(Paths.find.id.index);
  const [findEmail, setFindEmail] = useState(null);

  const onPushLogin = () => history.push(Paths.signin);
  const onPushFindPassword = () => history.push(Paths.find.password.index);

  const callGetTempUserInfo = useCallback(async () => {
    try {
      const res = await requestGetFindEmail(temporary_token);
      if (res.status === 200) {
        const { email } = res.data;
        setFindEmail(email);

        // 추후 이메일 사용을 위해 세션에 등록.
        setFindedEmail(email);
      }
    } catch (e) {
      history.replace(Paths.find.id.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callGetTempUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitleHelmet title="아이디 확인" />
      <p className={styles["email-view"]}>
        {findEmail && (
          <>
            가입하신 아이디는 <b>{findEmail}</b> 입니다.
          </>
        )}
      </p>
      <SignButton
        name="로그인 하기"
        subName="비밀번호 찾기"
        onClick={onPushLogin}
        onClickSub={onPushFindPassword}
      />
    </>
  );
};

export default ViewIDContainer;
