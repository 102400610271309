import React from "react";

import Label from "./Label";

import styles from "./FindInput.module.scss";

const SignSelect = ({ label, error, options = [], ...restProps }) => {
  return (
    <div className={styles["box"]}>
      <Label>{label}</Label>
      <select {...restProps} className={styles["input"]}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SignSelect;
