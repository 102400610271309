import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";

import { IconButton } from "@material-ui/core";

import _Back from "../../images/svg/arrow/back.svg";

import styles from "./MobileHeader.module.scss";

const cn = classNames.bind(styles);

const MobileHeader = ({ children }) => {
  const history = useHistory();
  const scroll = useRef(0);

  const [headerOff, setHeaderOff] = useState(false);

  const headerControll = e => {
    const st = window.scrollY;
    if (st <= 60) {
      setHeaderOff(false);
    } else {
      setHeaderOff(st > scroll.current);
    }
    scroll.current = st;
  };

  useEffect(() => {
    window.addEventListener("scroll", headerControll);
    return () => {
      window.removeEventListener("scroll", headerControll);
    };
  }, []);

  return (
    <>
      {children && (
        <header className={cn("mobile-header", { headerOff })}>
          <IconButton
            className={styles["back"]}
            onClick={() => history.goBack()}
          >
            <img src={_Back} alt="back" />
          </IconButton>
          <div className={styles["title"]}>{children}</div>
        </header>
      )}
    </>
  );
};

export default MobileHeader;
