import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";

import { ButtonBase } from "@material-ui/core";

import TextareaView from "../../components/text/TextareaView";
import TitleHelmet from "../../components/title/TitleHelmet";

//hooks
import { useTokenCallback } from "../../hooks/storage/useToken";
import useDialog from "../../hooks/useDialog";

//api
import { requestGetQnaItem, requestDeleteQnaItem } from "../../api/qna";

//lib
import { dateToRelative } from "../../lib/formatter";

import { Paths } from "../../paths";

import styles from "./QnaViewContainer.module.scss";

const cn = classNames.bind(styles);

/**
 * 1:1 문의 상단 영역 스켈레톤 컴포넌트
 */
const SkeletonTitleBar = () => {
  return (
    <div className={styles["skeleton-title-bar"]}>
      <div className={styles["skeleton-title"]} />
      <p className={styles["skeleton-created-at"]} />
      <div className={styles["skeleton-status"]} />
    </div>
  );
};

/**
 * 1:1 문의 내용 영역 스켈레톤 컴포넌트
 */
const SkeletonContent = () => {
  return <div className={styles["skeleton-content"]} />;
};

/**
 * 1:1 문의 하단 버튼 영역 스켈레톤 컴포넌트
 */
const SkeletonButton = () => {
  return (
    <div className={styles["skeleton-button-area"]}>
      <div className={styles["skeleton-button"]} />
      <div className={styles["skeleton-button"]} />
    </div>
  );
};

/**
 * 1:1 문의 상세 보기 컨테이너.
 *
 * id 값에 해당하는 문의 상세 데이터 렌더.
 */
const QnaViewContainer = ({ id }) => {
  const history = useHistory();
  const openDialog = useDialog();

  const [qna, setQna] = useState(null); // 1:1 문의 상세

  const isAnswer = qna?.a_created_at;
  // 딥변 완료 상태인지 확인.

  const onClickUpdate = () => history.push(`${Paths.contact.qna.write}/${id}`);
  // 1:1 문의 수정하러 가기 버튼 리스너

  const onClickDelete = () => {
    openDialog("해당 문의내역을 삭제하시겠습니까?", {
      text: "삭제를 원하시면 예를 눌러주세요.",
      onClick: callApiDeleteQna,
      isConfirm: true,
    });
  };
  // 1:1 문의 삭제 버튼 리스너

  const callApiGetQna = useTokenCallback(
    async access_token => {
      // 1:1 문의 내역 상세 api 호출
      try {
        const res = await requestGetQnaItem(access_token, id);
        if (res.status === 200) {
          setQna(res.data.qna);
        }
      } catch (e) {
        openDialog(e?.response?.data?.message, {
          onClose: () => history.goBack(),
        });
      }
    },
    { isRequired: true, goBack: true }
  );

  const callApiDeleteQna = useTokenCallback(
    async access_token => {
      // 1:1 문의 삭제 api 호출
      try {
        const res = await requestDeleteQnaItem(access_token, id);
        if (res.status === 200) {
          openDialog("삭제가 완료되었습니다.", {
            onClose: () => history.goBack(),
          });
        }
      } catch (e) {
        console.log(e.response);
      }
    },
    { isRequired: true }
  );

  useEffect(() => {
    // mount시 1:1 문의 상세 정보 가져 옴
    callApiGetQna();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderStatus = useMemo(
    () => (isAnswer ? "답변완료" : "답변대기"),
    [isAnswer]
  );

  return (
    <article className={styles["container"]}>
      {qna && (
        <TitleHelmet
          title={`문의 - ${qna.title} (${renderStatus})`}
          description={qna.question}
          subject={qna.title}
        />
      )}
      <section className={styles["wrapper"]}>
        <div className={styles["question"]}>
          {qna ? (
            <div className={styles["title-bar"]}>
              <h2 className={styles["title"]}>{qna.title}</h2>
              <p className={styles["created-at"]}>
                {dateToRelative(new Date(qna.created_at), ".")}
              </p>
              <div
                className={cn("status", {
                  on: isAnswer,
                })}
              >
                {renderStatus}
              </div>
            </div>
          ) : (
            <SkeletonTitleBar />
          )}
          {qna ? (
            <TextareaView
              className={styles["content"]}
              content={qna.question}
            />
          ) : (
            <SkeletonContent />
          )}
        </div>
        {isAnswer && (
          <div className={styles["answer"]}>
            <div className={styles["title-bar"]}>
              <h2 className={styles["title"]}>답변</h2>
              <p className={styles["created-at"]}>
                {dateToRelative(new Date(qna.a_created_at), ".")}
              </p>
            </div>
            <TextareaView className={styles["content"]} content={qna.answer} />
          </div>
        )}
        <div className={styles["button-area"]}>
          {qna ? (
            <>
              {!isAnswer && (
                <ButtonBase
                  className={cn("button", "update")}
                  onClick={onClickUpdate}
                >
                  수정하기
                </ButtonBase>
              )}
              <ButtonBase
                className={cn("button", "delete")}
                onClick={onClickDelete}
              >
                삭제하기
              </ButtonBase>
            </>
          ) : (
            <SkeletonButton />
          )}
        </div>
      </section>
    </article>
  );
};

export default QnaViewContainer;
