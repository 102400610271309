import React from "react";
import { Button } from "@material-ui/core";
import classNames from "classnames/bind";
import styles from "./SignButton.module.scss";

const cn = classNames.bind(styles);

const SignButton = ({
  disabled,
  name,
  onClick,
  subDisabled,
  subName,
  onClickSub,
}) => {
  return (
    <div className={styles["box"]}>
      <Button
        disabled={disabled}
        type="submit"
        className={cn("button", {
          disabled: disabled,
        })}
        onClick={onClick}
      >
        {name}
      </Button>
      {subName && (
        <Button
          disabled={subDisabled}
          className={cn("button", "sub", {
            disabled: subDisabled,
          })}
          onClick={onClickSub}
        >
          {subName}
        </Button>
      )}
    </div>
  );
};

export default SignButton;
