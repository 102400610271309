import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames/bind";
import { Link, useHistory, useLocation } from "react-router-dom";

import EmptyResult from "../error/EmptyResult";
import ProfileButton from "./cover/ProfileButton";
import { SkeletonProfiles } from "./SkeletonProfile";

import { Paths } from "../../paths";

import styles from "./Profile.module.scss";

const cn = classNames.bind(styles);

const Profile = ({
  user_id,
  profile_image,
  representative_icon,
  nickname,
  name,
  name_is_public,
  email,
  introduce,
}) => {
  const history = useHistory();
  const location = useLocation();

  const onClickProfileImage = () => {
    history.push(`${Paths.profileimage}/${user_id}`, {
      background: location,
    });
  };
  const [onload, setOnload] = useState(false);

  useEffect(() => {
    setOnload(true);
  }, []);

  const renderUserName = useMemo(
    () => (name_is_public ? name : "미공개"),
    [name, name_is_public]
  );

  return (
    <article className={cn("profile", { onload })}>
      <div className={styles["image-view"]}>
        <div className={styles["profile-image"]}>
          <ProfileButton
            src={profile_image}
            iconsrc={representative_icon}
            iconsize={{
              width: 60,
              height: 60,
            }}
            borderwidth={4}
            onClick={onClickProfileImage}
          />
        </div>
      </div>
      <div className={styles["info"]}>
        <h4 className={styles["nickname"]}>
          <Link to={`${Paths.user.index}/${user_id}`}>{nickname}</Link>
        </h4>
        <p className={styles["name"]}>{renderUserName}</p>
        <p className={styles["email"]}>
          <Link to={`${Paths.user.index}/${user_id}`}>{email}</Link>
        </p>
        {introduce && <p className={styles["introduce"]}>{introduce}</p>}
      </div>
    </article>
  );
};

const ProfileList = ({ list, loading }) => {
  return (
    <section className={styles["list"]}>
      {list === null || loading ? (
        <SkeletonProfiles />
      ) : list.length !== 0 ? (
        list.map(item => <Profile {...item} key={item.user_id} />)
      ) : (
        <EmptyResult height={200} />
      )}
    </section>
  );
};

export const Profiles = React.memo(ProfileList);

export default Profile;
