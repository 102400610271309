import React from "react";
import { Route, Switch } from "react-router-dom";
// import CacheRoute, { CacheSwitch } from 'react-router-cache-route';

import PresetPage from "./PresetPage";
import PresetViewPage from "./PresetViewPage";
import PresetWritePage from "./PresetWritePage";

import { Paths } from "../../paths";

const PresetRoutePage = () => {
  return (
    <Switch>
      <Route
        path={`${Paths.preset.write}/:id?`}
        component={PresetWritePage}
        exact
      />
      <Route
        path={`${Paths.preset.index}/:id`}
        component={PresetViewPage}
        when="back"
        exact
      />
      <Route
        path={Paths.preset.index}
        component={PresetPage}
        when="back"
        exact
      />
    </Switch>
  );
};

export default PresetRoutePage;
