import React from "react";
/* Library */

import { useSelector } from "react-redux";
/* Redux */

import Dialog from "../../components/assets/Dialog";
/* Components */

const DialogContainer = () => {
  const state = useSelector(state => state.dialog);
  return <Dialog {...state} />;
};

export default DialogContainer;
