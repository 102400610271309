import React from "react";
import { Switch, Route } from "react-router-dom";

import SignTitle from "../../../components/title/SignTitle";
import UpdateTabs from "../../../components/tab/UpdateTabs";

import useBlockRefresh from "../../../hooks/useBlockRefresh";
import useUser from "../../../hooks/redux/useUser";

import { Paths } from "../../../paths";

import { MobileTitleConsumer } from "../../../contexts/MobileTitleContext";

import ChangeProfilePage from "./ChangeProfilePage";
import ChangeInfoPage from "./ChangeInfoPage";
import ChangePasswordPage from "./ChangePasswordPage";
import DropProfilePage from "./DropProfilePage";
import AdminPage from "./AdminPage";

import styles from "./UpdateProfileRoutePage.module.scss";

const tabs = [
  {
    title: "프로필 변경",
    link: Paths.user.profile,
  },
  {
    title: "개인정보 변경",
    link: Paths.user.info,
  },
  {
    title: "비밀번호 변경",
    link: Paths.user.password,
  },
  {
    title: "회원탈퇴",
    link: Paths.user.drop,
  },
];

const UpdateProfileRoutePage = ({ location, history }) => {
  const onClickTab = link => history.push(link);
  const { user } = useUser();

  const additionTab =
    user?.level === 999 ? [{ title: "관리자", link: Paths.user.admin }] : [];

  useBlockRefresh();
  return (
    <div className={styles["container"]}>
      <div className={styles["tabs"]}>
        <UpdateTabs
          currentPath={location.pathname}
          tabs={tabs.concat(...additionTab)}
          onClickTab={onClickTab}
        />
      </div>
      <div className={styles["wrapper"]}>
        <div className={styles["note"]}>
          <MobileTitleConsumer>{SignTitle}</MobileTitleConsumer>
          <Switch>
            <Route
              path={Paths.user.profile}
              component={ChangeProfilePage}
              exact
            />
            <Route path={Paths.user.info} component={ChangeInfoPage} exact />
            <Route
              path={Paths.user.password}
              component={ChangePasswordPage}
              exact
            />
            <Route path={Paths.user.drop} component={DropProfilePage} exact />
            {user?.level === 999 && (
              <Route path={Paths.user.admin} component={AdminPage} exact />
            )}
            <Route
              render={({ history }) => history.replace(Paths.user.profile)}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfileRoutePage;
