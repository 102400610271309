import React from "react";
const default_color = "#fff";
const dark_color = "#666";

export default function ({ dark }) {
  return (
    <svg width="6" height="6" viewBox="0 0 6 6">
      <path
        d="M192.647,607.764a3,3,0,1,1,3-3A3,3,0,0,1,192.647,607.764Zm0-5.238a2.238,2.238,0,1,0,2.237,2.237A2.241,2.241,0,0,0,192.647,602.526Z"
        transform="translate(-189.646 -601.764)"
        fill={dark ? default_color : dark_color}
      />
    </svg>
  );
}
