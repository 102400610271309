import React, { useEffect, useState } from "react";
import { CircularProgress, Dialog, IconButton, Slide } from "@material-ui/core";

import CloseIcon from "../../icons/CloseIcon";
import ProfileImage from "../profile/cover/ProfileImage";

import useDialog from "../../hooks/useDialog";
import useTouchMouse from "../../hooks/useTouchMouse";

import { requestGetUserProfileByType } from "../../api/auth";

import styles from "./ProfileModal.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileModal = ({ id, open, onClose }) => {
  const openDialog = useDialog();
  const [user, setUser] = useState(null);

  const [touchState, isPress, events] = useTouchMouse({
    use_touch: true,
  });
  const { diff } = touchState;

  const callApiGetUser = async () => {
    try {
      const res = await requestGetUserProfileByType(null, id);
      setUser(res.data.user);
    } catch (e) {
      openDialog(e?.response?.data?.message, {
        onClose: onClose,
      });
    }
  };

  useEffect(() => {
    if (id && open) {
      if (user?.user_id !== parseInt(id)) {
        setUser(null);
        callApiGetUser();
      }
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!isPress && diff.y < -100) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPress]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className={styles["dialog"]}
      {...events}
    >
      <div className={styles["cover"]}>
        <IconButton
          className={styles["close"]}
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon white />
        </IconButton>
        <div className={styles["image-area"]}>
          {user ? (
            <ProfileImage
              imageSize={{ width: "100%", height: "100%", borderWidth: 1 }}
              src={user?.profile_image}
            />
          ) : (
            <CircularProgress size={"100%"} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ProfileModal;
