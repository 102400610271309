import { createAction, handleActions } from "redux-actions";

const OPEN = "dialog/OPEN";
const CLOSE = "dialog/CLOSE";

export const dialogOpen = createAction(OPEN, form => form);

export const dialogClose = createAction(CLOSE);

const initialState = {
  open: false,
  title: "",
  text: "",
  onClick: () => {},
  onClose: () => {},
  isConfirm: false,
};

const dialog = handleActions(
  {
    [OPEN]: (state, action) => ({
      ...state,
      ...action.payload,
      open: true,
    }),
    [CLOSE]: (state, action) => initialState,
  },
  initialState
);

export default dialog;
