import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";

import { CircularProgress } from "@material-ui/core";

import styles from "./Refresh.module.scss";

const cn = classNames.bind(styles);

const Refresh = ({ refresh }) => {
  const { action } = refresh;
  const [render, setRender] = useState(false);
  const [animation, setAnimation] = useState(false);

  const onTransitionEnd = () => {
    setRender(animation);
  };

  useEffect(() => {
    setAnimation(action);
  }, [action]);

  return (
    <>
      {(action || render || animation) && (
        <div
          className={cn("container", { isRefresh: animation })}
          onTransitionEnd={onTransitionEnd}
        >
          <div className={styles["refresh"]}>
            <CircularProgress />
          </div>
        </div>
      )}
    </>
  );
};

export default Refresh;
