import { createSlice } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { requestGetViewMain } from "../../api/combination";

import { update_feeds } from "../feed";

const initialState = {
  loading: false,
  failure: false,
  success: false,

  hashtags: null,
  recently_posts: null,
  notes: null,
};

const viewMainSlice = createSlice({
  name: "view_main",
  initialState,
  reducers: {
    get_view: (state, action) => {
      state.loading = true;
      state.failure = false;
      state.success = false;
    },
    get_view_success: (state, action) => {
      const { hashtags, recently_posts, notes } = action.payload;

      state.loading = false;
      state.success = true;
      state.recently_posts = recently_posts.map(post => post.board_id);
      state.notes = notes;
      state.hashtags = hashtags;
    },
    get_view_failure: (state, action) => {
      state.loading = false;
      state.failure = true;
    },
  },
});

export const { get_view, get_view_success, get_view_failure } =
  viewMainSlice.actions;

function* get_view_main_saga(action) {
  try {
    const res = yield call(requestGetViewMain, action.payload);
    yield put(get_view_success(res.data));
    yield put(update_feeds(res.data.recently_posts)); // 피드 데이터에 받아온 데이터 추가 및 갱신.
  } catch (e) {
    yield put(get_view_failure(e));
  }
}

export function* view_main_saga() {
  yield takeLatest(get_view.type, get_view_main_saga);
}

export default viewMainSlice.reducer;
