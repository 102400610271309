import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { dialogOpen } from "../store/dialog";

const useDialog = () => {
  const dispatch = useDispatch();
  const openDialog = useCallback(
    (
      title,
      options = {
        text: "",
        onClick: () => {},
        onClose: () => {},
        isConfirm: false,
      }
    ) => {
      if (options.onClose && !options.onClick) {
        // 닫는 리스너만 있고 클릭 리스너가 없을 때,
        options.onClick = options.onClose;
      }
      dispatch(dialogOpen({ title, ...options }));
    },
    [dispatch]
  );
  return openDialog;
};

export default useDialog;
