import React from "react";
import { Snackbar, Slide } from "@material-ui/core";
import classNames from "classnames/bind";

import styles from "./Snackbar.module.scss";

const cn = classNames.bind(styles);

const TransitionLeft = props => {
  return <Slide {...props} direction="left" />;
};

const SnackBar = ({ open, message, variant, up }) => {
  return (
    <Snackbar
      className={cn("snackbar", variant, { up })}
      open={open}
      autoHideDuration={6000}
      TransitionComponent={TransitionLeft}
      message={message}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    />
  );
};

SnackBar.defaultProps = {
  open: false,
  message: "",
  variant: "default",
  up: false,
};

export default SnackBar;
