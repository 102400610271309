import React from "react";
import Refresh from "../../components/assets/Refresh";
import useRefresh from "../../hooks/redux/useRefresh";

const RefreshContainer = () => {
  const [refresh] = useRefresh();
  return <Refresh refresh={refresh} />;
};

export default RefreshContainer;
