import { combineReducers } from "redux";
import info, { info_saga } from "./info";

import refresh from "./refresh";
import snackbar from "./snackbar";
import dialog from "./dialog";
import loading from "./loading";

import view_main, { view_main_saga } from "./view/main";
import view_contact, { view_contact_saga } from "./view/contact";
import mypage, { mypage_saga } from "./view/mypage";

import keyword, { keyword_saga } from "./search/keyword";
import search, { search_saga } from "./search/search";

import feed, { feed_saga } from "./feed";

import { all } from "redux-saga/effects";

const rootReducer = combineReducers({
  refresh,
  snackbar,
  dialog,
  loading,
  info,
  feed,
  view_main,
  view_contact,
  mypage,
  keyword,
  search,
});

export function* rootSaga() {
  yield all([
    info_saga(),
    view_main_saga(),
    view_contact_saga(),
    mypage_saga(),
    keyword_saga(),
    search_saga(),
    feed_saga(),
  ]);
}

export default rootReducer;
