import React from "react";

import MainContainer from "../containers/main/MainContainer";

import { useSetTitle } from "../contexts/MobileTitleContext";

const MainPage = () => {
  useSetTitle(null);
  return <MainContainer />;
};

export default MainPage;
