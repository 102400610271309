import React from "react";

import UserProfileContainer from "../../containers/mypage/UserProfileContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const UserProfile = ({ match }) => {
  const { user_id } = match.params;

  useSetTitle(null);
  return <UserProfileContainer user_id={user_id} />;
};

export default UserProfile;
