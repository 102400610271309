import React from "react";
import { IconButton } from "@material-ui/core";

import ProfileCoverIcon from "./ProfileCoverIcon";
import ProfileImage from "./ProfileImage";

import styles from "./ProfileButton.module.scss";

const ProfileButton = props => {
  const { src, iconsrc, iconsize, borderwidth, ...rest } = props;
  return (
    <div className={styles["profile-image"]}>
      <IconButton {...rest} className={styles["image-button"]}>
        <ProfileImage
          src={src}
          imageSize={{
            width: "100%",
            height: "100%",
            borderWidth: borderwidth,
          }}
        />
      </IconButton>
      {iconsrc !== undefined && (
        <div className={styles["icon"]}>
          <ProfileCoverIcon src={iconsrc} size={iconsize} />
        </div>
      )}
    </div>
  );
};

export default ProfileButton;
