import React, { useCallback, useState } from "react";
import classNames from "classnames/bind";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

import { Paths } from "../../paths";

import _Logo from "../../images/svg/logo/invaiz_community.svg";

import PostIcon from "../../icons/tab/PostIcon";
import ContactIcon from "../../icons/header/ContactIcon";
import HomeIcon from "../../icons/header/HomeIcon";
import SearchIcon from "../../icons/header/SearchIcon";
import ProfileImage from "../profile/cover/ProfileImage";

import DropdownMenu from "./DropdownMenu";

import useUser from "../../hooks/redux/useUser";
import { useRefreshCallback } from "../../hooks/redux/useRefresh";
import useCurrentUrl from "../../hooks/useCurrentUrl";

import styles from "./Header.module.scss";

const cn = classNames.bind(styles);

const currentUrlWithSearch = (path, isCurrent, search) => {
  // 같은 path일 경우, search까지 포함해서 이동.
  return isCurrent ? `${path}${search}` : path;
};

const Header = ({ loading }) => {
  const { search } = useLocation();
  const [onStartRefresh] = useRefreshCallback();
  const { user, onLogout: onClickLogout } = useUser();

  const [unopenable, setUnopenable] = useState(true);
  // 호버 제대로 보기 하기 위해

  const onClickRefresh = useCallback(
    isCurrentUrl => e => {
      if (isCurrentUrl) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        }); // 스크롤을 최상단으로 이동.
        if (window.scrollY === 0) {
          // 스크롤이 최상단에 위치했을 경우.
          onStartRefresh(); // 새로고침 실행.
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isCurrentHome = useCurrentUrl(Paths.index, true); // 현재 메인 페이지 인지.
  const isCurrentSearch = useCurrentUrl(Paths.search.index); // 현재 검색 페이지 인지.
  const isCurrentPreset = useCurrentUrl(Paths.preset.index); // 현재 프리셋 페이지 인지.
  const isCurrentContact = useCurrentUrl(Paths.contact.index); // 현재 고객센터 페이지 인지.
  const isCurrentMypage = useCurrentUrl(Paths.user.index); // 현재 마이 페이지 인지.

  const user_page_url = user?.user_id
    ? `${Paths.user.index}/${user.user_id}`
    : Paths.signin;

  return (
    <header className={styles["header"]}>
      <div className={styles["wrapper"]}>
        <Link
          to={Paths.index}
          className={styles["logo-area"]}
          onClick={onClickRefresh(isCurrentHome)}
        >
          <img src={_Logo} alt="logo" className={styles["logo"]} />
        </Link>
        <nav className={styles["nav"]}>
          <ul className={styles["nav-list"]}>
            <li className={cn("list-element", "home")}>
              <Link
                to={currentUrlWithSearch(Paths.index, isCurrentHome, search)}
                className={styles["link"]}
                onClick={onClickRefresh(isCurrentHome)}
              >
                <IconButton className={styles["link-btn"]}>
                  <HomeIcon on={isCurrentHome} />
                </IconButton>
              </Link>
            </li>
            <li className={styles["list-element"]}>
              <Link
                to={currentUrlWithSearch(
                  Paths.search.index,
                  isCurrentSearch,
                  search
                )}
                className={styles["link"]}
                onClick={onClickRefresh(isCurrentSearch)}
              >
                <IconButton className={styles["link-btn"]}>
                  <SearchIcon on={isCurrentSearch} />
                </IconButton>
              </Link>
            </li>
            <li className={styles["list-element"]}>
              <Link
                to={currentUrlWithSearch(
                  Paths.preset.index,
                  isCurrentPreset,
                  search
                )}
                className={styles["link"]}
                onClick={onClickRefresh(isCurrentPreset)}
              >
                <IconButton className={styles["link-btn"]}>
                  <PostIcon on={isCurrentPreset} />
                </IconButton>
              </Link>
            </li>
            <li className={styles["list-element"]}>
              <Link
                to={currentUrlWithSearch(
                  Paths.contact.index,
                  isCurrentContact,
                  search
                )}
                className={styles["link"]}
                onClick={onClickRefresh(isCurrentContact)}
              >
                <IconButton className={styles["link-btn"]}>
                  <ContactIcon on={isCurrentContact} />
                </IconButton>
              </Link>
            </li>
            <li className={styles["list-element"]}>
              <Link
                to={user_page_url}
                className={cn("link")}
                onClick={onClickRefresh(isCurrentMypage)}
                onMouseEnter={() => setUnopenable(false)}
              >
                <IconButton className={cn("link-btn", "profile")}>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <ProfileImage
                      src={user?.profile_image}
                      on={isCurrentMypage}
                    />
                  )}
                </IconButton>
              </Link>
              {user && (
                <DropdownMenu
                  unopenable={unopenable}
                  onClose={() => setUnopenable(true)}
                  onClickLogout={onClickLogout}
                  user_page_url={user_page_url}
                />
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
