import React from "react";

const TextareaView = ({ className, content }) => {
  return (
    <p
      className={className}
      dangerouslySetInnerHTML={{
        __html: content.replace(/(?:\r\n|\r|\n)/g, "<br />"),
      }}
    />
  );
};

export default TextareaView;
