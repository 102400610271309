import React from "react";
import qs from "qs";

import SearchProfileContainer from "../../containers/search/SearchProfileContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const SearchUser = ({ location }) => {
  useSetTitle("프로필 검색");
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return <SearchProfileContainer query={query} />;
};

export default SearchUser;
