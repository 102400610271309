import React from "react";
import qs from "qs";

import SearchFaqContainer from "../../containers/search/SearchFaqContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const SearchFaqPage = ({ location }) => {
  useSetTitle("자주 묻는 질문 검색");
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return <SearchFaqContainer query={query} />;
};

export default SearchFaqPage;
