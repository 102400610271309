import React from "react";

import _CoverImage from "../../../images/main/cover.png";

import styles from "./ProfileCoverIcon.module.scss";

const ProfileCoverIcon = props => {
  const { src, size = { width: 80, height: 80 } } = props;
  const { width, height } = size;
  return (
    <div
      className={styles["icon"]}
      style={{
        backgroundImage: `url(${src}), url(${_CoverImage})`,
        width: width,
        height: height,
      }}
      onError={e => console.log(e)}
    />
  );
};

export default ProfileCoverIcon;
