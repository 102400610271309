import qs from "qs";
import { useHistory } from "react-router-dom";

const useReplaceUrl = (baseUrl, query) => {
  // url query를 변경할 때 이전에 있던 query들은 유지하며 변경하는 함수
  const history = useHistory();

  return queryObject => {
    const newObject = {
      ...query,
      ...queryObject,
    };
    Object.keys(newObject).forEach(key => {
      if (!newObject[key]) {
        // 내용이 없으면 삭제
        delete newObject[key];
      }
    });
    const newQuery = qs.stringify(newObject);
    history.replace(`${baseUrl}?${newQuery}`);
  };
};

export default useReplaceUrl;
