import React from "react";
import styles from "./PageTitle.module.scss";

const PageTitle = ({ children }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["wrapper"]}>
        <h1 className={styles["title"]}>{children}</h1>
      </div>
    </div>
  );
};

export default PageTitle;
