import axios from "axios";
import { resolveAPIURL } from "./utils";

export const requestGetReleaseNote = id => {
  const url = `${resolveAPIURL("release-note")}/${id}`;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios.get(url, config);
};
