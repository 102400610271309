import React from "react";

import PresetViewContainer from "../../containers/preset/PresetViewContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const PresetViewPage = ({ match }) => {
  useSetTitle("프리셋 상세 보기");
  const { id } = match.params;
  return <PresetViewContainer id={id} />;
};

export default PresetViewPage;
