import { createAction, handleActions } from "redux-actions";

//액션 정의
const ON_LOADING = "loading/ON_LOADING";
const OFF_LOADING = "loading/OFF_LOADING";

//액션 생성함수
export const on_loading = createAction(ON_LOADING, type => type);
export const off_loading = createAction(OFF_LOADING, type => type);

const initState = {};

const loading = handleActions(
  {
    [ON_LOADING]: (state, action) => ({
      [action.payload]: true,
    }),
    [OFF_LOADING]: (state, action) => ({
      [action.paylod]: false,
    }),
  },
  initState
);

export default loading;
