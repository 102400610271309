import React from "react";
import qs from "qs";

import PresetContainer from "../../containers/preset/PresetContainer";

import { useSetTitle } from "../../contexts/MobileTitleContext";

const PresetPage = ({ match, location }) => {
  useSetTitle("프리셋");

  const { board_id: board_comment_id } = match.params;

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  return <PresetContainer board_id={board_comment_id} query={query} />;
};

export default PresetPage;
