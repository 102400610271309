import React, { useEffect, useRef } from "react";
import { Dialog, IconButton, Slide } from "@material-ui/core";

import CloseIcon from "../../icons/CloseIcon";
import { Comments } from "../comment/Comment";
import CommentInput from "../comment/CommentInput";

//hooks
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTokenCallback } from "../../hooks/storage/useToken";
import useDialog from "../../hooks/useDialog";
import useFeed from "../../hooks/redux/useFeed";
import useComment from "../../hooks/redux/useComment";
import useTouchMouse from "../../hooks/useTouchMouse";

//api
import { requestGetPreset } from "../../api/preset";

import { Paths } from "../../paths";

import styles from "./CommentModal.module.scss";

const SkeletonTitle = () => {
  return <div className={styles["skeleton-title"]} />;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CommentModal = ({ id, open, onClose }) => {
  const openDialog = useDialog();

  const scrollRef = useRef(null);
  const commentInputRef = useRef(null);

  const { user } = useSelector(state => state.info);
  const { feed = null, updateFeed } = useFeed(id);
  const { postComment } = useComment(id);
  const [touchState, isPress, events] = useTouchMouse({
    use_touch: true,
  });
  const { diff } = touchState;

  const callApiGetPreset = useTokenCallback(
    async access_token => {
      // 프리셋 게시글 상세 정보 요청 api 호출.
      try {
        const res = await requestGetPreset(access_token, id);
        updateFeed(res.data.post);
      } catch (e) {
        openDialog("삭제되거나 비공개 게시글입니다.", {
          onClose: onClose,
        });
      }
    },
    {
      isRequired: false,
    }
  );

  const onPostCommentBack = comment => postComment(comment, true);

  useEffect(() => {
    if (open && !feed) {
      callApiGetPreset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!isPress && diff.y < -100) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPress]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className={styles["dialog"]}
      PaperProps={{
        ref: scrollRef,
      }}
    >
      <header className={styles["app-bar"]} {...events}>
        <div className={styles["header"]}>
          {feed ? (
            <h1 className={styles["title"]}>
              <Link to={`${Paths.preset.index}/${feed?.board_id}`}>
                {feed?.title}
              </Link>
            </h1>
          ) : (
            <SkeletonTitle />
          )}
          <IconButton
            color="default"
            onClick={onClose}
            aria-label="close"
            className={styles["close"]}
          >
            <CloseIcon black />
          </IconButton>
        </div>
      </header>
      <div className={styles["wrapper"]}>
        <div className={styles["comment-list"]}>
          <Comments list={feed?.replies} board_id={id} scrollRef={scrollRef} />
        </div>
        <div className={styles["comment-box"]}>
          <CommentInput
            ref={commentInputRef}
            user={user}
            onPostComment={onPostCommentBack}
            autoFocus
          />
        </div>
      </div>
    </Dialog>
  );
};

export default CommentModal;
