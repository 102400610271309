import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../paths";
import { get_info } from "../../store/info";
import useDialog from "../useDialog";
import { setLoginPath } from "./useLoginPath";

const AUTO_LOGIN = "auto_login";
const ACCESS_TOKEN = "access_token";

export const useStorageCheck = () => {
  const dispatch = useDispatch();
  const info = useSelector(state => state.info);

  useEffect(() => {
    const localAutoLogin = localStorage.getItem(AUTO_LOGIN);
    const storage_access_token =
      localAutoLogin === "true"
        ? localStorage.getItem(ACCESS_TOKEN)
        : sessionStorage.getItem(ACCESS_TOKEN);

    dispatch(get_info(storage_access_token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return info;
};

export const setToken = (token, auto = false) => {
  // * 자동 로그인 체크했으면 localStorage에 아니면 sessionStorage에
  if (auto) {
    localStorage.setItem(ACCESS_TOKEN, token);
  } else {
    sessionStorage.setItem(ACCESS_TOKEN, token);
  }
  localStorage.setItem(AUTO_LOGIN, auto);
};

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(AUTO_LOGIN);
  sessionStorage.removeItem(ACCESS_TOKEN);
};

const useToken = () => {
  const { access_token } = useSelector(state => state.info);
  return access_token;
};

export const useTokenCallback = (
  callback = (access_token, ...restProps) => {},
  options = {
    isRequired: false,
    goBack: false,
  }
) => {
  const access_token = useToken();
  const history = useHistory();
  const location = useLocation();
  const openDialog = useDialog();
  return useCallback(
    (...restProps) => {
      if (!access_token && options.isRequired) {
        openDialog("로그인이 필요한 서비스입니다.", {
          onClick: () => {
            const { pathname, search } = location;
            setLoginPath(pathname + search); // 로그인 후 리다이렉트할 위치 저장.
            history.push(Paths.signin);
          },
          onClose: () => options.goBack && history.goBack(),
        });
      } else {
        callback(access_token, ...restProps);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [access_token, callback, options]
  );
};

export const useTokenEffect = () => {
  const access_token = useToken();
  const history = useHistory();
  const location = useLocation();
  const openDialog = useDialog();

  useEffect(() => {
    if (!access_token) {
      openDialog("로그인이 필요한 서비스입니다.", {
        onClick: () => {
          const { pathname, search } = location;
          setLoginPath(pathname + search); // 로그인 후 리다이렉트할 위치 저장.
          history.push(Paths.signin);
        },
        onClose: () => history.goBack(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  return access_token;
};

export default useToken;
