import React from "react";

import SearchError from "../../components/error/SearchError";
import { ReleaseNotes } from "../../components/releasenote/ReleaseNote";
import TitleHelmet from "../../components/title/TitleHelmet";

import { useSearchReleaseNotes } from "../../hooks/redux/useSearch";

/**
 * 업데이트 소식 검색 컨테이너.
 *
 * 커뮤니티 업데이트 소식을 검색하여, 업데이트 소식 리스트 렌더.
 */
const SearchReleaseNoteContainer = ({ query }) => {
  const { q } = query; // url query 값

  const [notes, loading] = useSearchReleaseNotes(q);

  return (
    <>
      <TitleHelmet title="업데이트 내역 검색" />
      {q ? (
        <ReleaseNotes loading={loading} list={notes} />
      ) : (
        <SearchError text="검색어를 입력해 주세요." />
      )}
    </>
  );
};

export default SearchReleaseNoteContainer;
