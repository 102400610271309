import AdminContainer from "../../../containers/mypage/update/AdminContainer";

import { useSetTitle } from "../../../contexts/MobileTitleContext";

const AdminPage = () => {
  useSetTitle("관리자");
  return <AdminContainer />;
};

export default AdminPage;
