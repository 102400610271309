import React from "react";
import { Link } from "react-router-dom";

import TitleHelmet from "../../components/title/TitleHelmet";

import { Paths } from "../../paths";

import styles from "./FindContainer.module.scss";

const FindItem = ({ to, title, descript }) => {
  return (
    <Link className={styles["box"]} to={to}>
      <p className={styles["text"]}>{title}</p>
      <p className={styles["descript"]}>{descript}</p>
    </Link>
  );
};

const FindContainer = () => {
  return (
    <div className={styles["link"]}>
      <TitleHelmet title="아이디 / 비밀번호 찾기" />
      <FindItem
        to={Paths.find.id.index}
        title="아이디 찾기"
        descript="휴대폰 인증번호로 이메일을 찾습니다."
      />
      <FindItem
        to={Paths.find.password.index}
        title="비밀번호 찾기"
        descript="휴대폰 인증번호로 비밀번호을 찾습니다."
      />
    </div>
  );
};

export default FindContainer;
