import React from "react";

export default function () {
  return (
    <svg width="3.964" height="19.963" viewBox="0 0 3.964 19.963">
      <g transform="translate(-1178.036 -2124.755)">
        <circle
          cx="1.982"
          cy="1.982"
          r="1.982"
          transform="translate(1182 2124.755) rotate(90)"
          fill="#888"
        />
        <circle
          cx="1.982"
          cy="1.982"
          r="1.982"
          transform="translate(1182 2132.755) rotate(90)"
          fill="#888"
        />
        <circle
          cx="1.982"
          cy="1.982"
          r="1.982"
          transform="translate(1182 2140.755) rotate(90)"
          fill="#888"
        />
      </g>
    </svg>
  );
}
