import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import TitleHelmet from "../../components/title/TitleHelmet";

import _Logo from "../../images/svg/logo/invaiz_white.svg";

import { requestGetReleaseNote } from "../../api/release";

import useDialog from "../../hooks/useDialog";

//lib
import { dateToRelative } from "../../lib/formatter";

import styles from "./ReleaseNoteContainer.module.scss";

/**
 * 릴리즈 노트 상단 영역 스켈레톤 컴포넌트
 */
const SkeletonTitleBar = () => {
  return (
    <div className={styles["skeleton-title-bar"]}>
      <div className={styles["skeleton-title"]} />
      <p className={styles["skeleton-created-at"]} />
    </div>
  );
};

/**
 * 릴리즈 노트 내용 영역 스켈레톤 컴포넌트
 */
const SkeletonContent = () => {
  return <div className={styles["skeleton-content"]} />;
};

/**
 * 릴리즈 노트 상세 보기 컨테이너.
 *
 * id 값에 해당하는 릴리즈 노트 상세 데이터 렌더.
 */
const ReleaseNoteContainer = ({ id }) => {
  const history = useHistory();
  const openDialog = useDialog();

  const [note, setNote] = useState(null); // 릴리즈 노트 상세

  const callApiGetReleaseNote = async () => {
    // 릴리즈 노트 상세 api 호출
    try {
      const res = await requestGetReleaseNote(id);
      if (res.status === 200) {
        setNote(res.data.note);
      }
    } catch (e) {
      openDialog(e?.response?.data?.message, {
        onClose: () => history.goBack(),
      });
    }
  };

  useEffect(() => {
    // mount시 릴리즈 노트 상세 정보 가져 옴
    callApiGetReleaseNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <article className={styles["container"]}>
      {note && <TitleHelmet title={`업데이트 내역 - ${note.title}`} />}
      <section className={styles["wrapper"]}>
        {note ? (
          <div className={styles["title-bar"]}>
            <h2 className={styles["title"]}>[ {note.title} ]</h2>
            <p className={styles["created-at"]}>
              {`invaiz.official / ${dateToRelative(new Date(note.created_at))}`}
            </p>
          </div>
        ) : (
          <SkeletonTitleBar />
        )}
        <div className={styles["thumbnail"]}>
          <img className={styles["logo"]} src={_Logo} alt="thumbnail" />
        </div>
        {note ? (
          <div
            className={styles["content"]}
            dangerouslySetInnerHTML={{ __html: note.content }}
          />
        ) : (
          <SkeletonContent />
        )}
      </section>
    </article>
  );
};

export default ReleaseNoteContainer;
