import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import TitleHelmet from "../../../components/title/TitleHelmet";

import { useTokenEffect } from "../../../hooks/storage/useToken";
import useDialog from "../../../hooks/useDialog";
import { useSnackbar } from "../../../hooks/useSnackbar";

import {
  requestDeleteSharedFile,
  requestGetSharedFiles,
  requestPostSharedFile,
} from "../../../api/files";
import { resolveAPIURL } from "../../../api/utils";

const AdminContainer = () => {
  const openDialog = useDialog();
  const openSnackbar = useSnackbar();
  const access_token = useTokenEffect();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);

  const onClose = () => setOpenCreateDialog(false);
  const onRegisterFile = e => {
    e.preventDefault();
    if (name === "") {
      openDialog("파일 이름을 입력해주세요.");
      return;
    }
    if (file === null) {
      openDialog("파일을 선택해주세요.");
      return;
    }

    requestPostSharedFile(access_token, name, file)
      .then(res => {
        setName("");
        setFile(null);
        setFileList(prev => prev.concat(res.data.shared_file));
        onClose();
      })
      .catch(() => {
        openDialog("파일 업로드에 실패하였습니다.");
      });
  };
  const onDeleteFile = id => {
    requestDeleteSharedFile(access_token, id).then(() => {
      setFileList(prev => prev.filter(f => f.id !== id));
      openDialog("파일이 삭제되었습니다.");
    });
  };

  const onChangeFile = e => {
    const file = e.target.files[0];
    try {
      if (file) {
        const name = file.name.split(".").slice(0, -1).join(".");
        setName(name);
        setFile(file);
      }
    } catch (e) {
      openDialog("파일 업로드에 실패하였습니다.");
    }
  };

  useEffect(() => {
    requestGetSharedFiles(access_token).then(res => {
      setFileList(res.data.shared_files);
    });
  }, []);

  return (
    <>
      <TitleHelmet title="관리자" />
      <Button
        onClick={() => setOpenCreateDialog(true)}
        variant="outlined"
        color="primary"
      >
        파일 등록
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>파일 이름</TableCell>
            <TableCell>파일 경로</TableCell>
            <TableCell>생성일</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fileList.map(fileItem => (
            <TableRow key={fileItem.id}>
              <TableCell>{fileItem.name}</TableCell>
              <TableCell
                onClick={() => {
                  window.navigator.clipboard
                    .writeText(
                      `${resolveAPIURL("shared-files")}/${fileItem.id}`
                    )
                    .then(() => {
                      openSnackbar({
                        message: "클립보드에 복사되었습니다.",
                        variant: "info",
                      });
                    });
                }}
              >
                {`${resolveAPIURL("shared-files")}/${fileItem.id}`}
              </TableCell>
              <TableCell>{fileItem.created_at}</TableCell>
              <TableCell>
                <Button onClick={() => onDeleteFile(fileItem.id)}>삭제</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={openCreateDialog} onClose={onClose}>
        <form onSubmit={onRegisterFile}>
          <DialogTitle>공유할 파일 등록</DialogTitle>
          <DialogContent>
            {file !== null && (
              <TextField
                autoFocus
                fullWidth
                label="파일 이름"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            )}
            <input type="file" onChange={onChangeFile} />
          </DialogContent>
        </form>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            취소
          </Button>
          <Button color="primary" variant="contained" onClick={onRegisterFile}>
            등록
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminContainer;
