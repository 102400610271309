const LOGIN_PATH = "login_path";

export const setLoginPath = path => {
  sessionStorage.setItem(LOGIN_PATH, path);
};

export const getLoginPath = () => {
  const paths = sessionStorage.getItem(LOGIN_PATH);
  sessionStorage.removeItem(LOGIN_PATH);
  return paths;
};
