import React from "react";
import TitleHelmet from "../../components/title/TitleHelmet";
import styles from "./Error.module.scss";

const ErrorContainer = () => {
  return (
    <article className={styles["container"]}>
      <TitleHelmet title="오류" />
      <section className={styles["content"]}>
        서버에 오류가 발생했습니다.
      </section>
    </article>
  );
};

export default ErrorContainer;
