import React from "react";

import TitleLabel from "../../components/title/TitleLabel";
import SearchError from "../../components/error/SearchError";

import { Profiles } from "../../components/profile/Profile";
import { Feeds } from "../../components/feed/Feed";
import { ReleaseNotes } from "../../components/releasenote/ReleaseNote";
import { Faqs } from "../../components/faq/Faq";

import { useSearchTotal } from "../../hooks/redux/useSearch";

import { Paths } from "../../paths";

import styles from "./SearchContainer.module.scss";
import TitleHelmet from "../../components/title/TitleHelmet";

const isNotTotalEmpty = result => {
  // 모든 결과가 빈 값인지 확인하는 함수
  return Object.values(result).some(v => {
    return v !== undefined && v !== null && v.length;
  });
};

/**
 * 통합 검색 컨테이너.
 *
 * 커뮤니티 내 데이터를 통합적으로 검색하여, 유저 프로필, 프리셋 게시글,
 * 자주 묻는 질문, 릴리즈 노트 리스트 렌더.
 */
const SearchTotalContainer = ({ query }) => {
  const { q } = query; // url query 값

  const [result = {}] = useSearchTotal(q);

  const { users, preset_posts, faqs, notes } = result; // 통합 검색 데이터.

  return (
    <>
      <TitleHelmet title="통합검색" />
      {q ? (
        isNotTotalEmpty(result) ? (
          <>
            {users && users.length !== 0 && (
              <section className={styles["content"]}>
                <TitleLabel
                  title="프로필"
                  more={{
                    link: `${Paths.search.profile}?q=${q}`,
                  }}
                />
                <Profiles list={users.slice(0, 4)} />
              </section>
            )}
            {preset_posts && preset_posts.length !== 0 && (
              <section className={styles["content"]}>
                <TitleLabel
                  title="프리셋 게시글"
                  more={{
                    link: `${Paths.preset.index}?keyword=${q}`,
                  }}
                />
                <Feeds list={preset_posts.slice(0, 4)} />
              </section>
            )}
            {notes && notes.length !== 0 && (
              <section className={styles["content"]}>
                <TitleLabel
                  title="업데이트 소식"
                  more={{
                    link: `${Paths.search.release}?q=${q}`,
                  }}
                />
                <ReleaseNotes list={notes.slice(0, 4)} />
              </section>
            )}
            {faqs && faqs.length !== 0 && (
              <section className={styles["content"]}>
                <TitleLabel
                  title="자주 묻는 질문"
                  more={{
                    link: `${Paths.search.faq}?q=${q}`,
                  }}
                />
                <Faqs list={faqs.slice(0, 4)} />
              </section>
            )}
          </>
        ) : (
          <SearchError text="검색 결과가 없습니다." />
        )
      ) : (
        <SearchError text="검색어를 입력해 주세요." />
      )}
    </>
  );
};

export default SearchTotalContainer;
