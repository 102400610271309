import React from "react";

import PolicyContainer from "../containers/policy/PolicyContainer";

import { useSetTitle } from "../contexts/MobileTitleContext";

const PolicyPage = ({ match }) => {
  const { type } = match.params;
  useSetTitle(type === "privacy" ? "개인정보 처리 방침" : "서비스 이용약관");

  return <PolicyContainer type={type} />;
};

export default PolicyPage;
